<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="title-item">
            <h2>Events</h2>
            <ul>
              <li>
                <img src="assets/img/logoIcon.png" alt="Image" />
                <a routerLink="/">Home</a>
              </li>
              <li><span>/</span></li>
              <li>Events</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Page Title -->
  
  <!-- Events -->
  <section class="blog-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-lg-4" *ngFor="let event of events">
          <div class="blog-item">
            <div class="top">
              <a [routerLink]="['/event', event.slug]"
                ><img [src]="event.image" alt="Events"
              /></a>
            </div>
            <h3>
              <a [routerLink]="['/event', event.slug]"
                >{{ event.title.substr(0, 50) }}
              </a>
            </h3>
            <p class="e-date"><i class="flaticon-appointment"></i> {{ event.start_date|date:'mediumDate' }} to {{ event.end_date|date:'mediumDate' }}</p>
            <div class="cmn-link">
              <a [routerLink]="['/event', event.slug]"
                ><i class="flaticon-right-arrow one"></i> Learn More
                <i class="flaticon-right-arrow two"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
  
      <div class="pagination-area">
        <ngb-pagination
          [collectionSize]="pagination.total"
          [(page)]="pagination.current_page"
          [maxSize]="5"
          [pageSize]="pagination.per_page"
          [boundaryLinks]="true"
          (pageChange)="getEvents($event)"
        ></ngb-pagination>
      </div>
    </div>
  </section>
  <!-- End Events -->
  