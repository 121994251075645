<div class="row">
  <div class="col-sm-6" *ngFor="let service of this.expert.services">
    <div class="expert-services card">
      <img
        src="{{ serviceIcon(service) }}"
        (click)="openService(service)"
        class="card-thumbnail"
      />
      <div>
        <h5 (click)="openService(service)">{{ service.service_name }}</h5>
        <p class="mb-0" *ngIf="service.service_type != 'message'">Rs. {{ service.charges }}/-</p>
        <p (click)="openService(service)">
          {{ serviceDescription(service.description).text }}
        </p>
        <a
          (click)="viewService(service)"
          *ngIf="serviceDescription(service.description).totalLength > 5"
          >Read More</a
        >
      </div>
    </div>
  </div>
</div>

<ng-template #noMessageModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">      
      <div class="col-sm-6">
        <h5>Expert can't Message other Expert.</h5>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #serviceModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <img
          [src]="
            expert.profile_pic ? expert.profile_pic : 'assets/img/avatar.jpg'
          "
          class="w-100"
          alt=""
        />
      </div>
      <div class="col-sm-6">
        <h5>{{ selectedService.service_name }}</h5>
        <p>{{ selectedService.description }}</p>

        <p>
          Charges: <i class="fas fa-rupee-sign"></i>
          {{ selectedService.charges }} ({{
            selectedService.charge_type == "hourly" ? "Per Hour" : "Fixed"
          }})
        </p>
        <p>Total Reviews: {{ expert.reviews.length }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Close
    </button>
    <button *ngIf="this.user.id !== this.expert.id"
      type="button"
      class="btn btn-primary"
      (click)="openService(selectedService, modal)"
    >
      Open
    </button>
  </div>
</ng-template>
