import { BrowserModule } from "@angular/platform-browser";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatChipsModule } from "@angular/material/chips";

import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { NavbarStyleOneComponent } from "./components/common/navbar-style-one/navbar-style-one.component";
import { FooterStyleOneComponent } from "./components/common/footer-style-one/footer-style-one.component";

import { LoginComponent } from "./components/pages/login/login.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { EmployersComponent } from "./components/pages/employers/employers.component";
import { EmployersDetailsComponent } from "./components/pages/employers-details/employers-details.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { ResumeDetailsComponent } from "./components/pages/resume-details/resume-details.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { JobsComponent } from "./components/pages/jobs/jobs.component";
import { FavouriteJobsComponent } from "./components/pages/favourite-jobs/favourite-jobs.component";
import { JobDetailsComponent } from "./components/pages/job-details/job-details.component";
import { PostAJobComponent } from "./components/pages/post-a-job/post-a-job.component";
import { CandidatesComponent } from "./components/pages/candidates/candidates.component";
import { CandidatesDetailsComponent } from "./components/pages/candidates-details/candidates-details.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { ContactComponent } from "./components/pages/contact/contact.component";

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider,
} from "ng-social-login-module";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NgParticlesModule } from "ng-particles";

import { NotificationsComponent } from "./components/common/notifications/notifications.component";
import { SocialLoginComponent } from "./components/common/social-login/social-login.component";

import { AuthGuardService } from "./services/AuthGaurd/auth-guard.service";
import { LogoutComponent } from "./components/pages/logout/logout.component";
import { ExpertContactComponent } from "./components/common/expert-contact/expert-contact.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ScheduleCallComponent } from "./components/pages/schedule-call/schedule-call.component";
import { InboxComponent } from "./components/pages/inbox/inbox.component";
import { CoursesComponent } from "./components/pages/courses/courses.component";
import { SearchFormComponent } from "./components/common/search-form/search-form.component";
import { SignupExpertComponent } from "./components/common/signup-expert/signup-expert.component";
import { ModalComponent } from "./components/common/modal/modal.component";
import { LoginSelectionComponent } from "./components/pages/login-selection/login-selection.component";
import { CounterComponent } from "./components/common/counter/counter.component";
import { ContactFormComponent } from "./components/common/contact-form/contact-form.component";
import { JobGuaranteeComponent } from './components/pages/job-guarantee/job-guarantee.component';
import { ReviewResumeComponent } from './components/pages/review-resume/review-resume.component';

import { IntercepterService } from "./services/InterCepterService/inter-cepter.service";
import { LinkedinComponent } from "./components/pages/linkedin/linkedin.component";
import { EventsComponent } from './components/pages/events/events.component';
import { EventDetialComponent } from './components/pages/event-detial/event-detial.component';
import { ForBusinessesComponent } from './components/pages/for-businesses/for-businesses.component';
import { ExpertProfileUpdateComponent } from './components/common/expert-profile-update/expert-profile-update.component';
import { ScheduledCallsComponent } from './components/pages/experts/scheduled-calls/scheduled-calls.component';
import { BreadcrumbsComponent } from './components/common/breadcrumbs/breadcrumbs.component';
import { ResumeRequestsComponent } from './components/pages/experts/resume-requests/resume-requests.component';
import { JobRequestsComponent } from './components/pages/experts/job-requests/job-requests.component';
import { ServicesComponent } from './components/pages/experts/services/services.component';
import { CustomServicesComponent } from './components/pages/custom-services/custom-services.component';
import { PaymentHistoryComponent } from './components/pages/experts/payment-history/payment-history.component';
import { PayNowComponent } from './components/common/pay-now/pay-now.component';
import { ServicesFormComponent } from './components/pages/experts/common/services-form/services-form.component';
import { ExpertProfileAddMoreComponent } from './components/common/expert-profile-add-more/expert-profile-add-more.component';
import { ExpertBasicMoreComponent } from './components/common/expert-basic-more/expert-basic-more.component';
import { EducationComponent } from './components/common/education/education.component';
import { IncompleteAlertComponent } from './components/common/incomplete-alert/incomplete-alert.component';
import { EducationModalComponent } from './components/common/multi-modal/education-modal/education-modal.component';
import { ExperinceModalComponent } from './components/common/multi-modal/experince-modal/experince-modal.component';

const CONFIG = new AuthServiceConfig(
  [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "117580371774-ka567qokvvlrgq0oc1liono0nfp8smu3.apps.googleusercontent.com"
      ),
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("1275897856236293"),      
    },
    {
      id: LinkedinLoginProvider.PROVIDER_ID,
      provider: new LinkedinLoginProvider("78giprgq53lfmj"),
    },
  ],
  true
);

export function provideConfig() {
  return CONFIG;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    LoginComponent,
    RegisterComponent,
    EmployersComponent,
    EmployersDetailsComponent,
    DashboardComponent,
    ResumeDetailsComponent,
    TestimonialsComponent,
    PricingComponent,
    FaqComponent,
    ComingSoonComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    JobsComponent,
    FavouriteJobsComponent,
    JobDetailsComponent,
    PostAJobComponent,
    CandidatesComponent,
    CandidatesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    NotificationsComponent,
    SocialLoginComponent,
    LogoutComponent,
    LinkedinComponent,
    ExpertContactComponent,
    ScheduleCallComponent,
    InboxComponent,
    CoursesComponent,
    SearchFormComponent,
    SignupExpertComponent,
    ModalComponent,
    LoginSelectionComponent,
    CounterComponent,
    ContactFormComponent,
    JobGuaranteeComponent,
    ReviewResumeComponent,
    EventsComponent,
    EventDetialComponent,
    ForBusinessesComponent,
    ExpertProfileUpdateComponent,
    ScheduledCallsComponent,
    BreadcrumbsComponent,
    ResumeRequestsComponent,
    JobRequestsComponent,
    ServicesComponent,
    CustomServicesComponent,
    PaymentHistoryComponent,
    PayNowComponent,
    ServicesFormComponent,
    ExpertProfileAddMoreComponent,
    ExpertBasicMoreComponent,
    EducationComponent,
    IncompleteAlertComponent,
    EducationModalComponent,
    ExperinceModalComponent,
  ],
  imports: [
    BrowserModule,
    MatProgressBarModule,
    AppRoutingModule,
    SocialLoginModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    NgbModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    NgParticlesModule,
    CKEditorModule,
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
  ],  
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IntercepterService,
      multi: true,
    },
    AuthGuardService,
  ],
  bootstrap: [AppComponent]
 
})
export class AppModule {}
