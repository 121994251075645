<div class="counter-area pt-100">
    <!-- <div class="counter-shape">
        <img src="assets/img/home-one/banner/shape-bottom-dark.png" alt="Shape" />
    </div> -->

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>HAPPY LEARNERS</p>
                    <h3><span class="odometer" data-count="2111">00</span></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>EXPERTS</p>
                    <h3><span class="odometer" data-count="45">00</span></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>SOCIAL MEDIA REACH</p>
                    <h3><span class="odometer" data-count="9650">00</span></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>CONSULTATION OVER</p>
                    <h3><span class="odometer" data-count="88">00</span></h3>
                </div>
            </div>
        </div>
    </div>
</div>