import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedinLoginProvider,
} from "ng-social-login-module";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { UserService } from "src/app/services/UserService/user.service";
import { LinkedinService } from "src/app/services/Linkedin/linkedin.service";

@Component({
  selector: "app-social-login",
  templateUrl: "./social-login.component.html",
  styleUrls: ["./social-login.component.scss"],
})
export class SocialLoginComponent implements OnInit {
  user_type: number = 0;

  constructor(
    private authService: AuthService,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    private _router: ActivatedRoute,
    private _user: UserService,
    private _linkedIn: LinkedinService
  ) {
    this._router.data.subscribe((data) => {
      this.user_type = data.user_type || 0;
    });
  }

  ngOnInit(): void {}

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      this.registerUser(data);
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
      this.registerUser(data);
    });
  }

  signInWithLinkedIn() {
    const url = this._linkedIn.createInitAuthUrl(this.user_type);

    window.open(url, "SkillSurger");
  }

  signOut(): void {
    this.authService.signOut();
  }

  registerUser(data) {
    data.user_type = this.user_type;

    this._api.socialLogin(data).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: data.message,
      });
      this._user.createUserSession(data.data);
      if(data.data.is_new){
        location.replace(location.origin+"/dashboard");
        return;
      }
      location.replace(location.origin+"/dashboard");
    });
  }
}
