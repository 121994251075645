<!-- Navbar -->

<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>About</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logoIcon.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-img">
                    <img src="assets/img/about-us.jpg" alt="New">
                    <img src="assets/img/about-shape.png" alt="New">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="sub-title">"On a mission to create a career changing platform</span>
                        <h2>We Are A Team Of Experts In This Field</h2>
                    </div>
                    <p>We are a team of like minded industry veterans who have a mission to educate, train and enable
                        youth to be job ready and excel in their work.</p>
                    <p>We are happy to launch this one stop platform for end to end learning experience by providing you
                        with community of experts, Courses (Text and Video based) , Webinars and Seminars etc</p>
                    <p>
                        We want to enable professionals to unlock their true potential with the right guidance and
                        support from experts
                    </p>
                    <a class="cmn-btn" routerLink="/contact">Get Started<i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End New -->

<!-- Counter -->
<app-counter></app-counter>
<!-- End Counter -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Innovate and Excel!</span>
            <h2>Our Products and Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>01</span>
                    <h3>Courses</h3>
                    <p>Aggregated top courses to help you excel in your field</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>02</span>
                    <h3>Jobs</h3>
                    <p>Aggregated top jobs to help you excel in your field</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>03</span>
                    <h3>Experts</h3>
                    <p>Expert partners to help you get referrals, career paths and transitions</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>x to 10x Career surger program</h3>
                    <p>Boost your salary by more than 50% or transition into a lucrative career with 1-1 expert guidance
                        ,referral, interview preparation support</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>Sureshot Career upstart program</h3>
                    <p>Get guaranteed referrals and job support for starting your career on a high note</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Explore -->
<app-signup-expert></app-signup-expert>
<!-- End Explore -->