import { Component, OnInit, ViewChild, Input } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { CONFIGS } from "src/app/services/AppConstants";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-navbar-style-one",
  templateUrl: "./navbar-style-one.component.html",
  styleUrls: ["./navbar-style-one.component.scss"],
})
export class NavbarStyleOneComponent implements OnInit {
  isLoggedIn: boolean = false;
  isExpert: boolean = false;
  user: any = {};
  mode :any;
  @Input() notificationsList: any = [];
  @Input() inboxNotificationsList: any = [];
  notification: any = {};
  env: any = environment;

  @ViewChild("viewNotification") viewNotification;

  constructor(
    private _user: UserService,
    private modalService: NgbModal,
    private _api: APIServiceService,
    private router: Router
  ) {
    this.isLoggedIn = !!this._user.getUserToken();
    this.isExpert = this._user.getUserType() == CONFIGS.user_type.expert;
    this.user = this._user.getUserSession();

    if (this.isLoggedIn) {
     
      
      this._api.getNotifications().subscribe((data) => {
        this.notificationsList = data.data.calls_notifications;
        this.inboxNotificationsList = data.data.inbox_notifications;
      });
    }
  }

  ngOnInit(): void {
    this.mode = localStorage.getItem('mode');
  }

  filterDescription(description) {
    let filteredDesc = description.replace(/<\/?[^>]+(>|$)/g, "");
    filteredDesc = filteredDesc.replace(/(\s+|\t|\r|\n)/g, " ");
    filteredDesc = filteredDesc.replace("<","")
    
    return filteredDesc.substr(0, 60);
  }

  getTimeDifference(createdAt: string): string {
    const now = new Date();
    const createdDate = new Date(createdAt);

    const diffInMs = now.getTime() - createdDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / 60000); 
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
    } else {
      return 'just now';
    }
  }

  sanitizeDescription(description) {
    if(!description) return description;
    let filteredDesc = description.replace(/(<\/?(?:a|p|img)[^>]*>)|<[^>]+>/ig, '$1');
    filteredDesc = filteredDesc.replace("<","").replace(">","");
    
    var image = null;

    function urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
       text.replace(urlRegex, function(url) {
        image = '<img src="' + url + '">';
      })
    }
    
    urlify(filteredDesc);

    if(image) return image;
    return filteredDesc;
  }

  switchToExpert() {
    localStorage.setItem('mode', 'switchExpert');
    this.reloadPage();
  }

  switchToUser(){
    localStorage.setItem('mode', 'switchUser');
    this.reloadPage();
  }

  reloadPage() {
    window.location.reload();
  }

  openNotification(notification) {
    
    this.notification = notification;
    
    if(notification.is_seen == '0'){   
      var param = {
        id: notification.id,
        user_id: notification.user_id
      };
      this._api.updateNotifications(param).subscribe((data) => {
        this.notificationsList = data.data.calls_notifications;
        this.inboxNotificationsList = data.data.inbox_notifications;
        if(notification.call_id){
          var url = this.isExpert ? '/skillsurger/dashboard/job-requests?call_id='+notification.call_id : '/skillsurger/dashboard?call_id='+notification.call_id;
          window.location.href = url;
        }else{
          var url = '/skillsurger/inbox';
          window.location.href = url;
         
        }
      });
      
    }else{
      if(notification.call_id){
       
        var url = this.isExpert ? '/skillsurger/dashboard/job-requests?call_id='+notification.call_id : '/skillsurger/dashboard?call_id='+notification.call_id;
        window.location.href = url;
      }else{
     
        var url = '/skillsurger/inbox';
        window.location.href = url;
      }
    }
    this.modalService.open(this.viewNotification, { size: "md" });
  }

  getUnseenNotificationsCount(notificationsList){
    if(!notificationsList || notificationsList <= 0) return 0;
    return notificationsList.filter(value => value.is_seen.toString() == '0').length;
  }

  getUnseenInboxNotificationsCount(inboxNotificationsList){
    if(!inboxNotificationsList || inboxNotificationsList <= 0) return 0;
    return inboxNotificationsList.filter(value => value.is_seen.toString() == '0').length;
  }
}
