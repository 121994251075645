<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Contact</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logoIcon.png" alt="Image" />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Info -->
<!-- <div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class="bx bx-world"></i>
                    <h3>India Office</h3>
                    <p>Hennur Road, Bengalore 560077</p>
                    <ul>
                        <li>
                            <span>Mobile:</span>
                            <a href="tel:+917310768702">+91 73107 68702</a>
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:hello@skillsurger.com">hello@skillsurger.com</a>
                        </li>
                        <li>
                            <span>Open:</span>
                            <a>Mon - Fri / 9:00 AM - 6:00 PM IST</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Info -->

<!-- Contact -->
<div class="contact-form-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-4 contact-info-area">
                <div class="info-item">
                    <i class="bx bx-world"></i>
                    <h3>India Office</h3>
                    <p>Hennur Road, Bengalore 560077</p>
                    <ul>
                        <li>
                            <span>Mobile:</span>
                            <a href="tel:+917310768702">+91 73107 68702</a>
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:hello@skillsurger.com">hello@skillsurger.com</a>
                        </li>
                        <li>
                            <span>Open:</span>
                            <a>Mon - Fri / 9:00 AM - 6:00 PM IST</a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-sm-12">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</div>
<!-- End Contact -->

<!-- Map -->
<div class="map-area">
    <div class="container-fluid p-0">
        <iframe width='100%' height='450px' id='mapcanvas'
            src='https://maps.google.com/maps?q=Hennur%20Road,%20Bengalore%20560077&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
            frameborder='0' scrolling='no' marginheight='0' marginwidth='0'></iframe>
        <!-- <iframe id="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779285!2d62.17507173408571!3d23.728204508550363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1599227927146!5m2!1sen!2sbd"></iframe> -->
    </div>
</div>
<!-- End Map -->