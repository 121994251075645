<div class="row">
  <div class="col-lg-6 col-md-12" >
    <div class="form-group">
      <label>Location:</label>
      <input type="text" class="form-control location"  placeholder="Location new" />
    </div>
  </div>
  <div class="col-lg-6 col-md-12">
    <div class="form-group">
      <label class="label-required">Position:</label>
      <input type="text" class="form-control position"  placeholder="Position in Current Organization" />
    </div>
  </div>
  <div class="col-lg-6 col-md-12">
    <div class="form-group">
      <label class="label-required">Company:</label>
      <input type="text" class="form-control company"  placeholder="Company" />
    </div>
  </div>
  <div class="col-lg-6 col-md-12">
    <div class="form-group">
      <label class="label-required">Current Role:</label>
      <input type="text" class="form-control role"  placeholder="Current Role" />
    </div>
  </div>
  <div class="col-lg-5 col-md-12">
    <div class="form-group">
      <label class="label-required">Start Year:</label>
      <input type="date" class="form-control start_year"  placeholder="Start Year" />
    </div>
  </div>
  <div class="col-lg-5 col-md-12">
    <div class="form-group">
      <label>End Year:</label>
      <input type="date" class="form-control end_year"  placeholder="End Year" />
    </div>
  </div>
  <div class="col-lg-2 col-md-12">
    <div class="form-group">
      <label>Till Now:</label>
      <br />
      <div class="form-check form-check-inline">
        <input class="form-check-input till" type="checkbox" id="check1" />
      </div>
    </div>
  </div>
</div>
