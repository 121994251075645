import { Component, OnInit } from "@angular/core";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "src/app/services/UserService/user.service";


@Component({
  selector: "app-home-one",
  templateUrl: "./home-one.component.html",
  styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
  home: any = {
    courses: [],
    jobs: [],
    blogs: [],
    experts: [],
  };
  user: any;

  particlesOptions = {
    fullScreen: {
      zIndex: 0,
      enable: false,
    },
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
        },
      },
    },
    particles: {
      color: {
        value: "#025951",
        animation: {
          h: {
            enable: false,
            speed: 2,
          },
        },
      },
      links: {
        color: {
          value: "#025951",
        },
        enable: true,
        opacity: 0.8,
        width: 1,
      },
      move: {
        enable: true,
        path: {},
        outModes: {
          bottom: "out",
          left: "out",
          right: "out",
          top: "out",
        },
        speed: 1.5,
        spin: {},
      },
      number: {
        density: {
          enable: true,
        },
        value: 50,
      },
      opacity: {
        value: 0.5,
        animation: {},
      },
      size: {
        value: {
          min: 4,
          max: 7,
        },
        animation: {},
      },
    },
  };

  constructor(private _user: UserService, private _api: APIServiceService, private router: Router, private route: ActivatedRoute) {
    this.user = _user.getUserSession();
  }

  ngOnInit(): void {

    if(this.router.url.includes('call_id') && this.router.url.includes('user_id'))
    {
      this.route.queryParams
      .subscribe(params => {
        localStorage.setItem('approvalData', JSON.stringify({callId: params.call_id, userId: params.user_id }));
        if(!this.user){
          this.router.navigate(['/login']);     
        }else{
          this.router.navigate(['/dashboard']);
        }
      });
    }

    this._api.getHomePage().subscribe((data) => {
      this.home = data.data;
    });
  }

  tags(tags) {
    if (!tags) return;
    const tagsArray = tags.split(",");
    let html = "";

    tagsArray.forEach((tag) => {
      html += `<span class="span-one mr-2">${tag}</span>`;
    });

    return html;
  }

  tagJobs(tags) {
    const tagsArray = tags.split(",");
    let html = "";

    tagsArray.forEach((tag) => {
      html += `<li>${tag}</li>`;
    });

    return html;
  }
}
