<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Schedule Call</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Schedule Consultation</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Contact -->
<div class="dashboard-area pb-100 mt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="profile-item">
          <img
            [src]="
              expert?.profile_pic
                ? expert?.profile_pic
                : 'assets/img/avatar.jpg'
            "
            alt="Dashboard"
          />
          <h2>{{ expert.name }}</h2>
          <span class="mb-2">{{ expert.position }}</span>
          <h5>
            Charges: Rs. {{ serviceDetails.charges }}/- ({{
              serviceDetails.charge_type
            }})
          </h5>
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <!-- <div class="profile-content" *ngIf="isExpert">
          <div class="profile-content-inner">
            <h2>Experts can't schedule call with another expert.</h2>
          </div>
        </div> -->
        <div class="profile-content" >
          <form
            id="contactForm"
            (submit)="scheduleCall()"
            [formGroup]="contactForm"
          >
            <div class="profile-content-inner">
              <h2>{{ formTitle }}</h2>
              <div class="row">
                <span formArrayName="meta">
                  <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="problem">{{
                        isGetTrained ? "Course Topic requirement" : "Problem"
                      }}</label>
                      <textarea
                        class="form-control h-auto"
                        id="problem"
                        cols="30"
                        rows="8"
                        formControlName="problem"
                        placeholder="Describe Your {{
                          isGetTrained ? 'Course Topic requirement' : 'Problem'
                        }}"
                        required
                      ></textarea>
                      <small *ngIf="!isGetTrained">
                        Describe the problem you are facing. It is advisable
                        that you complete your profile (If you have not done it
                        yet) by visiting your profile setting so that expert is
                        able to undestand you and your problem better.
                      </small>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12" *ngIf="!isGetTrained">
                    <div class="form-group">
                      <label for="session" class="label-required"
                        >What do you expect from the session?</label
                      >
                      <textarea
                        class="form-control h-auto"
                        id="session"
                        cols="30"
                        rows="8"
                        formControlName="session_expectation"
                        placeholder="What do you expect from the session?"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12" *ngIf="!isGetTrained">
                    <div class="form-group">
                      <label for="previous_steps" class="label-required"
                        >Previous Steps</label
                      >
                      <textarea
                        class="form-control h-auto"
                        id="previous_steps"
                        cols="30"
                        rows="8"
                        formControlName="previous_steps"
                        placeholder="Previous Steps taken to solve the problem"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group" formArrayName="time_options">
                      <label *ngIf="!isGetTrained" class="label-required"
                        >Suggest three open times for your call</label
                      >
                      <label *ngIf="isGetTrained">Tentative Start date</label>
                      <input
                        type="datetime-local"
                        formControlName="1"
                        id="time_options"
                        class="form-control my-2"
                        (change)="updateTimeOptions(0, $event)"
                        required
                      />
                      <input
                        type="datetime-local"
                        *ngIf="!isGetTrained"
                        formControlName="2"
                        id="time_options"
                        class="form-control my-2"
                        (change)="updateTimeOptions(1, $event)"
                        required
                      />
                      <input
                        type="datetime-local"
                        *ngIf="!isGetTrained"
                        formControlName="3"
                        id="time_options"
                        class="form-control my-2"
                        (change)="updateTimeOptions(2, $event)"
                        [ngModel]="range"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                      <label class="label-required"
                        >Prefferable days in Week</label
                      ><br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          (change)="updateWeekTime('weekdays')"
                          id="weekdays"
                          name="week_time"
                          formControlName="week_time"
                          value="weekdays" 
                        />
                        <label class="form-check-label" for="weekdays"
                          >Weekdays</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          formControlName="week_time"
                          class="form-check-input"
                          type="radio"
                          (change)="updateWeekTime('weekend')"
                          id="weekend"
                          name="week_time"
                          value="weekend"
                        />
                        <label class="form-check-label" for="weekend"
                          >Weekend</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                      <label class="label-required">Prefferable Day Time</label
                      ><br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          (change)="updateDayTime('morning')"
                          type="radio"
                          id="morning"
                          value="morning"
                          name="day_time"
                          formControlName="day_time"
                        />
                        <label class="form-check-label" for="morning"
                          >Morning</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          (change)="updateDayTime('afternoon')"
                          type="radio"
                          id="afternoon"
                          value="afternoon"
                          name="day_time"
                          formControlName="day_time"
                        />
                        <label class="form-check-label" for="afternoon"
                          >Afternoon</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          (change)="updateDayTime('evening')"
                          type="radio"
                          id="evening"
                          value="evening"
                          name="day_time"
                          formControlName="day_time"
                        />
                        <label class="form-check-label" for="evening"
                          >Evening</label
                        >
                      </div>
                    </div>
                  </div>
                </span>
                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="phone_no">Phone</label>
                    <input
                      type="text"
                      formControlName="phone_no"
                      id="phone_no"
                      required
                      class="form-control"
                      placeholder="88888-88888"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('phone_no')">
                      Please enter valid 10 digit phone number.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="email">Email</label>
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      required
                      class="form-control"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('email')">
                      Please enter valid email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              [disabled]="getFormValidationErrors() || loaderService.isLoading"
              class="btn dashboard-btn"
            >
              <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
              <span *ngIf="!loaderService.isLoading">Request Call</span>
              
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Contact -->
</div>
