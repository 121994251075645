import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { LoaderService } from "../LoaderService/loader.service";

@Injectable({
  providedIn: "root",
})
export class IntercepterService implements HttpInterceptor {
  constructor(public loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoader = req.headers.get("Hide-Loader");
    this.loaderService.isLoading = req.url.indexOf('/api/notifications') === -1 ? true : false;

    if(isLoader === "false" && req.url.indexOf('/api/login') === -1){
      this.loaderService.isRouteLoading.next(Boolean(isLoader));
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.isRouteLoading.next(false);
        this.loaderService.isLoading = false;
      })
    );
  }
}
