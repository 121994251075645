import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";

import { CONFIGS } from "src/app/services/AppConstants";

import { UserService } from "src/app/services/UserService/user.service";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";

function _window(): any {
  return window;
}
@Component({
  selector: "app-custom-services",
  templateUrl: "./custom-services.component.html",
  styleUrls: ["./custom-services.component.scss"],
})
export class CustomServicesComponent implements OnInit {
  get nativeWindow(): any {
    {
      return _window();
    }
  }

  serviceDetails: any = {};
  expert: any = {};
  contactForm: FormGroup;
  currentRate: number = 0;
  isExpert: any = false;
  user: any = {};
  formTitle: any = "Schedule Consultation";

  constructor(
    private _api: APIServiceService,
    private _route: ActivatedRoute,
    private _user: UserService,
    private _form: FormBuilder,
    private _notifier: NotifierService,
    public loaderService: LoaderService,
  ) {
    this.isExpert = this._user.getUserType() == CONFIGS.user_type.expert;
    this.user = _user.getUserSession();

    this._route.queryParams.subscribe((data) => {
      this._api.getExpertService(data.service).subscribe((service) => {
        this.serviceDetails = service.data;
      });
    });

    this._route.params.subscribe((data) => {
      this._api.viewExpert(data.slug).subscribe((data) => {
        this.expert = data.data;
      });
    });

    if(this.serviceDetails.charge_type == 'hourly'){
      this.contactForm = this._form.group({
        name: new FormControl("", [Validators.required]),
        phone_no: new FormControl("", [Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)      
        ]),
        email: new FormControl("", [Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
        meta: new FormGroup({
          requirments: new FormControl("", [Validators.required]),
          estimate_hours: new FormControl("", [Validators.required, 
            Validators.pattern("^[0-9]*$"),
            Validators.min(1) ])
        }),
      });
    }else{
      this.contactForm = this._form.group({
        name: new FormControl("", [Validators.required]),
        phone_no: new FormControl("", [Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)      
        ]),
        email: new FormControl("", [Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
        meta: new FormGroup({
          requirments: new FormControl("", [Validators.required]),
          estimate_hours: new FormControl("")
        }),
      });
    }
    
  }

  ngOnInit(): void {}

  getFormValidationErrors() {
    return this.contactForm.status == "VALID";
  }

  /** PAYMENT CODE */
  getCreateOrderParams(callDetails) {
    const expert = {
      call_id: callDetails.id,
      amount: this.serviceDetails.charges,
      notes: {
        expert_id: this.expert.user_id,
        expert_name: this.expert.name,
        amount: this.serviceDetails.charges,
      },
    };

    return expert;
  }

  updateOrderStatus(orderDetails) {
    this._api.saveOrder(orderDetails).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });

      this.contactForm.reset();
    });
  }

  paymentProcess(orderDetails: any) {
    const user = this._user.getUserSession();

    const options = {
      key: CONFIGS.razor_key,
      amount: orderDetails.amount,
      currency: "INR",
      name: CONFIGS.app_name,
      description: "Call Request",
      image: "https://skillsurger.com/assets/img/logo.png",
      order_id: orderDetails.order_id,
      handler: function (response) {
        response.status = "success";
        this.updateOrderStatus(response); // your function
      }.bind(this),
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone,
      },
      notes: JSON.parse(orderDetails.notes),
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new this.nativeWindow.Razorpay(options);
    rzp1.on(
      "payment.failed",
      function (response) {
        const paymentObj = {
          status: "failed",
          razorpay_order_id: response.error.metadata.order_id,
          razorpay_payment_id: response.error.metadata.payment_id,
          razorpay_signature: response.error.reason,
        };

        this.updateOrderStatus(paymentObj);

        rzp1.close();
      }.bind(this)
    );

    rzp1.open();
  }

  createOrder(callData: any) {
    this._api
      .createRazorOrder(this.getCreateOrderParams(callData))
      .subscribe((data) => {
        this.paymentProcess(data.data);
      });
  }

  isHourlyCharge() {
    return this.serviceDetails.charge_type == "hourly";
  }

  requestService() {
    const formData = this.contactForm.getRawValue();
    formData.expert_id = this.expert.user_id;
    formData.type = this.serviceDetails.id;
    formData.status = formData.status ? formData.status : this.serviceDetails.charge_type == 'hourly' ? -1 : 0;
    
    this._api.scheduleJob(formData).subscribe((data) => {
      if (this.isHourlyCharge()) {
        this._notifier.showNotification({
          message:
            "Request Submitted successfully, expert will shortly take a look and revert you back with budget",
          type: "success",
        });

        this.contactForm.reset();
      } else {
        this.createOrder(data.data);
      }
    });
  }

  showValidation(fieldName, parentField = null){
    if(parentField &&
      this.contactForm.get(parentField).get(fieldName).invalid &&
      (this.contactForm.get(parentField).get(fieldName).dirty ||
      this.contactForm.get(parentField).get(fieldName).touched )
      ){
      return true;
    }

    if( !parentField &&
      this.contactForm.controls[fieldName].invalid && 
      (this.contactForm.controls[fieldName].dirty ||
      this.contactForm.controls[fieldName].touched)
      ) return true;

      return false;
  }
}
