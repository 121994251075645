import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public isRouteLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isLoading: boolean = false;
  constructor() {}
}
