import { Component, OnInit } from "@angular/core";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-job-guarantee",
  templateUrl: "./job-guarantee.component.html",
  styleUrls: ["./job-guarantee.component.scss"],
})
export class JobGuaranteeComponent implements OnInit {
  experts: any[];
  openedFaq: any = 0;
  activeProgram: any = 0;

  programFor: any = [
    "B. Tech",
    "MCA / BCA Freshers",
    "IT Professionals",
    "Career Surge Seekers",
    "MAANG Aspirants",
  ];

  faqs: any = [
    {
      title: "When does the mentorship program start?",
      description:
        "Since it's 1-1 mentorship program it can start as soon as you enroll on the coming up weekend. Though the domain skill training sessions will happen once every 2 month, schedule of which will be notified once you enroll.",
    },
    {
      title: "Is this a completely live program?",
      description:
        "Yes it is completely live but it will not focus on course theory content as that is already available over the internet which has been aggregated by us in the courses and jobs section.",
    },
    {
      title: "What will be the fees structure?",
      description:
        "Apart from fixed fees, we will also charge 5 to 15% of CTC for 1 year after you get a job through our program. You will have to sign an agreement with us for this.",
    },
    {
      title: "What is the program duration?",
      description:
        "Though the duration is 3 months, our placement assistance and program validity is for 1 year unlessall 20 sessions are exhausted.",
    },
    {
      title: "Do you provide placement guarantee?",
      description: `We do not provide guarantee but we are fully focussed and
      definitely will provide referrals and mock interviews preparation
      1-1 with the mentor. Is you see we charge percentage of 1st year
      CTC which shows our comittment to work with you long term to make
      you succeed in your career.`,
    }
  ];

  bannerCards: any = [
    {
      icon: "assets/img/icons/opportunity.png",
      title: "Career opportunities into 5000+ companies",
    },
    {
      icon: "assets/img/icons/business-meeting.png",
      title: "Personalized 1 on 1 paid consultations",
    },
    {
      icon: "assets/img/icons/ribbon.png",
      title: "Capstone Projects by Industry Leaders",
    },
    {
      icon: "assets/img/icons/cloud-data.png",
      title: "Hands on Lab availability",
    },
    {
      icon: "assets/img/icons/referral.png",
      title: "Expert Referrals and outreach",
    },
  ];

  constructor(private _api: APIServiceService) {}

  ngOnInit(): void {
    this._api.getHomePage().subscribe((data) => {
      this.experts = data.data.experts;
    });
  }

  toggleFaq(index) {
    this.openedFaq = this.openedFaq == index ? null : index;
  }

  scrollToContact(id) {
    document.getElementById(id).scrollIntoView();
  }
}
