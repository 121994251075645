<form [formGroup]="searchForm" (submit)="search()">
  <div class="row">
    <div class="col-lg-5 col-md-12">
      <div class="form-group">
        <label><i class="bx bx-search"></i></label>
        <input
          type="text"
          class="form-control"
          formControlName="keywords"
          placeholder="type company or skill"
        
        />
          <!--Type your skills e.g. Java, Python, Data Science etc" -->
      </div>
    </div>

    <div class="col-lg-3 col-md-12 d-flex">
      <div class="form-group w-100">
        <select class="form-control" formControlName="type">
          <option value="expert">Role</option>
          <option value="jobs">Jobs</option>
          <!-- <option value="courses">Courses</option> -->
        </select>
      </div>
    </div>

    <!-- <div class="col-lg-4 col-md-12">
      <div class="form-group two">
        <label><i class="bx bx-location-plus"></i></label>
        <input
          type="text"
          class="form-control"
          formControlName="location"
          placeholder="Location e.g. Delhi"
        />
      </div>
    </div> -->
  </div>

  <button type="submit" class="btn">Search <i class="bx bx-search"></i></button>
</form>
