import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from 'src/app/services/LoaderService/loader.service';
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";

@Component({
  selector: 'app-for-businesses',
  templateUrl: './for-businesses.component.html',
  styleUrls: ['./for-businesses.component.scss']
})
export class ForBusinessesComponent implements OnInit {
  enquiryForm: FormGroup;
  constructor(
    private _form: FormBuilder,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    public loaderService: LoaderService,) {

  }

  ngOnInit(): void {
    this.enquiryForm = this._form.group({
      name:['',Validators.compose([Validators.required,])],
      email: ['',Validators.compose([Validators.required,Validators.email])],
      phone: ['',Validators.compose([Validators.required])],
      description: ['',Validators.compose([Validators.required])],
      company: ['',Validators.compose([Validators.required])],
      category: ['',Validators.compose([Validators.required])],
    });
  }


  onSubmit() {
    if (this.enquiryForm.valid) {
      const formData = this.enquiryForm.getRawValue();
      this._api.forBusinessess(formData).subscribe((data) => {
        this._notifier.showNotification({
          message: data.message,
          type: "success",
        });
        this.enquiryForm.reset();
      });
    } else {
      this._api.validateAllFormFields(this.enquiryForm);
    }
  }

}
