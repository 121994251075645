import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    isLoggedIn: boolean = false;
    notificationsList: any = [];
    inboxNotificationsList: any = [];
    subscription !: Subscription;
  
    location: any;
    routerSubscription: any;

    allowedUrls: any = ['', '/about', '/jobs', '/experts', '/contact', '/blog', '/login'];

    constructor(
        private router: Router,
        private _user: UserService,
        private _api: APIServiceService) {
            this.isLoggedIn = !!this._user.getUserToken();
            this.changeOfRoutes();
    }

    ngOnInit(){
        this.recallJsFuntions();
        if (this.isLoggedIn) {
            this.subscription = timer(0, 20000).pipe(
                switchMap(() =>this._api.getNotifications())
            ).subscribe((data) => { 
                this.notificationsList = data.data.calls_notifications;
                this.inboxNotificationsList = data.data.inbox_notifications
            });
        }
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.loader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    changeOfRoutes(){
        if (this.isLoggedIn) {
            this._api.getNotifications().subscribe((data) => {
                this.notificationsList = data.data.calls_notifications;
                this.inboxNotificationsList = data.data.inbox_notifications
            });
        }
    }
}