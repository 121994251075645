<!-- Navbar -->

<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Pricing Plans</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logoIcon.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Pricing Plans</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Pricing -->
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Premium</h3>
                        <span>For enormous Company</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 560/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>Unlimited Job Categories</li>
                            <li>Unlimited Job Posting</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li>Individually written job ads</li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Advanced</h3>
                        <span>For companies under 400</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 399/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>400 Job Posting</li>
                            <li>90 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li>Individually written job ads</li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Basic</h3>
                        <span>For companies under 80</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 150/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>50 Job Posting</li>
                            <li>20 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li><del>Individually written job ads</del></li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Free</h3>
                        <span>For companies under 10</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 100/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>10 Job Posting</li>
                            <li>15 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li><del>Resume database access</del></li>
                            <li><del>Individually written job ads</del></li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->
