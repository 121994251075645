<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Jobs</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Jobs</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all">
            <div class="search">
              <h3>Search Keywords</h3>
              <form>
                <input
                  type="text"
                  class="form-control"
                  name="keywords"
                  (input)="refineSearch($event)"
                  placeholder="Type Your Keywords"
                />
                <!-- <button type="submit" class="btn"><i class="flaticon-send"></i></button> -->
              </form>

              <div
                *ngFor="let filterCat of filterOptions"
                class="location-list cmn-padding"
              >
                <h3>{{ filterCat.name }}</h3>

                <ul class="job-cmn-cat">
                  <li *ngFor="let filters of filterCat.options">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [name]="stringTolower(filterCat.field)"
                        [value]="filters.value"
                        (click)="refineSearch($event)"
                        id="defaultCheck1"
                      />
                      <label class="form-check-label" for="defaultCheck1">{{
                        filters.label
                      }}</label>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- <div class="location-list cmn-padding">
                                <h3>Category</h3>

                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="category" value="fulltime" (click)="refineSearch($event)" id="defaultCheck1" />
                                            <label class="form-check-label" for="defaultCheck1">Full-Time</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="category" value="fulltime" (click)="refineSearch($event)" id="defaultCheck2" />
                                            <label class="form-check-label" for="defaultCheck2">Part-Time</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="location-list cmn-padding">
                                <h3>Skills</h3>

                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="data engineer" (click)="refineSearch($event)" id="data-engineer" />
                                            <label class="form-check-label" for="data-engineer">Data Engineer</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="java" (click)="refineSearch($event)" id="java" />
                                            <label class="form-check-label" for="java">Java</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="data science" (click)="refineSearch($event)" id="data-science" />
                                            <label class="form-check-label" for="data-science">Data Science</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="python" (click)="refineSearch($event)" id="python" />
                                            <label class="form-check-label" for="python">Python</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="machine learning" (click)="refineSearch($event)" id="machine-learning" />
                                            <label class="form-check-label" for="machine-learning">Machine Learning</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="management" (click)="refineSearch($event)" id="management" />
                                            <label class="form-check-label" for="management">Management</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="devops" (click)="refineSearch($event)" id="devops" />
                                            <label class="form-check-label" for="devops">Devops</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="full stack" (click)="refineSearch($event)" id="full-stack" />
                                            <label class="form-check-label" for="full-stack">Full Stack</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="skills" value="artifical intelligence" (click)="refineSearch($event)" id="artifical-intelligence" />
                                            <label class="form-check-label" for="artifical-intelligence">Artifical Intelligence</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="location-list cmn-padding">
                                <h3>Location</h3>

                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="location" value="delhi" (click)="refineSearch($event)" id="delhi" />
                                            <label class="form-check-label" for="delhi">Delhi</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="location" value="banglore" (click)="refineSearch($event)" id="banglore" />
                                            <label class="form-check-label" for="banglore">Banglore</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="location" value="pune" (click)="refineSearch($event)" id="pune" />
                                            <label class="form-check-label" for="pune">Pune</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="location" value="Mumbai" (click)="refineSearch($event)" id="mumbai" />
                                            <label class="form-check-label" for="mumbai">Mumbai</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="location" value="gurgaon" (click)="refineSearch($event)" id="gurgaon" />
                                            <label class="form-check-label" for="gurgaon">Gurgaon</label>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-12">
        <ul class="job-list-item">
          <li>
            <a href="#"
              >Results Found <span>({{ pagination.total }})</span></a
            >
          </li>
          <li></li>
        </ul>

        <div class="employer-item p-3" *ngFor="let job of jobs">
          <!-- target="_blank" href="{{job.url}}" -->
          <a (click)="contactForm(content, job.url)">
            <h3>{{ job.title }} | {{ job.company }}</h3>
            <ul>
              <li><i class="flaticon-send"></i> {{ job.location }}</li>
              <li *ngIf="job.location != job.category">
                <i class="fas fa-business-time"></i> {{ job.category }}
              </li>
            </ul>
            <div [innerHTML]="job.description"></div>
            <p *ngIf="job.skills && job.skills != '--'">
              Skills: {{ job.skills }}
            </p>
            <p *ngIf="job.proficiency && job.proficiency != '--'">
              Proficiency: {{ job.proficiency }}
            </p>
          </a>
        </div>

        <div class="pagination-area">
          <ngb-pagination
            [collectionSize]="pagination.total"
            [(page)]="pagination.current_page"
            [maxSize]="3"
            [pageSize]="pagination.per_page"
            (pageChange)="getJobs($event)"
            [boundaryLinks]="true"
          >
          </ngb-pagination>
        </div>
      </div>

      <div class="col-lg-2 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all p-2">
            <div class="location-list">
              <h3 style="font-size: 15px">
                <strong>Experts You May Now</strong>
              </h3>
              <div class="mb-2" *ngFor="let exp of expert">
                <a [routerLink]="['/experts', exp.vanity_url]">
                  <img src="{{ exp.profile_pic }}" class="w-100" />
                  <p class="m-0 text-capitalize">
                    <strong>{{ exp.name }}</strong>
                  </p>
                  <small class="text-capitalize"
                    >{{ exp.position }}, {{ exp.company }}</small
                  >
                </a>
              </div>
            </div>

            <div class="location-list cmn-padding">
              <h3 style="font-size: 15px"><strong>Relevant Courses</strong></h3>
              <ul class="list-unstyled job-cmn-cat">
                <li *ngFor="let course of courses">
                  <a href="{{ course.url }}" target="_blank">
                    <h6>
                      <strong>{{ course.title }}</strong>
                    </h6>
                    <p>{{ course.description.substr(0, 60) }}</p>
                  </a>
                  <hr />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<ng-template #content let-modal>
  <button
    type="button"
    class="btn close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="modal-body">
    <div class="contact-form-area">
      <app-contact-form
        [openUrl]="redirectTo"
        [isContact]="false"
      ></app-contact-form>
    </div>
  </div>
</ng-template>
