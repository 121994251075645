<div class="notification row">
    <div class="col-2" *ngIf="data.icon">
        <span>{{data.icon}}</span>
    </div>
    <div class="col-10">
        <p class="m-0">
            {{data.message}}
        </p>
    </div>
    <button class="dismiss-toast" (click)="hideNotification($event)"><i class="fas fa-times"></i></button>
</div>