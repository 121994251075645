<section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Help and Earn</span>
                <h2>Get Started now as an expert or a learner now to unlock the potential in you</h2>
            </div>
            <!-- <p>You require a Linkedin Account to Signup</p> -->
            <ul>
                <li><a [routerLink]="['/login', 'selection']" class="left-btn">Get Started <i
                            class="flaticon-right-arrow"></i></a></li>
            </ul>
        </div>
    </div>
</section>