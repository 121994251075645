import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { CONFIGS } from "src/app/services/AppConstants";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/UserService/user.service";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";

function _window(): any {
  return window;
}

@Component({
  selector: "app-schedule-call",
  templateUrl: "./schedule-call.component.html",
  styleUrls: ["./schedule-call.component.scss"],
})
export class ScheduleCallComponent implements OnInit {
  get nativeWindow(): any {
    {
      return _window();
    }
  }

  expert: any = {};
  contactForm: FormGroup;
  currentRate: number = 0;
  isExpert: any = false;
  formTitle: any = "Schedule Consultation";
  isGetTrained: any = 0;
  dayTime: any;
  weekTime: any;
  serviceDetails: any = {};
  time_options: any = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _api: APIServiceService,
    private _form: FormBuilder,
    private _notifier: NotifierService,
    private _user: UserService,
    public loaderService: LoaderService,
  ) {
    this._route.data.subscribe((data) => {
      this.formTitle = data.form_title || this.formTitle;
      this.isGetTrained = data.isGetTrained || this.isGetTrained;
    });

    this.isExpert = this._user.getUserType() == CONFIGS.user_type.expert;

    this.contactForm = this._form.group({
      meta: new FormGroup({
        problem: new FormControl(),
        session_expectation: new FormControl(),
        previous_steps: new FormControl(),
        time_options: new FormGroup({
          1: new FormControl(),
          2: new FormControl(),
          3: new FormControl(),
        }),
        day_time: new FormControl("", [Validators.required]),
        week_time: new FormControl("", [Validators.required]),
      }),
      phone_no: new FormControl("", [Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)      
      ]),
      email: new FormControl("", [Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      type: "call",
    });

    this._route.params.subscribe((data) => {
      this._api.viewExpert(data.slug).subscribe((data) => {
        this.expert = data.data;
      });
    });

    this._route.queryParams.subscribe((data) => {
      this._api.getExpertService(data.service).subscribe((service) => {
        this.serviceDetails = service.data;
      });
    });
  }

  ngOnInit(): void {}

  scheduleCall() {
    
    const formData = this.contactForm.getRawValue();
    formData.expert_id = this.expert.user_id;
    // formData.meta.day_time = this.dayTime;
    // formData.meta.week_time = this.weekTime;
    //formData.meta.time_options = this.time_options;
    formData.type = this.isGetTrained ? 'training' : 'call';
    this._api.scheduleJob(formData).subscribe((data) => {
      this.createOrder(data.data);
      this._router.navigate(['dashboard']);
    });
  }

  getFormValidationErrors() {
    let errors = false;

    Object.keys(this.contactForm.controls).forEach((key) => {


      if(key == 'meta'){
        Object.keys((this.contactForm.get(key) as FormArray).controls).forEach((k) => {
          const controlErrors: ValidationErrors = this.contactForm.get(key).get(k).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach((keyError) => {
              errors = true;
            });
          }
        });
      }else{  
        const controlErrors: ValidationErrors = this.contactForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            errors = true;
          });
        }
      }

    });
    return errors;
  }

  /** PAYMENT CODE */
  getCreateOrderParams(callDetails) {
    const expert = {
      call_id: callDetails.id,
      amount: this.serviceDetails.charges,
      notes: {
        expert_id: this.expert.user_id,
        expert_name: this.expert.name,
        amount: this.serviceDetails.charges,
      },
    };

    return expert;
  }

  createOrder(callData: any) {
    this._api
      .createRazorOrder(this.getCreateOrderParams(callData))
      .subscribe((data) => {
        this.paymentProcess(data.data);
      });
  }

  updateOrderStatus(orderDetails) {
    this._api.saveOrder(orderDetails).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });

      this.contactForm.reset();
    });
  }

  paymentProcess(orderDetails: any) {
    const user = this._user.getUserSession();

    const options = {
      key: CONFIGS.razor_key,
      amount: orderDetails.amount,
      currency: "INR",
      name: CONFIGS.app_name,
      description: "Call Request",
      image: "https://skillsurger.com/assets/img/logo.png",
      order_id: orderDetails.order_id,
      handler: function (response) {
        response.status = "success";
        this.updateOrderStatus(response); // your function
      }.bind(this),
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone,
      },
      notes: JSON.parse(orderDetails.notes),
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new this.nativeWindow.Razorpay(options);
    rzp1.on(
      "payment.failed",
      function (response) {
        const paymentObj = {
          status: "failed",
          razorpay_order_id: response.error.metadata.order_id,
          razorpay_payment_id: response.error.metadata.payment_id,
          razorpay_signature: response.error.reason,
        };

        this.updateOrderStatus(paymentObj);

        rzp1.close();
      }.bind(this)
    );

    rzp1.open();
  }

  updateDayTime(value) {
    this.dayTime = value;
  }

  updateWeekTime(value) {
    this.weekTime = value;
  }

  updateTimeOptions(index, e) {
    this.time_options[index] = e.target.value;
  }

  updateSelection(name, value) {
    this.contactForm.controls.meta[name].setValue(value);
  }

  showValidation(fieldName, parentField = null){
    if(parentField &&
      this.contactForm.get(parentField).get(fieldName).invalid &&
      (this.contactForm.get(parentField).get(fieldName).dirty ||
      this.contactForm.get(parentField).get(fieldName).touched )
      ){
      return true;
    }

    if( !parentField &&
      this.contactForm.controls[fieldName].invalid && 
      (this.contactForm.controls[fieldName].dirty ||
      this.contactForm.controls[fieldName].touched)
      ) return true;

      return false;
  }
  
}
