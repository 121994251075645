<div class="form-item">
  <h2 *ngIf="isContact">Contact Us</h2>
  <h3 *ngIf="!isContact">
    Help me find Best Jobs & Courses for me, Once you fill the form you will be
    redirected to the jobs/course page
  </h3>

  <form id="contactForm" [formGroup]="enquiryForm" (submit)="postEnquiry()">
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label for="name" class="label-required">Name</label>
          <input
            type="text"
            formControlName="name"
            id="name"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label for="email" class="label-required">Email</label>
          <input
            type="email"
            formControlName="email"
            id="email"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label for="phone" class="label-required">Phone</label>
          <input
            type="text"
            formControlName="phone_no"
            id="phone"
            required
            class="form-control"
          />
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label class="label-required">Career Help Needed</label><br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('career_help', 'internship')"
              type="radio"
              id="internship"
              value="internship"
            />
            <label class="form-check-label" for="internship">Internship</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('career_help', 'job')"
              type="radio"
              id="job"
              value="job"
            />
            <label class="form-check-label" for="job">Job</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('career_help', 'switch')"
              type="radio"
              id="switch"
              value="switch"
            />
            <label class="form-check-label" for="switch">Switch</label>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label for="skills" class="label-required">Skills</label>
          <input
            type="text"
            formControlName="skills"
            id="skills"
            required
            class="form-control"
          />
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="form-group">
          <label class="label-required">Required Service</label><br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('required_service', 'resume')"
              type="radio"
              id="resume"
              value="resume"
            />
            <label class="form-check-label" for="resume">Resume</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('required_service', 'refferal')"
              type="radio"
              id="refferal"
              value="refferal"
            />
            <label class="form-check-label" for="refferal">Refferal</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('required_service', 'courses')"
              type="radio"
              id="courses"
              value="courses"
            />
            <label class="form-check-label" for="courses">Courses</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="
                updateCheckBoxes(
                  'required_service',
                  'interview_prep_consultatoin'
                )
              "
              type="radio"
              id="interview_prep_consultatoin"
              value="interview_prep_consultatoin"
            />
            <label class="form-check-label" for="interview_prep_consultatoin"
              >Interview Prep Consultation</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              (change)="updateCheckBoxes('required_service', 'others')"
              type="radio"
              id="others"
              value="others"
            />
            <label class="form-check-label" for="others">Others</label>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-12">
        <button type="submit" class="btn">
          <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
          <span *ngIf="!loaderService.isLoading">Send Message</span>          
        </button>
      </div>
    </div>
  </form>
</div>
