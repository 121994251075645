import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { APIServiceService } from 'src/app/services/APIService/apiservice.service';

@Component({
  selector: 'app-event-detial',
  templateUrl: './event-detial.component.html',
  styleUrls: ['./event-detial.component.scss']
})
export class EventDetialComponent implements OnInit {

  event: any;
  recentEvents:any;
  constructor(private _api: APIServiceService, private _route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this._route.params.subscribe((params) => {
      this.getEventDetails(params.slug);
    });
   
  }
  
  transform(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit(): void {}

  getEventDetails(slug) {
    this._api.eventDetail({ slug }).subscribe((data) => {
      this.event = data.data;
      this.recentEvents = data.recentEvents.data;
    });
  }

}
