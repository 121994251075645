import { Component, OnInit , EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray ,FormControl} from '@angular/forms';
import { CONFIGS } from "src/app/services/AppConstants";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";



@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})




export class EducationComponent implements OnInit {
  @Output() addMoreEvent = new EventEmitter<string>();
 
  isExpert: any;
  user_type: any = CONFIGS.user_type;
  user: any = {};
  userMeta: any;
  expertEducation: any;
  expandedIndex: number | null = null;
  rows: any[] = []
  formSubmitted: boolean = false;
  is_loading: boolean = false;
  maxDate: string;
 
  constructor(
    private formBuilder: FormBuilder,
    private _api: APIServiceService,
    private _user: UserService,
    private _notifier: NotifierService,
    public loaderService: LoaderService,

   
  ) {
    this.getEducationDetail();
    
    const today = new Date();
    const month = today.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;
    this.maxDate = `${today.getFullYear()}-${formattedMonth}`;
  }




  ngOnInit(): void {
  }

  getEducationDetail() {
    this.isExpert = this._user.getUserType() == this.user_type.expert;
    this.user = this._user.getUserSession();
    this.userMeta = this.user.user_meta;
    if(this.user.Education){
      this.expertEducation = this.user.Education;
     
    }else{
      this.expertEducation = [];
    }
    if (this.expertEducation.length == 0) {
      this.addMore();
    }
  }


  addMore() {
    this.expertEducation.push({
      degree: '',
      field_of_study: '',
      institution: '',
      location: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
      is_new: true
    });

    this.colaspend(this.expertEducation.length - 1);
  }

  showAlert(index: number,id = null) {
    this.expertEducation.splice(index, 1);
  }

  colaspend(index: number){
    if(this.expandedIndex == index) {
      this.expandedIndex = null;
    }else{
      this.expandedIndex = index;
    }
  }

  validateForm(): boolean {
    const isExpertProfileValid = this.expertEducation.every(
      (education: any) => education.degree && education.field_of_study && education.institution && education.location && education.start_date 
    );

    return isExpertProfileValid;
  }

  onSubmit() {
    this.is_loading = true;
    this.formSubmitted = true;
    const allRecords = [...this.expertEducation];
    
    if (!this.validateForm()) {
      this._notifier.showNotification('Please fill all required fields');
      this.is_loading = false;
      return;
    }
    

    this._api.updateExpertEducation(allRecords).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: "Profile Updated Succcessfully",
      });
      data.data.is_new = false;
      this._user.updateUserSession(data.data);
      this.getEducationDetail();
      this.is_loading = false;
    });
  }

}
