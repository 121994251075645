import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  JsonpClientBackend,
} from "@angular/common/http";

import { LinkedinService } from "src/app/services/Linkedin/linkedin.service";
import { HttpService } from "src/app/services/HttpService/http-service.service";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { UserService } from "src/app/services/UserService/user.service";

@Component({
  selector: "app-linkedin",
  templateUrl: "./linkedin.component.html",
  styleUrls: ["./linkedin.component.scss"],
})
export class LinkedinComponent implements OnInit {
  authCode: any;
  user_type: any;

  constructor(
    private _router: ActivatedRoute,
    private _linkedin: LinkedinService,
    private httpClient: HttpService,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    private _user: UserService
  ) {}

  ngOnInit(): void {
    this._router.queryParams.subscribe((params) => {
      this.authCode = params.code;
      this.user_type = params.state;

      this.getAccessToken(params.code);
    });
  }

  getAccessToken(code) {
    const accessUri = this._linkedin.getAccessTokenUrl(code);

    this.httpClient
      .httpGet("linkedin", { url: accessUri })
      .subscribe((data) => {
        (data);
        this.getLinkedUserDetails(data);
      });
  }

  getLinkedUserDetails(data) {
    this.httpClient.httpPost("linkedin", data).subscribe((data) => {
      this.registerUserWithLinkedIn(data.data);

      window.opener.location.reload();
    });
  }

  registerUserWithLinkedIn(data) {
    data.user_type = this.user_type;
    data.name = data.first_name + " " + data.last_name;
    data.id = data.email;
    this._api.socialLogin(data).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: data.message,
      });
      this._user.createUserSession(data.data);

      location.replace("dashboard");
    });
  }
}
