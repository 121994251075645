
<app-incomplete-alert></app-incomplete-alert>

<form [formGroup]="userForm" (submit)="updateUserProfile()">
  <div class="profile-content-inner" >
      
    <h2 class="heading-underline">Basic Info</h2>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">First Name:</label>
          <input type="text" formControlName="name" [ngClass]="userForm.value.name ? '' : 'border-danger'"
            class="form-control" placeholder="Your Name" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">Last Name:</label>
          <input type="text" formControlName="lname" [ngClass]="userForm.value.lname ? '' : 'border-danger'"
            class="form-control" placeholder="Your Name" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">Your Email:</label>
          <input type="email" formControlName="email" [ngClass]="userForm.value.email ? '' : 'border-danger'"
            class="form-control" placeholder="hello@alzo.com" />
            <p class="invalid-feedback"
            *ngIf="showValidation('email')">
              Please enter valid email address.
            </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">Phone:</label>
          <input type="text" formControlName="phone" [ngClass]="userForm.value.phone ? '' : 'border-danger'"
            class="form-control" placeholder="88888-88888" />
          <p class="invalid-feedback"
            *ngIf="showValidation('phone')">
              Please enter valid 10 digit phone number.
            </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Profile Pic:</label>
          <input type="file" (change)="uploadFile($event)" class="form-control" accept=".jpg,.jpeg,.png,.pdf" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">Gender:</label>
          <br />
          <div class="form-check form-check-inline">
            <input class="form-check-input" name="gender" [checked]="userMeta?.gender == 'male'"
              (change)="updateCheckBoxes('gender', 'male')" type="radio" id="male" value="male" />
            <label class="form-check-label" for="male">Male</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" name="gender" [checked]="userMeta?.gender == 'female'"
              (change)="updateCheckBoxes('gender', 'female')" type="radio" id="female" value="female" />
            <label class="form-check-label" for="female">Female</label>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <label class="label-required">About:</label>
          <ckeditor formControlName="about" [editor]="Editor"></ckeditor>
          
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label class="label-required">Skills:</label>
          <input type="text" class="form-control" formControlName="skills" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12" *ngIf="mode !== 'switchExpert'">
        <div class="form-group">
          <label class="label-required">Resume:</label>
          <input type="file" (change)="uploadResume($event)" class="form-control" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.rtf" />
        </div>
      </div>
      <div class="col-sm-6" *ngIf="user.user_type === 1 || mode === 'switchExpert'">
        <div class="form-group">
          <label >Profile Url:</label>
          <input type="text" placeholder="Vanity Url" formControlName="vanity_url" (change)="checkVanityUrl($event)"
          class="form-control" />
          <p *ngIf="!isValidSlug" class="text-danger" style="font-size: 12px; padding-top: 10px; ">
            Profile Url already in use, Please provide a valid URL.
          </p>
        </div>
      </div>
    </div>
  </div>
  <button type="submit" [disabled]="!isFormValid() || !isValidSlug || loaderService.isLoading" class="btn dashboard-btn">
    <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
    <span *ngIf="!loaderService.isLoading">Save Your Information</span>    
  </button>
</form>
  <!-- <div class="profile-content-inner" *ngIf="isExpert">
    <h2>Expert Profile</h2>
    <app-expert-profile-add-more></app-expert-profile-add-more>
  </div>

  <div class="profile-content-inner" *ngIf="isExpert">
    <h2>Education</h2>
    <app-education></app-education>
  </div>   -->
 