import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-courses",
  templateUrl: "./courses.component.html",
  styleUrls: ["./courses.component.scss"],
})
export class CoursesComponent implements OnInit {
  courses: any;
  pagination: any = {
    from: 0,
    per_page: 0,
    current_page: 0,
    total: 0,
  };
  filters: any = {};
  expert: any = [];
  jobs: any = [];
  selectedCourse: any = "";
  headers: any = {
    hideLoader: false,
  };

  filterOptions: any = [];

  redirectTo = (): void => {
    window.open(this.selectedCourse);
  };

  constructor(
    private _api: APIServiceService,
    private _route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this._route.queryParams.subscribe((data) => {
      this.filters = { ...data };

      this.getCourses();
    });
  }

  ngOnInit(): void {
    this._api.getFilters({ type: "courses" }).subscribe((data) => {
      this.filterOptions = data.data;
    });
  }

  getCourses(page = 1) {
    this._api
      .getCourses({ page, ...this.filters }, this.headers)
      .subscribe((data) => {
        this.courses = data.data.courses.data;
        this.pagination = {
          from: data.data.courses.from,
          per_page: data.data.courses.per_page,
          current_page: data.data.courses.current_page,
          total: data.data.courses.total,
        };

        this.expert = data.data.expert;
        this.jobs = data.data.jobs;

        this.headers.hideLoader = true;
      });
  }

  tags(tags) {
    if (!tags) return;
    const tagsArray = tags.split(",");
    let html = "";

    tagsArray.forEach((tag) => {
      html += `<span class="span-one mr-2">${tag}</span>`;
    });

    return html;
  }

  filtersFormatting(event) {
    let newFilter = {};

    if (event.target.name == "keywords") {
      newFilter["keywords"] = event.target.value;
    } else {
      if (this.filters.hasOwnProperty(event.target.name)) {
        const index = this.filters[event.target.name].indexOf(
          event.target.value
        );

        if (index === -1) {
          this.filters[event.target.name].push(event.target.value);
        } else {
          this.filters[event.target.name].splice(index, 1);
        }
      } else {
        newFilter = {
          [event.target.name]: [event.target.value],
        };
      }
    }

    return newFilter;
  }

  refineSearch(event) {
    let newFilter = this.filtersFormatting(event);

    this.filters = { ...this.filters, ...newFilter };

    setTimeout(
      () => {
        this.getCourses(1);
      },
      event.target.name == "keywords" ? 500 : 0
    );
  }

  contactForm(content, url) {
    this.selectedCourse = url;

    this.modalService.open(content, { centered: true, size: "lg" });
  }

  stringTolower(value) {
    return value.toLowerCase();
  }

  filterCategoryFilter(filter){
    return filter.replace('_', ' ');
  }
}
