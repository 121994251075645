<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Event Details</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li>/ <a routerLink="/events">Events</a> &nbsp;</li>
            <li>/ {{ event.title.substr(0, 50) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Blog Details -->
<div class="blog-details-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div class="details-img">
            <img [src]="event.image" alt="Event Details" />
            <h2>{{ event.title }}</h2>
            <ul>
              <li><i class="flaticon-appointment"></i></li>
              <li>{{ event.start_date|date:'mediumDate' }}</li>
              <li>{{ event.end_date|date:'mediumDate' }}</li>
            </ul>
            <div class="d-content" [innerHTML]="transform(event.description)"></div>
          
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <div class="recent widget-item">
            <h3>Recent Events</h3>

            <div class="recent-inner" *ngFor="let event of recentEvents">
              <ul>
                <li>
                  <img [src]="event.image" alt="Details" />
                </li>
                <li>
                  <a [routerLink]="['/event', event.slug]">{{ event.title.substr(0, 30) }}...</a>
                  <p><i class="flaticon-appointment"></i> {{ event.start_date|date:'mediumDate' }} to {{ event.end_date|date:'mediumDate' }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Blog Details -->