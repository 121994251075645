<app-breadcrumbs
  pageTitle="Payment History"
  [path]="[
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Payments', url: '/dashboard/payments-history' }
  ]"
></app-breadcrumbs>
<div class="dashboard-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-sm-3 filters">
        <h4>Filters</h4>
        <hr />
        <div>
          <h6>Filter by Status</h6>
          <ul class="list-unstyled">
            <li>
              <input
                type="radio"
                value="1"
                name="is_pending"
                (change)="refineSearch($event)"
              />
              Pending
            </li>
            <li>
              <input
                type="radio"
                value="0"
                name="is_pending"
                (change)="refineSearch($event)"
              />
              Transefered
            </li>
          </ul>
        </div>

        <hr />

        <div>
          <h6>Filter by Date</h6>
          <ul class="list-unstyled">
            <li>
              <div class="form-group">
                <label for="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  class="form-control"
                  (change)="refineSearch($event)"
                />
              </div>
            </li>
            <li>
              <div class="form-group">
                <label for="toDate">To Date</label>
                <input
                  type="date"
                  id="toDate"
                  name="to_date"
                  class="form-control"
                  (change)="refineSearch($event)"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Payment Date</th>
              <th>Transfer Status</th>
              <th>Txn. ID</th>
              <th>Payment</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!payments.length">
              <th colspan="5" rowspan="3">
                <center>No Payments Available</center>
              </th>
            </tr>
            <tr *ngFor="let payment of payments; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ payment.created_at | date: medium }}</td>
              <td>{{ payment.trf_id ? payment.trf_id : "Pending" }}</td>
              <td>{{ payment.trf_id }}</td>
              <td>{{ callAmount(payment?.notes) }}</td>
            </tr>
            <tr *ngIf="payments.length">
              <td colspan="4" class="text-right">Total</td>
              <td colspan="1">{{ totalpayment }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-area mt-2">
          <ngb-pagination
            [collectionSize]="pagination.total"
            [(page)]="pagination.current_page"
            [maxSize]="3"
            [pageSize]="pagination.per_page"
            [boundaryLinks]="true"
            (pageChange)="changePange($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
