<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Blog Details</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li>/ <a routerLink="/blog">Blogs</a> &nbsp;</li>
            <li>/ {{ blog.title.substr(0, 50) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Blog Details -->
<div class="blog-details-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div class="details-img">
            <img [src]="blog.thumbnail" alt="Blog Details" />
            <h2>{{ blog.title }}</h2>
            <div class="d-content" [innerHTML]="blog.content"></div>
          </div>
          <div class="details-tag">
            <div class="row">
              <div class="col-lg-7">
                <div class="left">
                  <ul>
                    <li>
                      <span>Tags:</span>
                    </li>
                    <li *ngFor="let tag of tags(blog.meta_keywords)">
                      <a href="#">#{{ tag }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="right">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/sharer/sharer.php?u={{
                          postUrl(blog.slug)
                        }}"
                        target="_blank"
                        class="text-white"
                      >
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/share?url={{
                          postUrl(blog.slug)
                        }}&text={{ blog.title }}&status={{ blog.thumbnail }}"
                        target="_blank"
                        class="text-white"
                      >
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <div class="recent widget-item">
            <h3>Recent Posts</h3>

            <div class="recent-inner" *ngFor="let post of recentPosts">
              <ul>
                <li>
                  <img [src]="post.thumbnail" alt="Details" />
                </li>
                <li>
                  <a [routerLink]="['/blog', post.slug]"
                    >{{ post.title.substr(0, 30) }}...</a
                  >
                  <p>{{ post.meta_description.substr(0, 40) }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Blog Details -->
