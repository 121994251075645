import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { map } from 'rxjs/operators';
@Component({
  selector: "app-inbox",
  templateUrl: "./inbox.component.html",
  styleUrls: ["./inbox.component.scss"],
})
export class InboxComponent implements OnInit {
  rooms: any = [];
  messages: any;
  selectedRoom: any = false;
  chatMessages: any;
  user: any;
  chatForm: FormGroup;
  previousUrl: any = "experts";
  isLoading: any = true;
  active_expert_id: any = null;
  expertIds: any = [];
  mode: any;
  number:number;
  receivername : any;
  sendername : any ;

  constructor(
    private _api: APIServiceService,
    private _user: UserService,
    private _form: FormBuilder,
    private _router: Router
  ) {
    this.chatForm = this._form.group({
      message: new FormControl(),
    });
    this.user = this._user.getUserSession();
    

  }

  ngOnInit(): void {
    // this.SenderList();
    if (history.state.expert_id) {
      this.active_expert_id = history.state.expert_id;
      this.createNewRoom();
    } else {
      this.getRooms();
    }
  }


  createNewRoom() {
    this._api.createChatRoom(history.state).subscribe((data) => {
      setTimeout(() => {
        this.getRooms();
      }, 300);
    });
  }

  getRooms() {
    const type = localStorage.getItem('mode');
  
    
    this._api.getRoomsList({'user_type': type}).subscribe((data) => {
      if(this.active_expert_id){
        var selectRoom = data.data.filter((d) => d.expert_id == this.active_expert_id);
        
        if(selectRoom){
          this.openChatBox(selectRoom);
        } 
      }

      this.rooms = data.data;
      this.isLoading = false;
    });
  }



  openChatBox(roomDetails) {
    this.selectedRoom = roomDetails;

    this.getChatMessages();
  }

  getTimeDifference(createdAt: string): string {
    const now = new Date();
    const createdDate = new Date(createdAt);

    const diffInMs = now.getTime() - createdDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / 60000); 
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
    } else {
      return 'just now';
    }
  }

  getChatMessages() {
    const room_id = this.selectedRoom.id;

    this._api.getChatMessages(room_id).subscribe((data) => {
      this.chatMessages = data.data.data;
      this.receivername = this.chatMessages[0].receiver.name
      this.sendername = this.chatMessages[0].sender.name
      
      if(this.active_expert_id){
        var element= document.getElementsByClassName("room_"+this.active_expert_id);
        var anchor : HTMLAnchorElement = element.item(0) as HTMLAnchorElement;
        anchor.click();
        this.active_expert_id = null;
      }
    });
  }

  isSender(sender_id) {
    // alert(sender_id);
    return sender_id == this.user.id;
  }

  sendMessage() {
    const usertypemode = localStorage.getItem('mode');
    if (usertypemode === 'switchUser') {
      this.number = 0;
    } else {
      this.number = 1;
    }
    const formData = this.chatForm.value;
    formData.room_id = this.selectedRoom.id;
    formData.user_id =
    this.number == 0
        ? this.selectedRoom.expert_id
        : this.selectedRoom.user_id;

    this._api.sendMessage(formData).subscribe((data) => {
      // this.chatMessages.splice(0, 0, data.data);
      this.chatMessages.push(data.data);
      this.selectedRoom.last_message = data.data.message;
      this.chatForm.reset();
    });
  }
}
