<!-- Banner -->
<div class="banner-area pt-5">
  <!-- <ng-particles id="tsparticles" [options]="particlesOptions"></ng-particles> -->

  <div class="banner-bg-shapes">
    <img src="assets/img/patten.svg" />
    <img src="assets/img/patten.svg" />
    <img src="assets/img/patten.svg" />
  </div>
  <!-- <div class="banner-shape">
    <img src="assets/img/home-one/banner/shape-bottom-dark.png" alt="Shape" />
  </div> -->

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="banner-content text-left">
          <div class="d-table">
            <div class="d-table-cell">
              <h1>
                <span>LEARN, EARN AND NETWORK</span> WITH ANY SKILL YOU ARE
                INTERESTED IN
              </h1>
              <p>
                Connect with an industry expert to achieve your goal or to prepare for your interview
              </p>

              <div class="banner-form-area">
                <app-search-form></app-search-form>
              </div>
              <div class="banner-key position-relative">
                <ul>
                  <li><span>Top Experts as per your skills</span></li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Data Analytics' }"
                      >Data Analytics,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Data Science' }"
                      >Data Science,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Full Stack' }"
                      >Full Stack,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'DevOps' }"
                      >DevOps,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'cloud' }"
                      >Cloud,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Product Management' }"
                      >Product Management,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Data Structure Algorithm' }"
                      >Data Structure Algorithm,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Data Engineer' }"
                      >Data Engineer,</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'Deep Learning' }"
                      >Deep Learning</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/experts"
                      [queryParams]="{ keywords: 'cyber security' }"
                      >Cyber Security</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mobile-hide position-relative">
        <img
          src="assets/img/home-banner.png"
          class="banner-image"
        />
      </div>
    </div>
  </div>
</div>
<!-- End Banner -->

<section class="pt-70 pb-70 dark_green_bg featured-experts">
  <div class="container">
    <div class="section-title">
      <h2>Experts from</h2>
    </div>
    <div class="row">
      <div class="col-sm-2 col-6" *ngFor="let expert of home.experts">
        <div class="company-item">
          <div class="top">
            <a [routerLink]="['/experts', expert.vanity_url]">
              <img
                src="{{ expert.profile_pic }}"
                class="m-auto d-flex w-100 h-100"
                alt=""
              />
              <div class="p-2">
                <h6 class="mt-1">{{ expert.name }}</h6>
                <p>{{ expert.position }}, {{ expert.company }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Work -->
<section class="work-area pt-90 pb-70 light_green_bg">
  <div class="container">
    <div class="section-title">
      <!-- <span class="sub-title">How it works</span> -->
      <h2>How It Works</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <div class="work-item">
          <i class="flaticon-verify"></i>
          <h3>FOR LEARNERS</h3>
          <p>
            Reach out to top industry experts to get courses, consultations,
            referrals and career path guidance based on your skills. Further,
            search from our aggregated courses & Jobs based on your relevant
            skills to support easy transition.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="work-item">
          <i class="flaticon-file"></i>
          <h3>FOR EXPERTS</h3>
          <p>
            Create your complete profile to get listed as an expert, get
            consultation/ referrals, etc requests based on your hourly charges
            after you help career seekers.
          </p>
        </div>
      </div>

      <!-- <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="work-item">
          <i class="flaticon-comment"></i>
          <h3>FOR BUSINESSES</h3>
          <p>
            Hire Talent / Mentors/Freelancers / Influencers for any of your
            tasks related to webinars, assignments, projects or full time work.
          </p>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- End Work -->

<!-- Programs -->
<!-- <section class="pt-70 pb-70 dark_green_bg">
  <div class="container">
    <div class="section-title">
      <h2>Our Flagship Programs</h2>
    </div>
    <div class="row">
      <div
        class="col-sm-4 card programs home p-2 m-auto"
        style="min-height: 330px"
      >
        <div class="card-body d-flex">
          <div>
            <img src="assets/img/icons/xto10x.png" />
            <h3 class="title">x to 10x Career surge program</h3>
            <p class="description">
              Get into a product company and boost your salary by more than 50%
              or transition into a lucrative career with 1-1 expert guidance,
              referral, interview preparation support.
            </p>
            <h4 class="subtitle">100% Job Assistance*</h4>
            <div class="d-flex justify-content-center mt-4">
              <a
                class="btn btn-secondary"
                [routerLink]="['/career-surger-program']"
              >
                Enquire Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-sm-4 card programs home p-2 m-auto"
        style="min-height: 330px"
      >
        <div class="card-body d-flex">
          <div>
            <img src="assets/img/icons/sureshot.png" />
            <h3 class="title">Sure shot Career upstart program</h3>
            <p class="description">
              Get guaranteed referrals and job support for starting your career
              on a high note
            </p>
            <h4 class="subtitle">Career Acceleration Program</h4>
            <div class="d-flex justify-content-center mt-4">
              <a
                class="btn btn-secondary"
                [routerLink]="['/career-surger-program']"
              >
                Enquire Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Programs -->

<!-- Courses -->
<!-- <section class="job-area pt-90 pb-100 light_green_bg">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="section-title">
          <span class="sub-title">Desire Courses</span>
          <h2>Courses You Will Love</h2>
        </div>
      </div>
    </div>

    <div id="container" class="row">
      <div class="col-sm-6 col-lg-12" *ngFor="let course of home.courses">
        <div class="job-item">
          <a href="{{ course.url }}" target="_blank">
            <div class="feature-top-right">
              <span>Featured</span>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-8 col-md-12">
                <div class="job-left">
                  <img
                    [src]="
                      course.image == '-'
                        ? 'assets/img/skillsurger.png'
                        : course.image
                    "
                    class="thumbnail mr-2"
                    alt="Brand"
                  />
                  <h3>{{ course.course_name }}</h3>
                  <p *ngIf="course.description != '-'">
                    {{ course.description }}
                  </p>
                  <ul>
                    <li *ngIf="course.course_duration != '-'">
                      <i class="flaticon-appointment"></i>
                      {{ course.course_duration }}
                    </li>
                    <li *ngIf="course.provider != '-'">
                      <i class="bx bx-location-plus"></i> {{ course.provider }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="job-right">
                  <ul>
                    <li *ngIf="course.starting_date != '-'">
                      <i class="flaticon-customer"></i>
                      {{ course.starting_date }}
                    </li>
                    <li *ngIf="course.rating != '-'">
                      <span class="span-two">
                        <i class="fas fa-star"></i>
                        {{ course.rating }}
                        ({{ course.reviews }} Reviews)</span
                      >
                    </li>
                    <li
                      [innerHTML]="tags(course.tags)"
                      *ngIf="course.tags != '-'"
                    >
                      <span>Add to bookmarks</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="job-browse">
      <p>
        <a routerLink="/courses">Browse all Courses</a>
      </p>
    </div>
  </div>
</section> -->
<!-- End Jobs -->

<!-- Explore -->
<app-signup-expert></app-signup-expert>
<!-- End Explore -->

<!-- Counter -->
<app-counter></app-counter>
<!-- End Counter -->

<!-- Company -->
<section class="company-area pb-70 dark_green_bg">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8">
        <div class="section-title">
          <span class="sub-title">Employers Offering Job</span>
          <h2>Latest Featured jobs</h2>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="cmn-link">
          <a routerLink="jobs">
            <i class="flaticon-right-arrow one"></i> All Employers
            <i class="flaticon-right-arrow two"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-3" *ngFor="let job of home.jobs">
        <div class="company-item p-3">
          <div class="feature-top-right">
            <span>Featured</span>
          </div>
          <div class="top">
            <h3>
              <a href="{{ job.url }}" target="_blank">{{ job.title }}</a>
              <span>{{ job.category }}</span>
            </h3>
            <p>
              <i class="flaticon-appointment"></i> {{ job.company }},
              {{ job.location }}
            </p>
            <div class="job-description" [innerHTML]="job.description"></div>
          </div>
          <div class="bottom">
            <ul [innerHtml]="tagJobs(job.skills)"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Company -->

<!-- Feedback -->
<section class="feedback-area pt-100">
  <!-- <div class="feedback-shape">
    <img src="assets/img/home-one/banner/shape-bottom-dark.png" alt="Shape" />
  </div> -->

  <div class="container">
    <div class="section-title">
      <span class="sub-title">Feedbacks</span>
      <h2>What Users Are Saying</h2>
    </div>

    <div class="feedback-slider owl-theme owl-carousel">
      <div class="feedback-item">
        <h3>Mahesh</h3>
        <!-- <span>CEO at Alfa School</span> -->
        <p>
          “The call we did really went well today. Thank you for suggesting the
          best possible way to approach interview.
        </p>
        <h4><i class="flaticon-left-quote"></i> Just Awesome</h4>
      </div>

      <div class="feedback-item">
        <h3>Jiya</h3>
        <p>
          thanks for introducing me to your network which enabled me to get further insights and receive calls from top companies.
        </p>
        <h4><i class="flaticon-left-quote"></i> Very Reliable</h4>
      </div>

      <div class="feedback-item">
        <h3>Sanjay</h3>
        <p>
          “The high quality aggregated courses and jobs at a single place really
          made by search easy and well guided.
        </p>
        <h4><i class="flaticon-left-quote"></i> Looking Nice</h4>
      </div>
    </div>
  </div>
</section>
<!-- End Feedback -->

<!-- Blog -->
<!-- <section class="blog-area pb-70 dark_green_bg">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-12">
        <div class="section-title">
          <span class="sub-title">Our Blogs</span>
          <h2>Recent Posts</h2>
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="cmn-link">
          <a routerLink="/blog"
            ><i class="flaticon-right-arrow one"></i> Browse All Blogs
            <i class="flaticon-right-arrow two"></i
          ></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4" *ngFor="let blog of home.blogs">
        <div class="blog-item">
          <div class="top">
            <a [routerLink]="['/blog', blog.slug]"
              ><img src="{{ blog.thumbnail }}" alt="Blog"
            /></a>
          </div>
          <span>{{ blog.meta_keywords }}</span>
          <h3>
            <a [routerLink]="['/blog', blog.slug]">{{ blog.title }} </a>
          </h3>
          <div class="cmn-link">
            <a [routerLink]="['/blog', blog.slug]"
              ><i class="flaticon-right-arrow one"></i> Learn More
              <i class="flaticon-right-arrow two"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Blog -->

<!-- Partner -->
<!-- <div class="partner-area pt-100 pb-70">
  <div class="container">
    <div class="partner-slider owl-theme owl-carousel">
      <div class="partner-item">
        <img src="assets/img/partners/expertrons.svg" alt="Partner" />
        <img src="assets/img/partners/expertrons.svg" alt="Partner" />
      </div>

      <div class="partner-item">
        <img src="assets/img/partners/beingdatum.png" alt="Partner" />
        <img src="assets/img/partners/beingdatum.png" alt="Partner" />
        </div>

      <div class="partner-item">
        <img src="assets/img/partners/zx.png" alt="Partner" />
        <img src="assets/img/partners/zx.png" alt="Partner" />
      </div>

      <div class="partner-item">
        <img src="assets/img/partners/fl.png" alt="Partner" />
        <img src="assets/img/partners/fl.png" alt="Partner" />
      </div>

      <div class="partner-item">
        <img src="assets/img/partners/truenewslogo.png" alt="Partner" />
        <img src="assets/img/partners/truenewslogo.png" alt="Partner" />
      </div>
    </div>
  </div>
</div> -->
<!-- End Partner -->
