<app-incomplete-alert></app-incomplete-alert>
<ng-container>
  <div class="profile-content-inner"> 
      <h2 class="heading-underline">Experince</h2>
      <div *ngFor="let expert of expertProfile; let k = index">
        <div class="accordion accordion-flush" id="accordionFlushExample{{k}}">
          <div class="accordion-item itemCustom">
            <h2 class="accordion-header accordion-headerNew" id="flush-heading{{k}}">
              <button class="accordion-button collapsed" (click)="colaspend(k)" type="button">
                {{expert.company}}
              </button>
            </h2>
            <div id="flush-collapseOne1" class="accordion-collapse {{expandedIndex == k ? 'expanded' : 'collapse'}}" data-bs-parent="#accordionFlushExample{{k}}">
              <div class="row row-acc">
                <div class="col-lg-12 col-md-12 d-flex justify-content-end trasPointer" *ngIf="expert.is_new">
                  <i class="bx bx-trash ml-2" (click)="showAlert(k)"></i>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label class="label-required">Company</label>
                    <input type="text" class="form-control more-input" [(ngModel)]="expert.company" placeholder="Company" />
                    <div *ngIf="!expert.company && formSubmitted" class="text-danger">Company is required</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Location:</label>
                    <input type="text" class="form-control more-input" [(ngModel)]="expert.location" placeholder="Location" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label class="label-required">Designation:</label>
                    <input type="text" class="form-control more-input" [(ngModel)]="expert.position" placeholder="Designation" />
                    <div *ngIf="!expert.position && formSubmitted" class="text-danger">Designation is required</div>
                  </div>
                </div>
               
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label class="label-required">Role:</label>
                    <input type="text" class="form-control more-input" [(ngModel)]="expert.role" placeholder="Role" />
                    <div *ngIf="!expert.role && formSubmitted" class="text-danger">Role is required</div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12">
                  <div class="form-group">
                    <label class="label-required">Start Date:</label>
                    <input type="month" class="form-control more-input" [(ngModel)]="expert.start_year" placeholder="Start Date" [max]="maxDate" />
                    <div *ngIf="!expert.start_year && formSubmitted" class="text-danger">Start Date is required</div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12">
                  <div class="form-group">
                    <label>End Date:</label>
                    <input type="month" class="form-control more-input" [(ngModel)]="expert.end_year" placeholder="End Date" [max]="maxDate" />
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                  <div class="form-group">
                    <label>Till Now:</label>
                    <br />
                    <div class="form-check form-check-inline">
                      <input class="form-check-input till" type="checkbox" [(ngModel)]="expert.till_now" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 d-flex justify-content-end">
          <i class="bx bx-plus ml-2 add-cursor" (click)="addMore()"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label>Industry:</label>
            <input type="text" class="form-control" [(ngModel)]="expertsingle.industry" placeholder="Industry" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="totalExp">Tenure (Years):</label>
            <input type="number" class="form-control" [(ngModel)]="expertsingle.total_exp" placeholder="Tenure (Years)" />
          </div>
        </div>  
        <div class="col-sm-12">
          <div class="form-group">
            <label for="linkedUrl">Linkedin Profile:</label>
            <input type="text" class="form-control" [(ngModel)]="expertsingle.linkedin_url" placeholder="Linkedin Profile" />
          </div>
        </div>
      </div>
    </div>
</ng-container>

<div class="row">
  <div class="col-lg-12 col-md-12">
    <div
      class="btn"
      [ngClass]="{
        'dashboard-btn': validateForm() && !is_loading,
        'dashboard-btn-not': !validateForm() || is_loading,
        'disabled-cursor': !validateForm() || is_loading
      }"
      
      (click)="validateForm() ? onSubmit() : null"
    >
      Save Experince Details
    </div>
  </div>
</div>