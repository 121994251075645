import { Injectable } from "@angular/core";
import { APIServiceService } from "../APIService/apiservice.service";
import { CONFIGS } from "../AppConstants";
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userDataSubject: BehaviorSubject<any>;
  public userData: Observable<any>;

  constructor(private _api: APIServiceService) {
    this.userDataSubject = new BehaviorSubject<any>(false);
    this.userData = this.userDataSubject.asObservable();

    this.getUserSession();
  }

  setUserData(value: any) {
    this.userDataSubject.next(value);
  }

  createUserSession(userData) {
    if(userData.user_meta){
      const isMetaNull = Object.keys(userData.user_meta).every(key => {
        if (userData.user_meta['gender'] === null && userData.user_type && userData.user_type == 1) {
          return true;
        }
      
        return false;
      });
      if(isMetaNull) userData.user_meta = null;
    }
    localStorage.setItem("user_details", JSON.stringify(userData));
    localStorage.setItem("token", userData.token);
    return true;
  }

  getUserSession() {
    const userDetails = localStorage.getItem("user_details");

    if(userDetails) this.setUserData(JSON.parse(userDetails));

    return JSON.parse(userDetails);
  }

  getUserToken() {
    return localStorage.getItem("token");
  }

  resetUserSession() {
    localStorage.removeItem("user_details");
    localStorage.removeItem("token");
    return true;
  }

  getUserType() {
    if (!this.getUserSession()) return false;
    const userType =
      this.getUserSession().user_type == 0
        ? CONFIGS.user_type.user
        : CONFIGS.user_type.expert;

    return userType;
  }

  updateUserSession(data) {
    const currentSessionData = this.getUserSession();
    const updatedUserSession = { ...currentSessionData, ...data };

    this.setUserData(updatedUserSession);
    localStorage.setItem("user_details", JSON.stringify(updatedUserSession));
    return true;
  }
}
