import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
})
export class SearchFormComponent implements OnInit {
  searchForm: FormGroup;

  constructor(
    private _api: APIServiceService,
    private _form: FormBuilder,
    private router: Router
  ) {
    this.searchForm = this._form.group({
      keywords: new FormControl(),
      type: new FormControl('expert'),
      location: new FormControl(),
    });
  }
  ngOnInit(): void {}

  search() {
    const { keywords, type, location } = this.searchForm.getRawValue();
    if (type == "jobs") {
      this.router.navigate(["jobs"], { queryParams: { keywords, location } });
    } else if (type == "courses") {
      this.router.navigate(["courses"], {
        queryParams: { keywords, location },
      });
    }
    else if (type == "expert") {
      this.router.navigate(["experts"], {
        queryParams: { keywords, location },
      });
    }
  }
}
