<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Blog</h2>
          <ul>
            <li>
              <img src="assets/img/home-three/title-img-two.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Blog</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-4" *ngFor="let blog of blogs">
        <div class="blog-item">
          <div class="top">
            <a [routerLink]="['/blog', blog.slug]"
              ><img [src]="blog.thumbnail" alt="Blog"
            /></a>
          </div>
          <h3>
            <a [routerLink]="['/blog', blog.slug]"
              >{{ blog.title.substr(0, 50) }}
            </a>
          </h3>
          <div class="cmn-link">
            <a [routerLink]="['/blog', blog.slug]"
              ><i class="flaticon-right-arrow one"></i> Learn More
              <i class="flaticon-right-arrow two"></i
            ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-area">
      <ngb-pagination
        [collectionSize]="pagination.total"
        [(page)]="pagination.current_page"
        [maxSize]="5"
        [pageSize]="pagination.per_page"
        [boundaryLinks]="true"
        (pageChange)="getBlogs($event)"
      ></ngb-pagination>
    </div>
  </div>
</section>
<!-- End Blog -->
