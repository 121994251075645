<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Experts</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>/</span>
            </li>
            <li>Experts</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->



<!-- Showing -->
<div class="job-showing-area mt-2" *ngIf="this.expertsList.length">
  <div class="container">
    <h4>
      Showing {{ paginationData?.from }} - {{ paginationData?.to }} of
      {{ paginationData?.total-1 }} results
    </h4>
  </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
  <div class="container">
    <div class="col-lg-12 my-3 row" *ngIf="!this.expertsList.length">
      <h2 class="section-title">No Results Found</h2>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="candidate-list-right">
          <div class="candidate-list-all">
            <div class="search">
              <form>
                <h3>Search Keywords</h3>

                <input type="text" name="keywords" class="form-control" (input)="refineSearch($event)"
                  placeholder="Type Your Keywords" />
              </form>
            </div>

            <div *ngFor="let filterCat of filterOptions" class="location-list cmn-padding">
              <h3>{{ filterCategoryFilter(filterCat.name) }}</h3>

              <ul class="candidate-cmn-cat">
                <li *ngFor="let filters of filterCat.options">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [name]="stringTolower(filterCat.field)"
                      [value]="filters.value" (click)="refineSearch($event)" id="defaultCheck1" />
                    <label class="form-check-label" for="defaultCheck1">{{
                      filters.label
                      }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">

        <div *ngIf="loading" class="loading-spinner">
          Loading...
        </div>
        <div *ngIf="!loading">
          <!-- Your data rendering logic here -->
        

        <div class="row candidate-item two" *ngFor="let expert of expertsList; let i = index">
          <div class="col-sm-3 col-4">
            <img [src]="expert.profile_pic" alt="Candidate" />

            <a [routerLink]="['/experts', expert.vanity_url || expert.id]"
              class="btn btn-dark w-100 text-white weight-bold my-1">
              View Profile and Interact
            </a>
          </div>
          <div class="col-sm-9 col-8">
            <h3>
              <a [routerLink]="['/experts', expert.vanity_url || expert.id]">{{ expert.name }}</a>
            </h3>
            <span>{{ expert.position }}</span>
            <ul class="experience">
              <li *ngIf="expert.cost_per_hour">
                Hour Rate: <span>INR {{ expert.cost_per_hour }}</span>
              </li>
              <li *ngIf="expert.location">
                <i class="flaticon-send"></i> {{ expert.location }}
              </li>
            </ul>
            <div [innerHtml]='expert.about ? expert.about.substr(0, 60) + "" : ""'></div>
            <ul class="chips" [innerHtml]="getSkills(expert.skills)"></ul>
          </div>
        </div>

      </div>

      </div>
    </div>

    <div class="pagination-area" *ngIf="this.expertsList.length">
      <ngb-pagination [collectionSize]="paginationData.total" [(page)]="paginationData.current_page" [maxSize]="3"
        [pageSize]="paginationData.per_page" (pageChange)="getRecords($event)" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
</div>
<!-- End Candidate -->