import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { HttpService } from "../HttpService/http-service.service";

@Injectable({
  providedIn: "root",
})
export class APIServiceService {
  constructor(private __http: HttpService) {}

  register(params: any): Observable<any> {
    return this.__http.httpPost("register", params).pipe(map((data) => data));
  }

  login(params: any): Observable<any> {
    return this.__http.httpGet("login", params).pipe(map((data) => data));
  }

  resetPassword(params: any): Observable<any> {
    return this.__http.httpGet("reset-password", params).pipe(map((data) => data));
  }

  socialLogin(params: any): Observable<any> {
    return this.__http
      .httpPost("social-login", params)
      .pipe(map((data) => data));
  }

  logout(): Observable<any> {
    return this.__http.httpGet("logout").pipe(map((data) => data));
  }

  updateUserProfile(params: any): Observable<any> {
    return this.__http
      .httpPost("user/update", params)
      .pipe(map((data) => data));
  }

  updateExpertProfile(params: any): Observable<any> {
    return this.__http
      .httpPost("user/updateExpert", params)
      .pipe(map((data) => data));
  }

  updateExpertEducation(params: any): Observable<any> {
    return this.__http
      .httpPost("user/updateExpertEducation", params)
      .pipe(map((data) => data));
  }
  

  changePassword(params: any): Observable<any> {
    return this.__http
      .httpPost("user/change-password", params)
      .pipe(map((data) => data));
  }

  uploadFiles(files: any): Observable<any> {
    let formData = new FormData();
    var a = 0;
    while (a < files.length) {
      formData.append(`files[${a}]`, files[a], files[a].name);
      a++;
    }
    return this.__http.httpPost("upload-media", formData);
  }

  uploadFilesResume(files: any): Observable<any> {
    let formData = new FormData();
    var a = 0;
    while (a < files.length) {
      formData.append(`files[${a}]`, files[a], files[a].name);
      a++;
    }
    return this.__http.httpPost("upload-resume", formData);
  }

  verifyVanityUrl(params: any): Observable<any> {
    return this.__http.httpGet("vanity-url", params).pipe(map((data) => data));
  }

  expertsList(params: any = {}): Observable<any> {
    return this.__http.httpGet("experts", params).pipe(map((data) => data));
  }

  viewExpert(vanity_url: string): Observable<any> {
    return this.__http
      .httpGet(`experts/${vanity_url}`)
      .pipe(map((data) => data));
  }

  reviewExpert(params: any): Observable<any> {
    return this.__http
      .httpPost("experts/review", params)
      .pipe(map((data) => data));
  }

  scheduleCall(params: any): Observable<any> {
    return this.__http
      .httpPost("schedule-call", params)
      .pipe(map((data) => data));
  }

  scheduleJob(params: any): Observable<any> {
    return this.__http
      .httpPost("job-request", params)
      .pipe(map((data) => data));
  }

  reviewResume(params: any): Observable<any> {
    return this.__http
      .httpPost("resume-request", params)
      .pipe(map((data) => data));
  }

  getSchedulesJobs(params: any = {}): Observable<any> {
    return this.__http.httpGet("job-request", params).pipe(map((data) => data));
  }

  updateSchedulesJobs(params: any = {}): Observable<any> {
    return this.__http.httpPut("job-request", params).pipe(map((data) => data));
  }

  scheduleCalls(): Observable<any> {
    return this.__http.httpGet("scheduled-calls").pipe(map((data) => data));
  }

  getRequestedResume(): Observable<any> {
    return this.__http
      .httpGet("get-requested-resume")
      .pipe(map((data) => data));
  }

  updateCall(params: any): Observable<any> {
    return this.__http
      .httpPost("update-call", params)
      .pipe(map((data) => data));
  }

  updateResumeRequest(params: any): Observable<any> {
    return this.__http
      .httpPost("update-resume-request", params)
      .pipe(map((data) => data));
  }

  createRazorOrder(params: any): Observable<any> {
    return this.__http
      .httpPost("pg/create-order", params)
      .pipe(map((data) => data));
  }

  saveOrder(params: any): Observable<any> {
    return this.__http
      .httpPost("pg/update-order", params)
      .pipe(map((data) => data));
  }

  createChatRoom(params: any): Observable<any> {
    return this.__http
      .httpPost("chat/create-room", params)
      .pipe(map((data) => data));
  }

  getRoomsList(userType: any): Observable<any> {
    return this.__http
      .httpGet("chat/rooms/", userType)
      .pipe(map((data) => data));
  }

  getChatMessages(room_id: any): Observable<any> {
    return this.__http
      .httpGet("chat/messages/" + room_id)
      .pipe(map((data) => data));
  }

  sendMessage(params: any): Observable<any> {
    return this.__http
      .httpPost("chat/message", params)
      .pipe(map((data) => data));
  }

  blogs(params): Observable<any> {
    const { page = 1, slug = "" } = params || {};
    return this.__http
      .httpGet("blogs/" + slug, params)
      .pipe(map((data) => data));
  }

  recentPosts(): Observable<any> {
    return this.__http.httpGet("blogs/recent").pipe(map((data) => data));
  }

  getCourses(params: any, headers: any = {}): Observable<any> {
    return this.__http
      .httpGet("courses", params, headers)
      .pipe(map((data) => data));
  }

  getJobs(params: any, headers: any = {}): Observable<any> {
    return this.__http
      .httpGet("jobs", params, headers)
      .pipe(map((data) => data));
  }

  updateExpertBankAccount(params: any): Observable<any> {
    return this.__http
      .httpPost("bank-account", params)
      .pipe(map((data) => data));
  }

  getHomePage() {
    return this.__http.httpGet("home").pipe(map((data) => data));
  }

  enquiry(params: any) {
    return this.__http.httpPost("enquiry", params).pipe(map((data) => data));
  }

  getFilters(params: any) {
    return this.__http.httpGet("filters", params).pipe(map((data) => data));
  }

  events(params): Observable<any> {
    const { page = 1, slug = "" } = params || {};
    return this.__http.httpGet("events", params).pipe(map((data) => data));
  }

  eventDetail(params): Observable<any> {
    const { page = 1, slug = "" } = params || {};
    return this.__http
      .httpGet("event/" + slug, params)
      .pipe(map((data) => data));
  }

  forBusinessess(params: any) {
    return this.__http
      .httpPost("business-contact", params)
      .pipe(map((data) => data));
  }

  /**
   * Validate all fields on submit button click event
   */

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getExpertsServices() {
    return this.__http.httpGet("services").pipe(map((data) => data));
  }

  createService(params: any) {
    return this.__http.httpPost("services", params).pipe(map((data) => data));
  }

  updateService(params: any) {
    return this.__http.httpPut("services", params).pipe(map((data) => data));
  }

  getExpertService(serviceID) {
    return this.__http
      .httpGet(`services/${serviceID}`)
      .pipe(map((data) => data));
  }

  getPaymentsHistory(params = {}) {
    return this.__http.httpGet("payments", params).pipe(map((data) => data));
  }

  getNotifications() {
    return this.__http.httpGet("notifications", null, {hideLoader: true}).pipe(map((data) => data));
  }

  updateNotifications(params: any) {
    return this.__http.httpPut("notifications", params).pipe(map((data) => data));
  }

  getAnonymousNames(params: any = null) {
    return this.__http.httpGet("anonymous-names", params, {hideLoader: true}).pipe(map((data) => data));
  }

  getRandomExpertList() {
    return this.__http.httpGet("experts/random_expert_detail").pipe(map((data) => data));
  }
  
}
