<!-- Navbar -->

<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logoIcon.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Terms & Conditions -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                The following are the terms and conditions that govern your use of our website, www.ExpertDoor.com, and
                our mobile app, ExpertDoor App (&quot;App&quot;). &quot;ExpertDoor Platform&quot; refers to both the
                website and the app as a whole. We reserve the right to change these Terms at any time without prior
                notification to you, and the modified Terms will apply to you. On the website and in the App, you can
                always find the most recent version of the Terms.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                CAREFULLY READ THE FOLLOWING TERMS OF SERVICE BEFORE USING ANY SERVICE. BY ACCESSING, BROWSING, AND/OR
                USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY ALL OF THE
                FOLLOWING CONSENTS AND DISCLOSURES (WHICH ARE HEREBY INCORPORATED HEREIN BY THIS REFERENCE). ALL OF THE
                TERMS AND CONDITIONS BELOW, INCLUDING ANY FUTURE MODIFICATIONS TO THESE TERMS OF SERVICE, AS WELL AS ALL
                GUIDELINES AND PRIVACY POLICY (COLLECTIVELY, THE TERMS OF SERVICE). PLEASE STOP USING THE SERVICES
                IMMEDIATELY IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <strong><span style="font-size:35px;line-height:107%;font-family:Poppins;color:#333333;">GENERAL
                        TERMS</span></strong></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                1. ExpertDoor Technologies Private Limited, a ExpertDoor formed under the laws of India with its
                registered office at Hennur Main Road, Bangalore 560077 (&quot;ExpertDoor&quot; or &quot;us&quot; or
                &quot;we&quot;), owns the domain name (&quot;Website&quot;) www.ExpertDoor.com and the ExpertDoor App.
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                2. The following terminology must have the following meanings:</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                a) &quot;Aspirant&quot; means any person who uses the Services by viewing the Content on the Website;
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                b) &quot;Content&quot; means any and all content on and off the Website that is uploaded by experts and
                provided by ExpertDoor in connection with the Website;&nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                c) &quot;Expert&quot; means any person who uses the Services by uploading any Content on the Website and
                providing assistance to the Aspirants; d) &quot;Expert&quot; means any person who uses the Services by
                uploading any Content on the Website and providing assistance</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                d) &quot;Privacy Policy&quot; refers to the company&apos;s privacy policies.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                e) &quot;User&quot; or &quot;you&quot; refers to any legal person or entity that accesses or uses the
                Website and/or Services, whether as an Expert or as an Aspirant;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                f) &quot;We&quot; or &quot;us&quot; refers to ExpertDoor.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                3. These Terms apply to all ExpertDoor Platform Users, including Mentors/Experts who also provide
                ExpertDoor Platform Content. Mentors/Experts, where relevant, are included in the term User.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                4. The User is aware of and expressly agrees to be bound by the Privacy Policy&apos;s rules, guidelines,
                policies, terms, and conditions. The User explicitly and absolutely agrees to be bound by the provisions
                of the Privacy Policy, which are thus incorporated into the Terms.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                5. By visiting the Website by any computing or mobile platform, the User accepts and acknowledges that
                he or she has carefully read and understood the Terms that govern the use of Services and that he or she
                is bound by the Terms for as long as he or she is accessing or using the Services.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                6. We reserve the right to make modifications to the Terms at any time, and all changes become effective
                immediately after they are posted on the Website. It is the User&apos;s obligation to check these Terms
                for any modifications or changes on a regular basis. Any use of the Website following the posting of an
                update/change notice will be construed as acceptance of the changes by the User.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                7. The User acknowledges that he or she will not use the Website for any purpose that is forbidden by
                these Terms or by applicable law. The Website is for your personal, non-commercial use only. You may not
                duplicate, edit, distribute, reproduce, publish, licence, transfer, or otherwise use this Website or the
                information and contents on this Website in any way, in whole or in part, unless otherwise permitted by
                these Terms.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                8. You certify that you are at least 18 years old, or that you have legal parental or guardian consent,
                and that you are fully capable and competent to enter into the terms, conditions, obligations,
                affirmations, representations, and warranties set forth in these Terms, as well as to abide by and
                comply with these Terms. Please consult your parents or guardians before using the ExpertDoor Platform
                if you are under the age of 18.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                9. The Terms &amp; Conditions, as well as the Privacy Policy, form a legally enforceable agreement
                between you and ExpertDoor. You can get a copy of this Agreement by sending an email to
                support@ExpertDoor.com and asking for one. The provisions of the (Indian) Information Technology Act,
                2000 (&quot;IT Act&quot;) and the rules issued thereunder, as well as the revised sections related to
                electronic documents/records in different statutes as changed by the IT Act from time to time, create an
                electronic contract. These Terms of Service do not require any physical, electronic, or digital
                signatures, and they are a legally enforceable contract between you and ExpertDoor.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                10. Please check our Privacy Policy, accessible at ExpertDoor Platform, in conjunction with your use of
                the Service, to understand how we utilise information we gather from you when you access, visit, or use
                the Service. By consenting to these Provisions of Service, you agree to be bound by the conditions of
                the Privacy Policy and accept that we may use information acquired from you in line with those terms.
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">ACCESS AND USE OF THE SERVICES</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                1. By interacting through the Website, we give Users with suitable career advise via our AI-assisted
                video bot platform. The User understands that any information supplied by us on the website is for
                informational reasons only and is not intended to be taken as a business offer or to establish a
                professional relationship.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                2. ExpertDoor thus provides you a non-exclusive, non-transferable, limited licence to use the
                ExpertDoor Platform in accordance with these Terms, the Privacy Policy, and any other rules and
                policies made accessible or published by ExpertDoor.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">REGISTRATION</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        In order to use some elements of the Services, the User may be asked to register with the
                        Website. If a User decides to register for the Services, he or she undertakes to submit and
                        maintain true, accurate, and full information about himself or herself as prompted by the
                        registration form for the Services. Our Privacy Policy governs registration data and some other
                        information about the User. In order to use certain features of our Services, the User may be
                        required to register with (and agree to the terms of) third-party service providers. While we
                        may assist in the registration process in some situations, we are not a party to any such
                        agreements and disclaim all responsibility or liability for the actions of third parties. We may
                        share information with these third-party services to make the offering of Services easier (and
                        related third-party services).</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: undefined;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        You agree and acknowledge that you will never use another User&apos;s account or provide any
                        third-party access to your account. You will be entirely responsible for the actions that take
                        place on your account, and you must keep your account password private and not disclose it to
                        anyone else. Any breach of security or illegal use of your account must be reported promptly to
                        ExpertDoor. ExpertDoor will not be liable for any damages incurred as a result of any
                        unauthorised use of your account; you will be entirely responsible for any losses incurred by
                        ExpertDoor or others as a result of such unauthorised use, if any.</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">MOBILE SERVICES</span>
            </p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        The User accepts and recognises that, to the extent that he or she uses a mobile device to
                        access the Services:</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:18.0pt;'>
                a) Standard charges, data rates, and other costs imposed by the User&apos;s cellular service carrier may
                apply.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:18.0pt;'>
                b) Furthermore, the User&apos;s carrier may prevent or limit the User from downloading, installing, or
                using certain mobile services, and not all mobile services may function with all carriers or devices.
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">ACCURACY OF
                    INFORMATION</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                1. Despite the fact that we may post information and material on the Website from reputable sources, we
                make no explicit or implied representation, warranty, or guarantee as to the correctness, authenticity,
                reliability, or completeness of such information.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                2. The Website&apos;s features and services are offered &quot;as is&quot; and &quot;as available&quot;
                basis. We make no claim or assurance as to the accuracy, completeness, or reliability of any information
                supplied on or via the Website, including but not limited to blog material.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">MODIFICATIONS TO SERVICES</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        We retain the right, with or without warning, to alter or discontinue the Services (or any
                        portion thereof) temporarily or permanently. The User accepts that we shall not be responsible
                        to the User or any third party if the Services are modified, suspended, or discontinued.</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                2. ExpertDoor reserves the right to suspend or terminate access to any Content, portion of Content, or
                category of Content, as well as to alter or delete any transmission method, as well as transmission
                speeds and other signal characteristics. ExpertDoor maintains the right to deny access to the
                ExpertDoor Platform, terminate User accounts, and delete or modify content at any time without prior
                notice.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                3. You accept to receive ExpertDoor installations and upgrades from time to time. These updates are
                intended to improve, extend, and further develop the ExpertDoor Platform, and may include bug patches,
                new software modules, and new versions. As part of your usage of the ExpertDoor Platform, you agree to
                receive such updates (and provide ExpertDoor permission to do so).</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">PROPRIETARY
                    RIGHTS</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        We are the only owner or legal licensee of all of the Website&apos;s and Content&apos;s rights.
                        The Website and Services contain copyrighted material, trademarks, and other proprietary
                        information owned by us and/or our licensors, and the User agrees not to reproduce our
                        proprietary rights separately from the textual content associated with them without first
                        obtaining our prior written consent. Any proprietary notices found on any Content, including
                        copyright, trademark, and other intellectual property notices, must not be removed, obscured, or
                        otherwise altered by the User.</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
            <ol style="list-style-type: undefined;">
                <li>The User undertakes not to engage in or employ data mining, robots, scraping, or other similar data
                    collection or extraction methods. If we prevent the User from using the Services (for example, by
                    blocking his or her IP address), the User undertakes not to take any steps to bypass the blockage
                    (e.g., by masking his or her IP address or using a proxy IP address). It is completely forbidden to
                    use the Services or the Services in any way other than as expressly authorised below.</li>
                <li>By uploading any Content, the User hereby grants us and our affiliates an exclusive, worldwide,
                    royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy,
                    display, upload, perform, distribute, store, modify and otherwise use the Content in connection with
                    the operation of the Services or the promotion, advertising or marketing thereof, in any form.
                    Without limiting the foregoing, if any Content contains the User&rsquo;s name, image or likeness,
                    the User agrees to release and hold us, our affiliates, and employees harmless from:</li>
            </ol>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                a) Any and all claims for invasion of privacy, publicity, or libel;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                b) Any and all liabilities for claims brought by the User (or any successor to any claim brought by the
                User) in connection with the Content. The User agrees and acknowledges that his or her participation in
                the Services and submission of Content is purely voluntary, that he or she will not receive any
                financial compensation of any kind in connection with the licences, waivers, and releases set forth
                herein (or our exploitation thereof), and that the sole consideration for the subject matter of these
                Terms is the ability to use the Services.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                4. Even if you terminate or delete your account on the ExpertDoor Platform, the above-mentioned
                licences given by you in the Content you submit to the ExpertDoor Platform will remain valid, and
                ExpertDoor may store or utilise such Content.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                5. The User acknowledges and agrees that we may store and disclose any Content if required to do so by
                law or in the good faith belief that such storing or disclosure is reasonably necessary to: (a) comply
                with legal process, applicable laws, or government requests; (b) enforce these Terms; (c) respond to
                claims that any content violates third-party rights; or (d) protect our rights, property, personal
                safety, or that of our users or the public.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">THIRD-PARTY WEBSITES/SERVICES</span></p>
            <ol style="list-style-type: decimal;">
                <li>The Services may provide or enable connections to other sites, services, and resources on the
                    internet, or third parties may do so. We have no control over such sites, services, or resources,
                    and we are not responsible for or endorsing them. We will not be responsible or liable, directly or
                    indirectly, for any damage or loss caused or claimed to be caused by or in connection with use of or
                    reliance on any content, events, products or services available on or from any such site, service,
                    or resource. Any interactions the User has with third parties while using the Services are solely
                    between the User and the third party, and the User accepts that we are not responsible for any
                    damage or claim the User may have against such third party.</li>
                <li>You realise and agree that the Service&apos;s availability may be contingent on third-party websites
                    from which you obtain it, such as the Apple App Store or Google Play Store (each a &quot;Third Party
                    App Store&quot;). You accept that these Terms of Service apply only to you and ExpertDoor, and not
                    to any Third Party App Store. You must agree to the terms and conditions of each Third Party App
                    Store before downloading the Service from it. You agree to abide by the appropriate Third Party App
                    Store terms and conditions, and your permission to use the Service is contingent on your doing so.
                    If additional terms and conditions from the appropriate Third Party App Store are less stringent
                    than, or otherwise conflict with, the more restrictive or conflicting terms and conditions in these
                    Terms of Service will apply.</li>
            </ol>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">ACCESS, PERMISSIONS AND
                    RESTRICTIONS</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        By using the ExpertDoor Platform and/or Services, the User undertakes to refrain from engaging
                        in certain acts, including but not limited to:</li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                a) Downloading or duplicating any information for the advantage of another person, seller, or third
                party;&nbsp;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:18.0pt;'>
                b) Caching unauthorised hypertext connections to the Website;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:18.0pt;'>
                c) Uploading, posting, or sending any Content made accessible through the Website that he or she does
                not have the right to make public (for example, another party&apos;s intellectual property);</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                d) Uploading, posting, or sending any content containing software viruses or any other computer code,
                files, or programmes that are designed to disrupt, damage, or restrict the operation of any computer
                software or hardware, or telecommunications equipment;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                e) Taking any activity that places, or may place, an excessive or disproportionately high burden on our
                system, in our sole judgement;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                f) Using data mining, robots, or similar data collection and extraction techniques;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                g) Bypassing any of our security or access restrictions on the Website;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                h) Using the ExpertDoor Platform &nbsp;or its Contents for any commercial purpose including but not
                limited to (i) sale of access to the ExpertDoor Platform, (ii) sale of advertising, sponsorships, or
                promotions placed on or within the ExpertDoor Platform or Content or (iii) sale of advertising,
                sponsorships, or promotions of ExpertDoor on any page or website that provide similar ExpertDoor
                Platform;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                i) Making any speculative, false, or fraudulent transaction or any transaction in anticipation of
                demand;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                j) Accessing, monitoring or copying any Content or information on the Website using any robot, spider,
                scraper or other automated means or any manual process for any purpose without our express written
                permission;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                k) Sending chain letters, bulk or junk email, or interfering with, disrupting, or putting an undue load
                on the Service or the networks or services linked to the Service, including hacking into the Service;
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                l) Impersonate another person or entity, sell or allow others to use your profile or password, offer
                false or misleading identification or address information, or infringe or violate another person&apos;s
                or entity&apos;s privacy or personal or property rights;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                m) Bypassing or subverting any procedures used to prohibit or limit access to the Website, such as robot
                exclusion headers;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                n) Deep-linking to any section of the Website (including, without limitation, the purchase route for any
                service) for any purpose without our prior written authorization;</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                o) Without our prior written permission, &quot;framing,&quot; &quot;mirroring,&quot; or otherwise
                integrating any element of the Website onto another website.</p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span style="color:black;">p) Submit Content, write reviews, leave comments, or other communications
                    that are not illegal, obscene, threatening, defamatory, intrusive of privacy, infringing on
                    intellectual property rights, or otherwise objectionable to third parties; and upload Contributions
                    that:</span><span
                    style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;</span><span
                    style="color:black;border:none windowtext 1.0pt;padding:0cm;">(i) are defamatory, damaging,
                    disruptive, unlawful, inappropriate, offensive, inaccurate, pornographic, vulgar, indecent, profane,
                    hateful, racially or ethnically offensive, obscene, lewd, lascivious, filthy, threatening,
                    excessively violent, harassing, or otherwise objectionable; (ii) incite, encourage or threaten
                    immediate physical harm against another, including but not limited to, Contributions that promote
                    racism, bigotry, sexism, religious intolerance or harm against any group or individual; or (iii)
                    contain material that solicits personal information from anyone under the age of 18 or exploits
                    anyone in a sexual or violent manner.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span style="color:black;border:none windowtext 1.0pt;padding:0cm;">&nbsp;</span></p>
            <ol style="list-style-type: undefined;">
                <li><span style='font-family:"Times New Roman";color:black;border:none windowtext 1.0pt;'>In addition to
                        the restrictions listed above, you must comply with the following limits and requirements when
                        using the Content:</span></li>
                <li><span style='font-family:"Times New Roman";color:black;border:none windowtext 1.0pt;'>ExpertDoor
                        owns or licences the Content used on the ExpertDoor Platform, which includes but is not limited
                        to trademarks, service marks, and logos, process, pictures, software, and graphics, and is
                        subject to copyright and other intellectual property rights under applicable law.</span></li>
                <li><span style='font-family:"Times New Roman";color:black;border:none windowtext 1.0pt;'>You are
                        provided with the Content on a &quot;as is&quot; basis. You may only access the Content for your
                        own personal information and use, as authorised by these Terms. You may not download any Content
                        unless ExpertDoor has provided a &apos;download&apos; or equivalent link for that Content on
                        the ExpertDoor Platform.</span></li>
                <li><span style='font-family:"Times New Roman";color:black;border:none windowtext 1.0pt;'>Without the
                        prior written consent of ExpertDoor or the respective licensors of the Content, you may not
                        copy, reproduce, make available online or electronically transmit, publish, adapt, distribute,
                        transmit, broadcast, display, sell, licence, or otherwise exploit any Content for any purpose
                        other than as provided herein. All rights not explicitly granted in and to the ExpertDoor
                        Platform and the Content are reserved by ExpertDoor and its licensors.</span></li>
                <li><span style='font-family:"Times New Roman";color:black;border:none windowtext 1.0pt;'>You
                        acknowledge that by using the ExpertDoor Platform, you will be exposed to Content from a number
                        of sources and created by a variety of Users, and that ExpertDoor is not responsible for the
                        accuracy, usefulness, safety, or intellectual property rights of or pertaining to such
                        Content.</span><span style="color:black;">&nbsp;</span><span
                        style="border:none windowtext 1.0pt;">You also understand and acknowledge that you may be
                        exposed to Content that is inaccurate, offensive, indecent, or objectionable, and you agree to
                        waive, and hereby do waive, any legal or equitable rights or remedies you have or may have
                        against ExpertDoor with respect thereto, and to indemnify and hold harmless ExpertDoor, its
                        owners, operators, affiliates, licensors, and licensees to the fullest extent permitted by
                        applicable law.</span></li>
            </ol>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span style="color:black;border:none windowtext 1.0pt;padding:0cm;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span style="color:black;border:none windowtext 1.0pt;padding:0cm;">3. The following requirements also
                    apply to the Content uploaded by a Mentor User:</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">a) You represent and declare that you have all required permissions and
                    approvals for generating and submitting the Content, and that using the ExpertDoor Platform to
                    create and submit the Content will not violate any contract you have signed. ExpertDoor will not be
                    held accountable if you fail to get any authorization or if you breach any contract with a third
                    party.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">b) You may upload Content, such as videos and comments, to the ExpertDoor
                    Platform as a User. You accept and recognise that ExpertDoor makes no assurances about the
                    confidentiality of any Content you provide.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">c) You are fully responsible for your own Content, as well as the
                    repercussions of submitting and distributing it on the ExpertDoor Platform.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">d) You represent and warrant that you own or have the relevant licences,
                    rights, consents, and permissions to publish the Content that you submit, and that you own or have
                    such licences, rights, consents, and permissions.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">e) You also agree that any Content you submit to the ExpertDoor Platform
                    will not contain third-party copyrighted material or material subject to other third-party
                    proprietary rights unless you have permission from the material&apos;s rightful owner or are
                    otherwise legally entitled to post the material and grant ExpertDoor all of the licence rights
                    granted herein.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">f) You represent, warrant and confirm that the Content uploaded by you is
                    not: (i) false, inaccurate or misleading, (ii) infringe any third party&rsquo;s copyright, patent,
                    trademark, trade secret or other proprietary rights or rights of publicity or privacy, (iii) violate
                    any law, statue, ordinance or regulation, (iv) defamatory, unlawfully threatening or unlawfully
                    harassing, (v) obscene or contain pornography and (vi) Contain any viruses, trojan horses, worms,
                    cancelbots or other computer programming routines that may damage, detrimentally interfere with,
                    surreptitiously intercept or expropriate any system, data or personal information</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">g) You agree that while using the ExpertDoor Platforms, you will not post or
                    publish any content, remarks, or act in any way that would constitute harassment of any User,
                    whether an Aspirant or a Mentor. If ExpertDoor becomes aware that you are harassing other Users,
                    ExpertDoor reserves the right to suspend or delete your account with immediate effect and without
                    notice, and we also retain the right to pursue legal action against you in such situations.</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">h) ExpertDoor may, in its sole discretion, evaluate any audio or
                    audio-visual content you upload to the ExpertDoor Platform to determine if it meets
                    ExpertDoor&apos;s quality standards and is compatible with the ExpertDoor Platform.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">i) ExpertDoor explicitly disclaims any and all liability in connection with
                    any Content submitted on the ExpertDoor Platform by any User or other licensor, as well as any
                    opinion, suggestion, or advice stated therein. ExpertDoor does not allow copyright infringement or
                    intellectual property rights infringement on the ExpertDoor Platform, and if properly alerted,
                    ExpertDoor will delete all Content that infringes on another&apos;s intellectual property rights.
                    If ExpertDoor has cause to think that any Content infringes on any copyright, it retains the right
                    to delete it without notice.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">INDEMNITY</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">The User agrees to hold us, our affiliates, and their officers, employees,
                    directors, and agents harmless from any and all losses, damages, expenses, rights, claims, actions
                    of any kind, and injury (including death) arising out of or relating to the User&apos;s use of the
                    Services, any Content, in connection with the Services, and/or the User&apos;s violation of these
                    Terms.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">LIMITATION OF
                    LIABILITY</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">In no event shall we be liable to compensate the User or any third party for
                    any special, incidental, indirect, consequential or punitive damages whatsoever, including those
                    resulting from loss of use, data or profits, whether or not foreseeable, and whether or not we had
                    been advised of the possibility of such damages, based on any theory of liability, including breach
                    of contract or warranty, negligence or other tortuous action, or any other claim arising out of or
                    in connection with the User&rsquo;s use of or access to the Website and/or the Services. We reserve
                    the right to assume exclusive control of its defence, and of any matter otherwise subject to
                    indemnification by the User, in which event the User will fully cooperate with us in connection
                    therewith, and hereby expressly agrees to bear the cost thereof.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">TERMINATION</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <span style="font-family:Calibri;color:black;">The User agrees that we may suspend or terminate
                            the User&apos;s account (or any part thereof) or use of the Services, as well as remove and
                            discard any Content within the Services, in our sole discretion, for any reason, including,
                            without limitation, for lack of use or if we believe the User has violated or acted
                            inconsistently with the letter or spirit of these Terms. Any suspected fraudulent, abusive,
                            or illegal behaviour that might result in the User&apos;s usage of the Services being
                            terminated may be reported to appropriate law enforcement authorities.</span> <span
                            style="font-family:Calibri;">The User may also, at any time and without notice, cease
                            offering the Services, or any portion thereof, in its sole discretion. The User acknowledges
                            and agrees that any termination of his or her access to the Services under any provision of
                            these Terms may be effected without prior notice, and that we may immediately deactivate or
                            delete the User&apos;s account and all related information and files in his or her account,
                            as well as bar any further access to such files or the Services. Furthermore, the User
                            acknowledges that if the User&apos;s access to the Services is terminated, we will not be
                            responsible to the User or any other person.</span></li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: undefined;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <span style="font-family:Calibri;color:black;">Any provision of these Terms that imposes or
                            envisions continuing responsibilities on the User shall survive the expiration or
                            termination of these Terms, notwithstanding any other terms of these Terms or any general
                            legal principles to the contrary.</span></li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: undefined;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <span style="font-family:Calibri;color:black;">In the event that a User&apos;s account or access
                            to the Services is suspended or terminated, ExpertDoor maintains the right, in its sole
                            discretion, to withhold or terminate any and all payments due to such User. Payments will be
                            provided only for any genuine work that is not the subject of any dispute or violation.
                            Payments for the work that is the subject of any of the violations will not be made. User
                            access will be instantly revoked, and the User will no longer be able to access the
                            account.</span></li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
            <div
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <ol style="margin-bottom:0cm;list-style-type: undefined;">
                    <li
                        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <span style="font-family:Calibri;color:black;">Any and all sections relating to or concerning
                            Proprietary Rights, Limitation of Liability, Confidentiality, and Indemnification will
                            survive any termination of these Terms and Conditions or your use or subscription to the
                            Services.</span></li>
                </ol>
            </div>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">CONFIDENTIALITY</span></p>
            <ol style="list-style-type: decimal;margin-left:44px;">
                <li><span style="font-family:Calibri;color:black;">You will not, without ExpertDoor&apos;s prior
                        written agreement, reveal any Confidential Information that is provided to you during the course
                        of your use of the ExpertDoor Platform to a third party.</span></li>
                <li><span style="font-family:Calibri;color:black;">Employee data, User list, business model, methods,
                        ideas, concepts, and other pertaining to ExpertDoor or ExpertDoor Platform that are not
                        available in the public domain are examples of confidential information. You understand and
                        accept that the Confidential Information given to you will remain the property of ExpertDoor at
                        all times, and that any breach of this agreement will result in irreparable harm to us.</span>
                </li>
            </ol>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">WAIVER AND
                    SEVERABILITY</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">The User acknowledges that no action of ExpertDoor, other than an explicit
                    written waiver or amendment, may be considered as a waiver or alteration of any of these Terms. If
                    any portion of these Terms is found to be invalid or unenforceable by a court or other competent
                    authority, the other provisions of these Terms will remain in effect.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">GOVERNING LAW AND
                    DISPUTE RESOLUTION</span></p>
            <ol style="list-style-type: decimal;">
                <li><span style="font-family:Calibri;color:black;">This is the first resolution. We may be reached at
                        support@ExpertDoor.com if you have any questions or concerns about how to utilise the Service.
                        The majority of issues may be fixed swiftly in this manner. Each of you and the ExpertDoor
                        undertake to use reasonable attempts to resolve any dispute, claim, issue, or disagreement
                        directly by consultation and good faith discussions, which shall be a condition precedent to
                        either party pursuing legal action.</span></li>
                <li><span style="font-family:Calibri;color:black;">These Terms are governed by, construed, and enforced
                        in conformity with Indian law. All matters arising out of these Terms or the use of the Website
                        shall be decided exclusively by the courts of Mumbai, subject to the other provisions of this
                        section.</span></li>
                <li><span style="font-family:Calibri;color:black;">Any disagreements, conflicts, disputes, or
                        differences arising out of these Terms will be addressed by arbitration in Bangalore in
                        accordance with the current version of the Arbitration and Conciliation Act, 1996, which is
                        presumed to be incorporated by reference in this section. The tribunal will be made up of one
                        arbitrator. The arbitration will be conducted in English.</span></li>
                <li><span style="font-family:Calibri;color:black;">Unless compelled by law, the parties to the
                        arbitration must keep the arbitration secret and not reveal it to anybody except those who need
                        to know. The arbitrator&apos;s ruling is final and binding on all of the parties to this
                        agreement.</span></li>
                <li><span style="font-family:Calibri;color:black;">In the event of a dispute, each party to the
                        arbitration bears its own costs.</span></li>
            </ol>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">INDEPENDENT
                    CONTRACTORS</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">Nothing in these Terms of Service should be construed as establishing any
                    form of agency, partnership, joint venture, employer-employee, or franchisor-franchisee relationship
                    between us and any user.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">NO MODIFICATIONS BY OUR
                    EMPLOYEES</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">If one of our employees offers to change the terms of these Terms of Service,
                    he or she is not acting on our behalf or acting as an agent for us. Any statement or contact from
                    our employees or anyone else claiming to act on our behalf is not to be trusted, and you should not
                    act on it.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">NOTICES</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">You agree that the ExpertDoor may send you communications via email, normal
                    mail, or posts on the Site or Services, including information about changes to these Terms of
                    Service.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">REFUND POLICIES</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">If the Student withdraws from the ExpertDoor Program for any reason and at
                    any time, the Student must give the Company and the Company Agent with a written notice of 10 (ten)
                    days&apos; notice of such withdrawal from the ExpertDoor Program and the effective date of such
                    withdrawal. The following circumstances apply if the Student withdraws from the ExpertDoor Program
                    within 3 (three) days of the program&apos;s start date: the Student is responsible for the cost of
                    training and assistance received, and no tuition fee refund will be handled.</span> <span
                    style="color:black;">If the Student drops out after 10 (ten) days from the start of the ExpertDoor
                    Program, the Student is still responsible for all financial obligations and other terms and
                    conditions set out in this Agreement. For the avoidance of dispute, the Payment Term will begin on
                    the date the Student departs the ExpertDoor Program.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:30px;line-height:107%;font-family:Poppins;color:#333333;">COMMUNICATION</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">ExpertDoor can receive any and all communications pertaining to any problem
                    or grievance suffered by the User by contacting us at:</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <strong><span
                        style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>Name:</span></strong><span
                    style="font-size:16px;font-family:Poppins;color:#707070;">&nbsp;ExpertDoor Technology Private
                    Limited</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <strong><span
                        style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>Email:&nbsp;</span></strong><span
                    style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>support@ExpertDoor.com</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <strong><span
                        style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>Phone:&nbsp;</span></strong><span
                    style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>+91
                    7310768702</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:baseline;'>
                <span style="font-size:30px;font-family:Poppins;color:#333333;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:18.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                <span style="color:black;">&nbsp;</span></p>
        </div>
    </div>
</div>
<!-- End Terms & Conditions -->
