import { Component, OnInit } from "@angular/core";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  blogs: any;
  pagination: any;

  constructor(private _api: APIServiceService) {
    this.getBlogs();
  }

  getBlogs(page = 1) {
    this._api.blogs({ page }).subscribe((data) => {
      this.blogs = data.data.data;
      this.pagination = {
        from: data.data.from,
        per_page: data.data.per_page,
        current_page: data.data.current_page,
        total: data.data.total,
      };
    });
  }

  ngOnInit(): void {}
}
