import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-expert',
  templateUrl: './signup-expert.component.html',
  styleUrls: ['./signup-expert.component.scss']
})
export class SignupExpertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
