import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CONFIGS } from "src/app/services/AppConstants";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";



@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  
})
export class DashboardComponent implements OnInit {
  progress: number = 0;
  mode: any;
  background: string = 'red';
  user_type: any = CONFIGS.user_type;
  user: any;
  userMeta: any;
  isExpert: any;
  changePassword: FormGroup;
  bankDetails: FormGroup;
  scheduledCalls: any;
  requestedResume: any;
  selectedAppointment: any = false;
  selectedResume: any = false;
  bank: any ;
  isNewSignup: Boolean = false;
  resumePagination: any = {};
  callPagination: any = {};
  approvalData: any = null;
  param_call_id: any = null;
  approvedJobPayment: any = null; 

  filterCalls: any = {
    page: 1,
    // "type[]": "call",
  };

  filterResumes: any = {
    page: 1,
    "type[]": "resume",
  };

  manageAppointment: FormGroup;
  manageResumeRequest: FormGroup;
  manageJobApprovalRequest: FormGroup;

  uploadedFiles: any;

  progressValue: number = 0;

  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;
  // @ViewChild("accountSetup") accountSetup;
  @ViewChild("bankDetailsUpdate") bankDetailsUpdate;
  @ViewChild("expertProfile") expertProfile;
  @ViewChild("approveRequest") approveRequest;
  @ViewChild("pendingExpertResponse") pendingExpertResponse;
  @ViewChild("customServicePayment") customServicePayment;
  @ViewChild('content') content: ElementRef;

  constructor(
    private _user: UserService,
    private _form: FormBuilder,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    private modalService: NgbModal,
    private _router: Router,
    public loaderService: LoaderService,
    private route: ActivatedRoute,
  

  ) {
    this.isExpert = _user.getUserType() == this.user_type.expert;
    this.user = _user.getUserSession();
    this.isNewSignup = this.user.is_new;
    
    this.userMeta = this.user.user_meta;
    this.bank = this.user.bank_details;

    this.bankDetails = this._form.group({
      account_name: new FormControl(this.bank?.account_name),
      ifsc_code: new FormControl(this.bank?.ifsc_code),
      account_number: new FormControl(this.bank?.account_number),
    });

    this.changePassword = this._form.group({
      old_password: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
      password_confirmation: new FormControl("", [Validators.required]),
    });

    this.manageAppointment = this._form.group({
      selected_time: new FormControl(),
      meeting_url: new FormControl(),
      status: new FormControl(),
    });

    this.manageResumeRequest = this._form.group({
      updated_resume: new FormControl(),
      image_upload: new FormControl(),
      remarks: new FormControl(),
      status: new FormControl(),
    });

    this.manageJobApprovalRequest = this._form.group({
      remarks: new FormControl(),
    });

    this.route.queryParams
      .subscribe(params => {
        this.param_call_id = params.call_id && !params.user_id && this.isPositiveInteger(params.call_id) ? params.call_id : null;
        var baseUrl = window.location.href.split("?")[0];
      window.history.pushState('call_id', '', baseUrl);
      });
  }

  ngOnInit(): void {
    this.mode = localStorage.getItem('mode');
    this.getRequests(["call"]);
    
    this._user.userData.subscribe((obj) => {
      this.user = obj;
      if (this.user) {
        const hasEducation = this.user.Education && this.user.Education.length > 0;
        const hasExpertProfile = this.user.ExpertProfile && this.user.ExpertProfile.length > 0;
        const hasServiceDetails = this.user.services && this.user.services.length > 0;
        const hasUserMetaDetails = this.user.user_meta && (this.user.user_meta.phone || this.user.user_meta.gender || this.user.user_meta.skills || this.user.user_meta.about);
        
        if (hasEducation && hasExpertProfile && hasServiceDetails && hasUserMetaDetails) {
          this.progress = 100;
          this.background = 'green';
        } else if (hasServiceDetails && hasUserMetaDetails && (hasEducation || hasExpertProfile )) {
          this.progress = 85;
          this.background = 'green';
        } else if (hasEducation && hasExpertProfile && hasUserMetaDetails) {
          this.progress = 80;  
          this.background = 'yellow';
        } else if (hasUserMetaDetails && hasServiceDetails) {
          this.progress = 70;
          this.background = 'red';
        } else if (hasServiceDetails && (hasEducation || hasExpertProfile )) {
          this.progress = 65;
          this.background = 'red';         
        } else if (hasUserMetaDetails && (hasEducation || hasExpertProfile )) {
          this.progress = 65;
          this.background = 'red';     
        } else if (hasEducation && hasExpertProfile) {
          this.progress = 60;
          this.background = 'red';   
        } else if (hasUserMetaDetails) {
          this.progress = 50;
          this.background = 'red'; 
        } else if (hasServiceDetails) {
          this.progress = 50;
          this.background = 'red';   
        } else if (hasEducation || hasExpertProfile) {
          this.progress = 45;
          this.background = 'red';   
        } else {
          this.progress = 30;
          this.background = 'red';
        }
      } 
    });
 }

  isPositiveInteger(str) {
    if (typeof str !== 'string')  return false;
    const num = Number(str);
  
    if (Number.isInteger(num) && num > 0)  return true;
    return false;
  }

  timeOptionsToArray(options) {
    let timeOptions = [];
    for (var option in options) {
      timeOptions.push(options[option]);
    }

    return timeOptions.filter((option) => {
      return option;
    });
  }

  ngAfterViewInit() {
    if(this.isExpert){
      if (!this.userMeta ||
        !this.userMeta?.skills ||
        !this.userMeta?.position ||
        !this.userMeta?.about ||
        !this.userMeta?.company ||
        !this.userMeta?.phone
      ) {
        
      }else if (!this.bank ||
        !this.bank?.account_name ||
        !this.bank?.ifsc_code ||
        !this.bank?.account_number
      ) {
        this.modalService.open(this.bankDetailsUpdate, {
          centered: true,
          size: "lg",
          backdrop: "static",
          keyboard: false,
        });
      }

      this.getPaymentsHistory();
    }


    if (
      this.isExpert &&
      this.userMeta &&
      this.userMeta?.skills &&
      this.userMeta?.position &&
      this.userMeta?.about &&
      this.userMeta?.company &&
      this.userMeta?.phone &&
      this.bank &&
      this.bank?.account_name &&
      this.bank?.ifsc_code &&
      this.bank?.account_number &&
      this._user.getUserSession()?.services.length < 3
    ) {
      this._router.navigate(["/dashboard/services"], {
        state: { serviceModal: true },
      });
    }

    if (!this.userMeta?.phone && !this.isExpert) {
      // this.modalService.open(this.accountSetup, { centered: true });
    }

  if(!this.isExpert && this.param_call_id){
    let element:HTMLElement = document.getElementById('v-pills-calls-tab') as HTMLElement;
    element.click();
  }  

  if(!this.isExpert){
    this.openApproveRequestModal();
  }
  
  }

  changePange(page, filterType = "call") {
    if (filterType == "call") {
      this.filterCalls = { ...this.filterCalls, page };
    } else {
      this.filterResumes = { ...this.filterResumes, page };
    }

    this.getRequests([filterType]);
  }

  getRequests(requestFor: Array<string>) {
    if (requestFor.includes("call")) {

      var call_id = null; 
      if(!this.isExpert && this.param_call_id && this.filterCalls.page == 1){
        call_id = this.param_call_id;
        this.filterCalls['call_id'] = this.param_call_id;
      }

      this._api.getSchedulesJobs(this.filterCalls).subscribe((data) => {
        const {
          data: { data: jobRequests, total, per_page, current_page },
        } = data;

        if(call_id && this.filterCalls.page == 1){
          const index = jobRequests.findIndex(object => {
            return object.id == call_id;
          });
          this.scheduledCalls = this.moveArrayItemToNewIndex(jobRequests, index, 0);
        }else{
          this.scheduledCalls = jobRequests;
        } 
        
        this.scheduledCalls = this.scheduledCalls.map((request) => {
          const meta = JSON.parse(request.meta);
          return {
            ...request,
            time_options: meta.time_options
              ? this.timeOptionsToArray(meta.time_options)
              : null,
            meeting_url: meta.meeting_url ? meta.meeting_url : null,
            selected_time: meta.selected_time ? meta.selected_time : null,
            meta: { ...meta },
          };
        });
        
        if(call_id && this.filterCalls.page == 1){
          this.manageCallSchedule(this.content, 0);
        }
        
        this.callPagination = { total, current_page, per_page };
      });
    }
    if (requestFor.includes("resume")) {
      this._api.getSchedulesJobs(this.filterResumes).subscribe((data) => {
        const {
          data: { data: jobRequests, total, per_page, current_page },
        } = data;
        this.requestedResume = jobRequests.map((request) => {
          return { ...request, meta: JSON.parse(request.meta) };
        });

        this.resumePagination = { total, current_page, per_page };
      });
    }
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
  };

  openApproveRequestModal() {
    this.approvalData = JSON.parse(localStorage.getItem("approvalData"));
    if (this.approvalData) {
      this.modalService.open(this.approveRequest, {
        centered: true,
        size: "lg",
      });
    }
  }

  openExpertProfile() {
    if ((this.isNewSignup && this.isExpert )||( !this.userMeta && this.isExpert)) {
      this.modalService.open(this.expertProfile, {
        centered: true,
        size: "lg",
        backdrop: "static",
        keyboard: false,
      });

      return;
    }

    if (this._user.getUserSession()?.services.length < 3) {
      this._router.navigate(["/dashboard/services"], {
        state: { serviceModal: true },
      });
    }
  }

  chnagePassword() {
    var formData = this.changePassword.getRawValue();

    this._api.changePassword(formData).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: data.data,
      });

      this.changePassword.reset();
    });
  }

  timeOptions(options) {
    if (!options) return;
    let html = "";
    options.forEach((element) => {
      html += `<label>${element}<label>`;
    });
    return html;
  }

  manageCallSchedule(content, i) {
    this.selectedAppointment = i;

    // In case of custom service and payment details not updated yet
    if (this.scheduledCalls[i].status == -1) {
      this.modalService.open(this.pendingExpertResponse, {
        centered: true,
        size: "lg",
      });
      return;
    }

    // in case of custom service and payment details updated
    if (
      this.scheduledCalls[i].status == 0 &&
      this.scheduledCalls[i].type == 3
    ) {
      this.modalService.open(this.customServicePayment, {
        centered: true,
        size: "lg",
      });
      return;
    }

    this.modalService.open(content, { centered: true, size: "lg" });
  }

  manageResumeUpdate(resumeReview, i) {
    this.selectedResume = i;

    this.manageResumeRequest = this._form.group({
      updated_resume: this.requestedResume[i]?.updated_resume,
      image_upload: this.requestedResume[i]?.image_upload,
      remarks: this.requestedResume[i]?.remarks,
      status: this.requestedResume[i]?.status,
    });

    this.modalService.open(resumeReview, { centered: true, size: "lg" });
  }

  updateResumeRequest() {
    const formData = this.manageResumeRequest.getRawValue();
    formData.id = this.requestedResume[this.selectedResume].call_id;

    this._api.updateResumeRequest(formData).subscribe((data) => {
      this._notifier.showNotification({ message: data.data, type: "success" });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  updateJobRequest(
    requestType = "call",
    isArchiveRequest = false,
    callId = null
  ) {
    let formData: any = {};
    if (requestType == "call" && !isArchiveRequest) {
      formData = this.manageAppointment.getRawValue();
      formData.id = this.scheduledCalls[this.selectedAppointment].call_id;
    }
    if (requestType == "resume" && !isArchiveRequest) {
      formData = this.manageResumeRequest.getRawValue();
      formData.id = this.requestedResume[this.selectedResume].call_id;
    }

    if (isArchiveRequest && callId) {
      formData.id = callId;
      formData.isArchive = isArchiveRequest;
    }

    if (this.approvalData) {
      formData = {};
      formData = this.manageJobApprovalRequest.getRawValue();
      formData.id = this.approvalData.callId;
      formData.approve = true;
      localStorage.removeItem("approvalData");
    }

    this._api.updateSchedulesJobs(formData).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  getJobRequestStatus(call :any) {
    if(!call || !call.status || !call.type) return; 
    var type = call.type;
    var status = call.status;
    if(type == "resume"){
      switch (status.toString()) {
        case "0":
        case "1":
          return "Resume Request is in Process.";
        case "2":
          return "Resume Request is Completed.";
        case "3":
          return "Resume Request is Approved.";
      }
    }else{
      switch (status.toString()) {
        case "0":
          return "Request is in Process.";
        case "1":
          return type == 'call' ? 'Meeting is Scheduled.' : "Training is Scheduled.";
        case "2":
          return "Job in progress.";
        case "3":
          return "Job Completed.";
        case "4":
          return "Job is Approved";
      }
    }
  }

  getMeetingStatus(status: any, type = null) {
    if(type == "resume"){
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "On Going";
        case "2":
          return "Completed";
        case "3":
          return "Approved";
        default :
          return "Completed";
      }
    }else{
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "Scheduled";
        case "2":
          return "On Going";
        case "3":
          return "Completed";
        case "4":
          return "Approved";
        case "-1":
          return "Requested";
        default:
          return "Completed";
      }
    }
  }

  updateBankDetails() {
    var bankDetalis = this.bankDetails.getRawValue();

    this._api.updateExpertBankAccount(bankDetalis).subscribe((data) => {
      
      if(data?.status == 200){
        localStorage.setItem("user_details", JSON.stringify({ ...this.user, 'bank_details' : data.data }));
        
        const formData = {
          service_name: "Message Me",
          service_type: "message",
          description: "Ask me any anything and I'll revert with a detailed answer",
          charges: "",
          status: "Active",
        };

        this._api.createService(formData).subscribe((data) => {
          if(this.modalService.hasOpenModals() && data?.status == 200){
            this.modalService.dismissAll(); 
            this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this._router.navigate(['dashboard']);
            });        
          }
        });

        // this.appService.setUserData(data.data);
      }

      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });
    });
    
  }

  isPaymentSuccess() {
    return (
      this.scheduledCalls[this.selectedAppointment].order_status == "success"
    );
  }

  isResumePaymentSuccess() {
    return this.requestedResume[this.selectedResume].order_status == "success";
  }

  uploadFile(event: any, type: any) {
    this.uploadedFiles = <File>event.target.files;

    this._api.uploadFiles(this.uploadedFiles).subscribe((data) => {
      if (type == "image") {
        this.manageResumeRequest.patchValue({
          image_upload: data.data[0],
        });
      } else {
        this.manageResumeRequest.patchValue({
          updated_resume: data.data[0],
        });
      }
    });
  }

  isCustomService(type) {
    if (type == "call") return false;
    if (type == "resume") return false;
    if (type == "training") return false;

    return true;
  }

  getResumeUrl(call) {
    if(!call || !call.meta) return null;
    if(call.meta.cv_upload && call.meta.updated_resume){
      return call.meta.updated_resume+'?download';
    }
    if(call.meta.cv_upload){
      return call.meta.cv_upload+'?download';
    }
    return  null;
  }

 
  getPaymentsHistory() {
    this._api.getPaymentsHistory({onlyTotal: true}).subscribe((data) => {
   
      const { data: { approvedRequestPayment}} = data;
      this.approvedJobPayment = approvedRequestPayment;
    });
  }
}
