<div class="page-title-area two three candidate-details" *ngIf="profileUrl === 0">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row ">
          <div class="col-sm-12 candinate_message">
            Please complete your basic profile details
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-title-area two three candidate-details" *ngIf="profileUrl === 1">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row ">
          <div class="col-sm-2 bannerHeder">
            <img [src]="expert?.profile_pic ? expert?.profile_pic : 'assets/img/avatar.jpg'" alt="Details" />
          </div>
          <div class="col-sm-10 bannerContent">
            <div>
              <h2>{{ expert?.name }} {{ expert?.lname }}</h2>
            </div>
            <div *ngIf="lastCurrentlyExperience?.till_now">{{
              lastCurrentlyExperience?.company }},{{lastCurrentlyExperience?.position}}</div>
            <div *ngIf="lastCurrentlyExperience?.till_now">{{ lastCurrentlyExperience?.start_year }} - {{'Present'}}</div>
           
            <div *ngIf="lastCurrentlyExperience?.till_now">{{ lastCurrentlyExperience?.location }}</div>
            <div *ngIf="lastCurrentlyEducation?.currently_studying === 1">{{ lastCurrentlyEducation?.field_of_study
              }},{{lastCurrentlyEducation?.institution}}</div>
            <!-- current -->

            <div *ngIf="!lastCurrentlyExperience?.till_now">{{ lastExperience?.company
              }}<span ngIf="lastExperience?.company">,</span>{{lastExperience?.position}}</div>
            <div *ngIf="!lastCurrentlyExperience?.till_now">{{ lastExperience?.start_year }} <span *ngIf="lastExperience?.start_year">-</span>
              {{lastExperience?.end_year}}</div>
            <div *ngIf="!lastCurrentlyExperience?.till_now">{{ lastExperience?.location }}</div>
            <div *ngIf="lastCurrentlyEducation?.currently_studying === 0">{{ lastCurrentlyEducation?.field_of_study
              }},{{lastCurrentlyEducation?.institution}}</div>

            <!-- current -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->
<!-- my custome -->
<div class="container ExpertProfileMain" *ngIf="profileUrl === 1">
  <div class="row">
    <div class="col-sm-9">
      <div class="expertCol" *ngIf="expert?.position || expert?.linkedin_url || expert?.total_exp">
        <div class="ProfileContent">
          <div class="col-sm-12">
            <div class="left">
              <div *ngIf="expert?.position"><i class="bx bx-box"></i>{{expert?.position }}</div>
              <div *ngIf="expert?.linkedin_url"><i class="fab fa-linkedin"></i><a href="{{ expert?.linkedin_url }}"
                  target="_blank">{{expert?.linkedin_url}}</a></div>
              <div *ngIf="expert?.total_exp"><i class="fas fa-briefcase"></i>{{expert?.total_exp }} Exp.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about">
        <div class="aboutHeader">About</div>
        <div>
          <div *ngIf="!showFullText">
            <span [innerHTML]="expert?.about ? expert.about.substr(0, 60) + '' : ''"></span>
            <span *ngIf="expert?.about?.length > 60" (click)="toggleText()" style="color: blue; cursor: pointer;">Read
              more</span>
          </div>
          <div *ngIf="showFullText">
            <span [innerHTML]="expert?.about"></span>
            <span *ngIf="expert?.about?.length > 60" (click)="toggleText()" style="color: blue; cursor: pointer;">Read
              less</span>
          </div>
        </div>

        <div class="skill">
          <div>Top Skill {{showFullText}}</div>
          <div>
            {{ skillsToText(expert?.skills) }}
          </div>
        </div>
      </div>
      <div class="experince">
        <div class="row">
          <div class="col-sm-12 experienceHeader">Experience</div>
        </div>
        <div class="row experienceContent" *ngFor="let TwoExperiences of lastTwoExperiences;">
          <div class="col-sm-1"><img src="assets/img/icons/experince.png" alt=""></div>
          <div class="col-sm-11 erf">
            <div>{{TwoExperiences.position}}</div>
            <div>{{TwoExperiences.company}}.{{TwoExperiences.role}}</div>
            <div>{{TwoExperiences.start_year}}-{{TwoExperiences.end_year}}</div>
            <div>{{TwoExperiences.location}}</div>
          </div>
        </div>
        <div class="row" *ngIf="experince.length > 2" (click)="openExperinceModal(experince)">
          <div class="col-sm-12 showAll">
            Show All Experiences
          </div>
        </div>
      </div>

      <div class="experince">
        <div class="row">
          <div class="col-sm-12 experienceHeader">Education</div>
        </div>
        <div class="row experienceContent" *ngFor="let TwoEducation of lastTwoEducation;">
          <div class="col-sm-1"><img src="assets/img/icons/education.png" alt=""></div>
          <div class="col-sm-11 erf">
            <div>{{TwoEducation.institution}}</div>
            <div>{{TwoEducation.degree}},{{TwoEducation.field_of_study}}</div>
            <div>{{TwoEducation.start_date}}-{{TwoEducation.end_date}}</div>
            <div>{{TwoEducation.location}}</div>
          </div>
        </div>
        <div class="row" *ngIf="education.length > 2" (click)="openEducationModal(education)">
          <div class="col-sm-12 showAll">
            Show All Education
          </div>
        </div>

      </div>
      <div class="row service">
        <div class="col-sm-12">
          <div class="widget-area">
            <app-expert-contact [expert]="expert"></app-expert-contact>
          </div>
        </div>
      </div>

      <div class="row service">
        <div class="col-sm-12">
          <div class="details-item">

            <div class="client" *ngFor="let review of expert?.reviews">
              <img [src]="review.profile_pic || 'assets/img/skillsurger.png'" alt="Candidate Details" />
              <h3>{{ review.name }}</h3>
              <span>{{ review.created_at | date: short }}</span>
              <p>
                {{ review.review }}
              </p>
              <ul>
                <li>
                  <span>{{ review.stars }}</span>
                </li>
                <li *ngFor="let start of rateToArray(review.stars)">
                  <i class="bx bxs-star checked"></i>
                </li>
              </ul>
            </div>

            <div class="review">
              <h3>Add Review</h3>
              <span>Your rating for this listing</span>
              <ngb-rating max="5" [(rate)]="currentRate" class="ratings"></ngb-rating>
              ({{ currentRate }})
              <form [formGroup]="reviewForm" (submit)="submitReview()">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                      <i class="bx bx-user"></i>
                      <input type="text" class="form-control" placeholder="Name*" formControlName="name" />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                      <i class="bx bx-mail-send"></i>
                      <input type="email" class="form-control" placeholder="Email*" formControlName="email" />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <i class="bx bx-mail-send"></i>
                      <textarea id="your-message" rows="10" class="form-control" placeholder="Write message"
                        formControlName="review"></textarea>
                    </div>
                  </div>

                  <div class="col-lg-12 col-sm-12 col-md-12">
                    <button type="submit" class="btn">
                      <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt"></i></span>
                      <span *ngIf="!loaderService.isLoading">Submit A Review</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 rightSide">
      <div class="row candidate-item two" *ngFor="let expertRandom of expertRandomList; let i = index">
        <div class="col-sm-12">
          <img [src]="expertRandom.meta.profile_pic ? expertRandom.meta.profile_pic : 'assets/img/avatar.jpg'"  alt="Candidate" />
         <div class="text-center">
            <h2>{{ expertRandom?.name }} {{ expertRandom?.lname }}</h2> 
         </div>
         
         <div class="text-center">
            <span>{{ getCompany(expertRandom.experience) }},{{getPosition(expertRandom.experience)}}</span>
        </div>
        <div class="text-center">
          <span>{{ getStartYear(expertRandom.experience) }} - </span>
          <span>{{ getEndYear(expertRandom.experience) }}</span>
        </div>
        <div class="text-center" *ngIf="getSkills(expertRandom.meta?.skills)">
          <span>Exp : </span>
          <span>{{expertRandom.meta.total_exp}}</span>
        </div>
          <div>
             <a (click)="navigateToProfile(expertRandom)" class="btn btn-dark w-100 text-white weight-bold my-1">
                View Profile and Interact
            </a>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
 </div>   
    <!-- my custome -->

    <ng-template #educationModal let-modal>
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="experince">
          <div class="row">
            <div class="col-sm-12 experienceHeader">Education</div>
          </div>
          <div class="row experienceContent" *ngFor="let education of educationList;">
            <div class="col-sm-1"><img src="assets/img/icons/education.png" alt=""></div>
            <div class="col-sm-11 erf">
              <div>{{education.institution}}</div>
              <div>{{education.degree}},{{education.field_of_study}}</div>
              <div>{{education.start_date}}-{{education.end_date}}</div>
              <div>{{education.location}}</div>
            </div>
          </div>
        </div>
       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close()">
          Close
        </button>
      </div>
    </ng-template>

    <ng-template #experinceModal let-modal>
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="experince">
          <div class="row">
            <div class="col-sm-12 experienceHeader">Experience</div>
          </div>
          <div class="row experienceContent" *ngFor="let experince of experinceList;">
            <div class="col-sm-1"><img src="assets/img/icons/experince.png" alt=""></div>
            <div class="col-sm-11 erf">
              <div>{{experince.position}}</div>
              <div>{{experince.company}}.{{experince.role}}</div>
              <div>{{experince.start_year}}-{{experince.end_year}}</div>
              <div>{{experince.location}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close()">
          Close
        </button>
      </div>
    </ng-template>