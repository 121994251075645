<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User" />
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>{{ !isPasswordReset ? 'Login' : 'Reset Password' }}</h2>

                        <form [formGroup]="loginForm" (submit)="login()">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email" formControlName="email" />
                            </div>

                            <div class="form-group" *ngIf="!isPasswordReset">
                                <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                            </div>

                            <button type="submit" class="btn" [disabled]="loaderService.isLoading">
                                <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                                <span *ngIf="!loaderService.isLoading">
                                    {{ !isPasswordReset ? 'Login Here' : 'Reset Password' }}
                                </span>                                
                            </button>
                            <div class="d-flex justify-content-end align-items-end flex-column">
                                <a class="create-account" routerLink="/register">Create new Account</a>
                                
                                <!-- <a class="create-account" *ngIf="!isLoggedIn" [routerLink]="['/register', 'selection']">Create new Account</a> -->
                                <a class="create-account" (click)="resetPassword()" style="cursor: pointer;">
                                    {{ !isPasswordReset ? 'Forgot Password' : 'Already Have an Account' }}
                                </a>
                            </div>
                        </form>

                    </div>
                    <app-social-login></app-social-login>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->