import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { CandidatesDetailsComponent } from "./components/pages/candidates-details/candidates-details.component";
import { CandidatesComponent } from "./components/pages/candidates/candidates.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { CoursesComponent } from "./components/pages/courses/courses.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { EmployersDetailsComponent } from "./components/pages/employers-details/employers-details.component";
import { EmployersComponent } from "./components/pages/employers/employers.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { FavouriteJobsComponent } from "./components/pages/favourite-jobs/favourite-jobs.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { InboxComponent } from "./components/pages/inbox/inbox.component";
import { JobDetailsComponent } from "./components/pages/job-details/job-details.component";
import { JobsComponent } from "./components/pages/jobs/jobs.component";
import { LoginSelectionComponent } from "./components/pages/login-selection/login-selection.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { LogoutComponent } from "./components/pages/logout/logout.component";
import { PostAJobComponent } from "./components/pages/post-a-job/post-a-job.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { ResumeDetailsComponent } from "./components/pages/resume-details/resume-details.component";
import { ScheduleCallComponent } from "./components/pages/schedule-call/schedule-call.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { JobGuaranteeComponent } from "./components/pages/job-guarantee/job-guarantee.component";
import { ReviewResumeComponent } from "./components/pages/review-resume/review-resume.component";

import { AuthGuardService } from "./services/AuthGaurd/auth-guard.service";
import { LinkedinComponent } from "./components/pages/linkedin/linkedin.component";
import { EventsComponent } from "./components/pages/events/events.component";
import { EventDetialComponent } from "./components/pages/event-detial/event-detial.component";
import { ForBusinessesComponent } from "./components/pages/for-businesses/for-businesses.component";
import { ScheduledCallsComponent } from "./components/pages/experts/scheduled-calls/scheduled-calls.component";
import { ResumeRequestsComponent } from "./components/pages/experts/resume-requests/resume-requests.component";
import { JobRequestsComponent } from "./components/pages/experts/job-requests/job-requests.component";
import { ServicesComponent } from "./components/pages/experts/services/services.component";
import { CustomServicesComponent } from "./components/pages/custom-services/custom-services.component";
import { PaymentHistoryComponent } from "./components/pages/experts/payment-history/payment-history.component";
import { CONFIGS } from "./services/AppConstants";

const routes: Routes = [
  { path: "", component: HomeOneComponent },
  { path: "home-two", component: HomeTwoComponent },
  { path: "home-three", component: HomeThreeComponent },
  { path: "login/selection", component: LoginSelectionComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "register", component: RegisterComponent },
  {
    path: "expert-registration",
    component: RegisterComponent,
    data: { user_type: 1, form_title: "Expert Registration" },
  },
  { path: "employers", component: EmployersComponent },
  { path: "employer-details", component: EmployersDetailsComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard/job-requests",
    component: JobRequestsComponent,
    canActivate: [AuthGuardService],
    data: {
      // role: CONFIGS.user_type.expert
      modeSwitchExpert: CONFIGS.mode === "switchExpert"
    }
  },
  {
    path: "dashboard/services",
    component: ServicesComponent,
    canActivate: [AuthGuardService],
    data: {
        modeSwitchExpert: CONFIGS.mode === "switchExpert"
    }
  },
  {
    path: "dashboard/payments-history",
    component: PaymentHistoryComponent,
    canActivate: [AuthGuardService],
    data: {
      // role: CONFIGS.user_type.expert
      modeSwitchExpert: CONFIGS.mode === "switchExpert"
    }
  },
  {
    path: "inbox",
    component: InboxComponent,
    canActivate: [AuthGuardService],
  },
  { path: "single-resume", component: ResumeDetailsComponent },
  { path: "testimonials", component: TestimonialsComponent },
  { path: "pricing", component: PricingComponent },
  { path: "faq", component: FaqComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  { path: "error", component: ErrorComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-conditions", component: TermsConditionsComponent },
  { path: "about", component: AboutComponent },
  { path: "jobs", component: JobsComponent },
  { path: "courses", component: CoursesComponent },
  { path: "favourite-jobs", component: FavouriteJobsComponent },
  { path: "job-details", component: JobDetailsComponent },
  { path: "post-a-job", component: PostAJobComponent },
  { path: "experts", component: CandidatesComponent },
  { path: "candidate-details", component: CandidatesDetailsComponent },
  { path: "experts/:slug", component: CandidatesDetailsComponent },
  {
    path: "experts/:slug/schedule-call",
    component: ScheduleCallComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "experts/:slug/review-resume",
    component: ReviewResumeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "experts/:slug/get-trained",
    component: ScheduleCallComponent,
    canActivate: [AuthGuardService],
    data: {
      form_title: "Get Trained",
      isGetTrained: 1,
    },
  },
  {
    path: "experts/:slug/:service",
    component: CustomServicesComponent,
    canActivate: [AuthGuardService],
  },
  { path: "blog", component: BlogComponent },
  { path: "blog/:slug", component: BlogDetailsComponent },
  { path: "blog-details", component: BlogDetailsComponent },
  { path: "events", component: EventsComponent },
  { path: "event/:slug", component: EventDetialComponent },
  { path: "for-businesses", component: ForBusinessesComponent },
  { path: "contact", component: ContactComponent },
  { path: "linkedin", component: LinkedinComponent },
  { path: "career-surger-program", component: JobGuaranteeComponent },
  { path: "**", component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
