<!-- <div class="alert alert-danger inconpleteform" role="alert" *ngIf="user.user_type == 1 && (userMeta === null || Education.length === 0 || Experince.length === 0 )"> -->
  <div class="alert alert-danger inconpleteform" role="alert" *ngIf="user.user_type == 1 || mode === 'switchExpert'  && (userMeta === null || Education.length === 0 || Experince.length === 0 )">

   
  <h3>Please complete your profile</h3>
    <ul>
      <!-- <li *ngIf="userMeta === null ">Basic Information Is Incomplete</li>
      <li *ngIf="Experince.length === 0">Experience Information Is Incomplete</li>
      <li *ngIf="Education.length === 0">Education Information Is Incomplete</li> -->
      <li *ngIf="isBasicInfoIncomplete()">Basic Information Is Incomplete</li>
      <li *ngIf="isExperienceIncomplete()">Experience Information Is Incomplete</li>
      <li *ngIf="isEducationIncomplete()">Education Information Is Incomplete</li>
    </ul>
  </div>
