<!-- Footer -->
<footer #footer id="footer" class="pt-100 pb-70 dark_green_bg ">
  <div class="container footer-container">
    <div class="row">
      <div class="col-sm-6 col-lg-2">
        <div class="footer-item">
          <div class="footer-logo">
            <a class="logo" routerLink="/index.html"
              ><img src="assets/img/logo.png" alt="Logo"
            /></a>

          </div>
        </div>
      </div>


      <div class="col-sm-6 col-lg-10">
        <div class="footer-item">
          <div class="footer-service footer-top">
            <ul>
              <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
              <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
              <li><a routerLink="/jobs">Jobs</a></li>
              <li><a routerLink="/experts">Experts</a></li>
              <li><a routerLink="/blog">Blog</a></li>
              <li><a routerLink="/contact">Contact</a></li>
            </ul>
          </div>
          <div class="footer-service footerIcon">
            <ul>
              <li>
                <a
                  class="text-white"
                  href="https://www.facebook.com/skillsurger"
                  target="_blank"
                  ><i class="bx bxl-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  class="text-white"
                  href="https://twitter.com/SurgerSkill"
                  target="_blank"
                  ><i class="bx bxl-twitter"></i
                ></a>
              </li>
              <li>
                <a
                  class="text-white"
                  href="https://www.linkedin.com/company/skillsurger"
                  target="_blank"
                  ><i class="bx bxl-linkedin-square"></i
                ></a>
              </li>
              <li>
                <a
                  class="text-white"
                  href="https://www.instagram.com/skillsurger/"
                  target="_blank"
                  ><i class="bx bxl-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area">
  <div class="container footer-container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="copyright-item">
          <p>
            Copyright @2021 Design & Developed by
            <a href="https://thecodersadda.com/" target="_blank"
              >The Coders Adda</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="telegram-btn">
  <a href="https://t.me/+feq5j-cezLBjMWY1" target="_blank">
    <i class="bx bxl-telegram"></i><span>Join our Telegram.</span>
  </a>
</div>
<!-- End Copyright -->
