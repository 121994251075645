<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Dashboard</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
  <div class="banner-content dashboard-search">
    <h2>Search</h2>
    <div class="banner-form-area">
      <app-search-form></app-search-form>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="profile-item">
         <div class="dashbordImg">
              <img *ngIf="user.user_type === 1 || mode === 'switchExpert'"
              [src]="
                userMeta?.profile_pic
                  ? userMeta?.profile_pic
                  : 'assets/img/avatar.jpg'
              "
              alt="Dashboard"

              style="--progress: {{ progress }}%; background: conic-gradient({{background}} var(--progress, 25%), #e6e6e6 0%);"
            />

            <img *ngIf="user.user_type === 0 && mode !== 'switchExpert'"
              [src]="
                userMeta?.profile_pic
                  ? userMeta?.profile_pic
                  : 'assets/img/avatar.jpg'
              "
              alt="Dashboard"
            />
            <div class="ImgProgress">
              <div class="percentText">
                {{ progress }}%
              </div>
              <div class="percentComplete">
                Profile Completed
              </div>
            </div>
         </div>
         <h3>
          <a [routerLink]="['/experts', user.vanity_url || user.id]">{{ user.name }} {{user.lname}}</a>
        </h3>
         
          <span *ngIf="isExpert">{{ user.position }}</span>
          <span *ngIf="isExpert && approvedJobPayment >= 0">
            Approved Jobs Pending Payment : {{ approvedJobPayment }}</span>
            
        </div>
        
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link active"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            href="#v-pills-home"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
            ><i class="bx bx-user"></i> My Profile</a
          >

          <a
            class="nav-link"
            id="v-pills-experience-tab"
            data-bs-toggle="pill"
            href="#v-pills-experience"
            role="tab"
            aria-controls="v-pills-experience"
            aria-selected="true"
            *ngIf="user.user_type === 1 || mode === 'switchExpert' "
            ><i class="bx bx-layout"></i> Experience Detail</a
          >

          <a
          class="nav-link"
          id="v-pills-education-tab"
          data-bs-toggle="pill"
          href="#v-pills-education"
          role="tab"
          aria-controls="v-pills-education"
          aria-selected="true"
          *ngIf="user.user_type === 1 || mode === 'switchExpert'"
          ><i class="bx bx-book"></i> Education Detail</a
        >

          <a
            class="nav-link"
            id="v-pills-account-tab"
            data-bs-toggle="pill"
            href="#v-pills-account"
            role="tab"
            aria-controls="v-pills-account"
            aria-selected="false"
            *ngIf="isExpert || mode === 'switchExpert'"
          >
            <div class="profile-list">
              <i class='bx bxs-bank'></i> Bank Details
            </div>
          </a>

          <a
            class="nav-link"
            id="v-pills-account-tab"
            data-bs-toggle="pill"
            routerLink="/dashboard/services"
            role="tab"
            aria-controls="v-pills-account"
            aria-selected="false"
            *ngIf="isExpert || mode === 'switchExpert'"
          >
            <div class="profile-list">
              <i class='bx bxs-cog'></i> Service
            </div>
          </a>

          <a
            class="nav-link"
            id="v-pills-calls-tab"
            data-bs-toggle="pill"
            href="#v-pills-calls"
            role="tab"
            aria-controls="v-pills-calls"
            aria-selected="false"
            *ngIf="!isExpert && mode !== 'switchExpert'"
          >
            <div class="profile-list">
              <i class="bx bxs-inbox"></i> Work Requests
            </div>
          </a>

          <a
            class="nav-link"
            id="v-pills-messages-tab"
            data-bs-toggle="pill"
            href="#v-pills-messages"
            role="tab"
            aria-controls="v-pills-messages"
            aria-selected="false"
          >
            <div class="profile-list">
              <i class='bx bxs-key' ></i> Change Password
            </div>
          </a>

          <a routerLink="/inbox">
            <div class="profile-list"><i class="bx bxs-inbox"></i> Inbox</div>
          </a>

          <a routerLink="/logout">
            <div class="profile-list"><i class="bx bx-log-out"></i> Logout</div>
          </a>
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <!-- Basic Profile -->
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <div class="profile-content">
              <app-expert-profile-update></app-expert-profile-update>
            </div>
          </div>

          <div class="tab-pane fade" id="v-pills-experience" role="tabpanel" aria-labelledby="v-pills-experience-tab">
            <div class="profile-content">
             <app-expert-profile-add-more ></app-expert-profile-add-more>
            </div>
          </div>

          <div class="tab-pane fade" id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab">
            <div class="profile-content">
              <app-education></app-education>
            </div>
          </div>

          <!-- Bank Account -->
          <div
            class="tab-pane fade"
            id="v-pills-account"
            role="tabpanel"
            aria-labelledby="v-pills-account-tab"
          >
          <app-incomplete-alert></app-incomplete-alert>
            <div class="profile-content">
              <form [formGroup]="bankDetails" (submit)="updateBankDetails()">
                <div class="profile-content-inner">
                  <h2 class="heading-underline">Bank Account Details</h2>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="name">Account Name:</label>
                        <input
                          type="text"
                          formControlName="account_name"
                          class="form-control"
                          id="name"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="accountNumber">Account Number:</label>
                        <input
                          type="text"
                          formControlName="account_number"
                          class="form-control"
                          id="accountNumber"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="ifscCode">IFSC Code:</label>
                        <input
                          type="text"
                          formControlName="ifsc_code"
                          class="form-control"
                          id="ifscCode"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn dashboard-btn"
                  [disabled]="
                    !bankDetails.value.account_name ||
                    !bankDetails.value.ifsc_code ||
                    !bankDetails.value.account_number ||
                    loaderService.isLoading
                  "
                >
                  <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                  <span *ngIf="!loaderService.isLoading">Save Bank Details</span>                   
                </button>
              </form>
            </div>
          </div>

          <!-- Calls -->
          <div
            class="tab-pane fade"
            id="v-pills-calls"
            role="tabpanel"
            aria-labelledby="v-pills-calls-tab"
          >
            <div class="profile-content">
              <table class="table table-bordered table-striped">
                <thead>
                  <th>Sr. No.</th>
                  <th>Expert Name</th>
                  <th>Expert Email</th>
                  <th>Expert Phone</th>
                  <th>Status</th>
                  <th>Archive</th>
                  <th></th>
                </thead>
                <tbody>
                  <tr *ngFor="let call of scheduledCalls; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ call.user_name }}</td>
                    <td>{{ call.user_email }}</td>
                    <td>{{ call.phone }}</td>
                    <td>{{ getMeetingStatus(call.status, call.type) }}</td>
                    <th>
                      <button
                        (click)="updateJobRequest('call', true, call.id)"
                        class="btn bg-transparent text-danger"
                      >
                        <i class="bx bxs-trash"></i>
                      </button>
                    </th>
                    <td>
                      <button
                        (click)="manageCallSchedule(content, i)">
                        {{ isExpert ? "Manage" : "View" }}
                        Detail
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-area mt-2">
                <ngb-pagination
                  [collectionSize]="callPagination.total"
                  [(page)]="callPagination.current_page"
                  [maxSize]="3"
                  [pageSize]="callPagination.per_page"
                  [boundaryLinks]="true"
                  (pageChange)="changePange($event)"
                ></ngb-pagination>
              </div>
            </div>
          </div>

          <!-- Resume Request -->
          <div
            class="tab-pane fade"
            id="v-pills-resume"
            role="tabpanel"
            aria-labelledby="v-pills-resume-tab"
          >
            <div class="profile-content">
              <table class="table table-bordered table-striped">
                <thead>
                  <th>Sr. No.</th>
                  <th>Candidate Name</th>
                  <th>Candidate Email</th>
                  <th>Candidate Phone</th>
                  <th>Payment Status</th>
                  <th *ngIf="!isExpert">CV Status</th>
                  <th *ngIf="isExpert">CV Download</th>
                  <th *ngIf="isExpert">Update CV</th>
                  <th *ngIf="!isExpert">Archive</th>
                </thead>
                <tbody>
                  <tr *ngFor="let resume of requestedResume; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ resume.name == '' || resume.name == null ? resume.user_name : resume.name  }}</td>
                    <td>{{ resume.email }}</td>
                    <td>{{ resume.phone_no }}</td>
                    <td>
                      {{
                        resume.order_status ? resume.order_status : "pending"
                      }}
                    </td>
                    <td>{{ resume.updated_resume ? "Updated" : "Pending" }}</td>
                    <td *ngIf="isExpert">
                      <a
                        href="{{ resume.cv_upload }}?download"
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <button>Download</button>
                      </a>
                    </td>
                    <td *ngIf="isExpert">
                      <button (click)="manageResumeUpdate(resumeReview, i)">
                        Update
                      </button>
                    </td>
                    <th *ngIf="!isExpert">
                      <button
                        (click)="updateJobRequest('resume', true, resume.id)"
                      >
                        Archive
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-area mt-2">
                <ngb-pagination
                  [collectionSize]="resumePagination.total"
                  [(page)]="resumePagination.current_page"
                  [maxSize]="3"
                  [pageSize]="resumePagination.per_page"
                  [boundaryLinks]="true"
                  (pageChange)="changePange($event, 'resume')"
                ></ngb-pagination>
              </div>
            </div>
          </div>

          <!-- Messages -->
          <div
            class="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
          <app-incomplete-alert></app-incomplete-alert>
            <div class="profile-content">
              <form [formGroup]="changePassword" (submit)="chnagePassword()">
                <div class="profile-content-inner">
                  <h2 class="heading-underline">Change Password</h2>

                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <label class="password">Current Password:</label>
                        <input
                          type="password"
                          id="password"
                          required
                          formControlName="old_password"
                          class="form-control"
                          placeholder="Current Password"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <label class="newPassword">New Password:</label>
                        <input
                          type="password"
                          id="newPassword"
                          required
                          formControlName="password"
                          class="form-control"
                          placeholder="New Password"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <label class="confirmPassword"
                          >Confirm New Password:</label
                        >
                        <input
                          type="password"
                          id="confirmPassword"
                          required
                          formControlName="password_confirmation"
                          class="form-control"
                          placeholder="Confirm New Password"
                        />
                        <p
                          class="invalid-feedback d-block"
                          *ngIf="
                            changePassword.value.password !=
                            changePassword.value.password_confirmation
                          "
                        >
                          Must be same as New Password
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn dashboard-btn"
                  [disabled]="
                    !changePassword.value.password ||
                    !changePassword.value.password_confirmation ||
                    changePassword.value.password !=
                      changePassword.value.password_confirmation ||
                    loaderService.isLoading
                  "
                >
                  <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                  <span *ngIf="!loaderService.isLoading">Save Your Information</span>                   
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pendingExpertResponse let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Pending Expert Response</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Requirements</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.requirments }}</p>
    </div>
    <div class="form-group" *ngIf="scheduledCalls[selectedAppointment].meta.estimate_hours">
      <label><b>Estimate Hours</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.estimate_hours }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <p>Awaiting Expert's Response on this.</p>

    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #customServicePayment let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Pay Now</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Requirements</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.requirments }}</p>
    </div>
    <div class="form-group" *ngIf="scheduledCalls[selectedAppointment].meta.estimate_hours">
      <label><b>Estimate Hours</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.estimate_hours }}</p>
    </div>
    <div class="form-group">
      <label><b>Expert Working Hours</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.ideaHours }}</p>
    </div>
    <div class="form-group">
      <label><b>Expected Budget</b></label>
      <p>{{ scheduledCalls[selectedAppointment].meta.budget }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <p *ngIf="scheduledCalls[selectedAppointment].order_status == 'success'">
      Payment Successful. Awating Expert Response
    </p>
    <app-pay-now
      *ngIf="!isPaymentSuccess()"
      [jobData]="scheduledCalls[selectedAppointment]"
      [closeModal]="modal.close('Close click')"
    ></app-pay-now>

    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
     {{ scheduledCalls[selectedAppointment].type == 'call' ? 'Schedule Appointment' : '' }}
     {{ scheduledCalls[selectedAppointment].type == 'resume' ? 'Resume Request Detail' : '' }}
     {{ scheduledCalls[selectedAppointment].type == 'training' ? 'Training Request Detail' : '' }}
     {{ isCustomService(scheduledCalls[selectedAppointment].type) ? 'Request Detail' : '' }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
    *ngIf="isCustomService(scheduledCalls[selectedAppointment].type)"
    [formGroup]="manageAppointment"
  >
    <div class="row">
      <div class="col-sm-6">
        <label for="">Name</label>
        <p>{{ scheduledCalls[selectedAppointment]?.user_name   }}</p>
      </div>

      <div class="col-sm-6">
        <label for="">Email</label>
        <p>{{ scheduledCalls[selectedAppointment].user_email }}</p>
      </div>

      <div class="col-sm-6" *ngIf="!isExpert">
        <div class="form-group">
          <label>Budget</label>
          <p>
            {{ scheduledCalls[selectedAppointment].meta?.budget ||'NA'  }}
          </p>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="scheduledCalls[selectedAppointment].meta?.estimate_hours">
        <div class="form-group">
          <label for="">Estimated Hours</label>
          <p> {{ scheduledCalls[selectedAppointment].meta?.estimate_hours }}
          </p>
          
        </div>
      </div>
      <div class="col-sm-6" *ngIf="scheduledCalls[selectedAppointment].meta?.ideaHours">
        <div class="form-group">
          <label for="">Given Hours</label>
          <p> {{ scheduledCalls[selectedAppointment].meta?.ideaHours }}
          </p>
          
        </div>
      </div>

      <div class="col-sm-6" style="word-break: break-all;">
        <div class="form-group">
          <label for="">Remarks</label>
          <p>
            {{ scheduledCalls[selectedAppointment].meta?.remarks ? scheduledCalls[selectedAppointment].meta?.remarks : 'NA' }} 
          </p>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <label for="">Request Status</label>
          <p *ngIf="!isExpert">
            {{ getMeetingStatus(scheduledCalls[selectedAppointment].status) }}
          </p>
        </div>
      </div>
    </div>
  </form>


    <form
      *ngIf="!isCustomService(scheduledCalls[selectedAppointment].type) && (scheduledCalls[selectedAppointment].type == 'call' || scheduledCalls[selectedAppointment].type == 'training')"
      [formGroup]="manageAppointment"
    >
      <div class="row">
        <div class="col-sm-6">
          <label for="">Name</label>
          <p>{{ scheduledCalls[selectedAppointment]?.user_name   }}</p>
        </div>

        <div class="col-sm-6">
          <label for="">Email</label>
          <p>{{ scheduledCalls[selectedAppointment].user_email }}</p>
        </div>

        <div class="col-sm-6" *ngIf="!isExpert">
          <div class="form-group">
            <label>Meeting Time</label>
            <p>
              {{
                scheduledCalls[selectedAppointment].selected_time
                  | date: "medium"
              }}
            </p>
          </div>
        </div>

        <div class="col-sm-6" *ngIf="isExpert">
          <div class="form-group">
            <label>Select Time</label>
            <div
              class="form-check"
              *ngFor="
                let time of scheduledCalls[selectedAppointment].time_options
              "
            >
              <input
                type="radio"
                formControlName="selected_time"
                [value]="time"
              />&nbsp; <label>{{ time | date: "medium" }}</label>
            </div>
          </div>
        </div>

        <div class="col-sm-6" style="word-break: break-all;">
          <div class="form-group">
            <label for="">Meeting URL</label>
            <p *ngIf="!isExpert">
              <a
               *ngIf="scheduledCalls[selectedAppointment].meeting_url"
                target="_blank"
                [href]="scheduledCalls[selectedAppointment].meeting_url"
                >{{ scheduledCalls[selectedAppointment].meeting_url }}</a
              >
              <a *ngIf="!scheduledCalls[selectedAppointment].meeting_url">NA</a>
            </p>
            <input
              type="url"
              *ngIf="isExpert"
              formControlName="meeting_url"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Meeting Status</label>
            <p *ngIf="!isExpert">
              {{ getMeetingStatus(scheduledCalls[selectedAppointment].status, scheduledCalls[selectedAppointment].type) }}
            </p>
            <select
              *ngIf="isExpert"
              formControlName="status"
              class="form-control"
            >
              <option [value]="0">New</option>
              <option [value]="1">Scheduled</option>
              <option [value]="2">On Going</option>
              <option [value]="3">Completed</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6" style="word-break: break-all;">
          <div class="form-group">
            <label for="">Remarks</label>
            <p>
              {{ scheduledCalls[selectedAppointment].meta?.remarks || 'NA' }} 
            </p>
          </div>
        </div>
      </div>
    </form>

    <form
      *ngIf="!isCustomService(scheduledCalls[selectedAppointment].type) && scheduledCalls[selectedAppointment].type == 'resume'"
      [formGroup]="manageAppointment"
    >
      <div class="row">
        <div class="col-sm-6">
          <label for="">Name</label>
          <p>{{ scheduledCalls[selectedAppointment]?.user_name   }}</p>
        </div>

        <div class="col-sm-6">
          <label for="">Email</label>
          <p>{{ scheduledCalls[selectedAppointment].user_email }}</p>
        </div>

        <div class="col-sm-6" *ngIf="!isExpert" style="word-break: break-all;">
          <div class="form-group">
            <label>Old CV</label>
            <p>
              <a
                target="_blank"
                [href]="scheduledCalls[selectedAppointment].meta?.cv_upload"
                >{{ scheduledCalls[selectedAppointment].meta?.cv_upload || 'NA' }}</a
              >
            </p>
          </div>
        </div>

        <div class="col-sm-6" style="word-break: break-all;">
          <div class="form-group">
            <label for="">Updated CV</label>
            <p *ngIf="!isExpert">
              <a
                target="_blank"
                [href]="scheduledCalls[selectedAppointment].meta?.updated_resume"
                >{{ scheduledCalls[selectedAppointment].meta?.updated_resume || 'NA' }}</a
              >
            </p>            
          </div>
        </div>

        <div class="col-sm-6" style="word-break: break-all;">
          <div class="form-group">
            <label for="">Uploaded Image</label>
            <p *ngIf="!isExpert">
              <a
                target="_blank"
                [href]="scheduledCalls[selectedAppointment].meta?.image_upload"
                >{{ scheduledCalls[selectedAppointment].meta?.image_upload || 'NA' }}</a
              >
            </p>           
          </div>
        </div>
        <div class="col-sm-6" style="word-break: break-all;">
          <div class="form-group">
            <label for="">Remarks</label>
            <p>
              {{ scheduledCalls[selectedAppointment].meta?.remarks || 'NA' }} 
            </p>
          </div>
        </div>
      </div>
    </form>

    <p *ngIf="scheduledCalls[selectedAppointment].status == 0 && isPaymentSuccess() && isCustomService(scheduledCalls[selectedAppointment].type)">
      Payment Paid for this job. Please wait for status update.
    </p>
    <p *ngIf="scheduledCalls[selectedAppointment].status == 3 && isPaymentSuccess() && isCustomService(scheduledCalls[selectedAppointment].type)">
      Job is completed and approval mail send to you.
    </p>
    <p *ngIf="!isCustomService(scheduledCalls[selectedAppointment].type)">
      {{ getJobRequestStatus(scheduledCalls[selectedAppointment]) }}
    </p>
  </div>
  <div class="modal-footer">
    <p *ngIf="!isPaymentSuccess()">Payment Pending for this Job</p>

    <app-pay-now
      *ngIf="!isPaymentSuccess()"
      [jobData]="scheduledCalls[selectedAppointment]"
    ></app-pay-now>

    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="updateJobRequest('call')"
      *ngIf="isExpert && isPaymentSuccess()"
    >
      Update Appointment
    </button>
  </div>
</ng-template>

<ng-template #accountSetup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Hello {{ user.name }}, Welcome on-board!</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="isExpert ? modal.close(openExpertProfile()) : modal.dismiss(openApproveRequestModal())"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="isExpert">
      Please complete your profile and bank details. Once done you will be
      seeing the 1-1 requests on Job requests tab for next steps.
    </p>
    <p *ngIf="!isExpert">
      Please browse/ search experts, jobs and courses for your required skill
      and schedule consultation with industry expert to achieve your goal
      sooner!
    </p>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="isExpert"
      type="button"
      class="btn btn-light"
      (click)="modal.close(openExpertProfile())"
    >
      Close
    </button>
    <button
      *ngIf="!isExpert"
      type="button"
      class="btn btn-light"
      (click)="modal.close(openApproveRequestModal())"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #bankDetailsUpdate let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Hello {{ user.name }}, Please update bank details below in the form
    </h4>
  </div>
  <div class="modal-body tab-content dashboard-area">
    <div class="profile-content">
      <form [formGroup]="bankDetails" (submit)="updateBankDetails()">
        <div class="profile-content-inner">
          <h2>Bank Account Details</h2>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Account Name:</label>
                <input
                  type="text"
                  formControlName="account_name"
                  class="form-control"
                  id="name"
                />
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="accountNumber">Account Number:</label>
                <input
                  type="text"
                  formControlName="account_number"
                  class="form-control"
                  id="accountNumber"
                />
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="ifscCode">IFSC Code:</label>
                <input
                  type="text"
                  formControlName="ifsc_code"
                  class="form-control"
                  id="ifscCode"
                />
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn dashboard-btn"
          [disabled]="
            !bankDetails.value.account_name ||
            !bankDetails.value.ifsc_code ||
            !bankDetails.value.account_number ||
            loaderService.isLoading
          "
        >
          <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
          <span *ngIf="!loaderService.isLoading">Save Bank Details</span>         
        </button>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #expertProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Hello {{ user.name }}, Please update profile below in the form
    </h4>
  </div>
  <div class="modal-body tab-content dashboard-area">
    <div class="profile-content">
      <app-expert-profile-update></app-expert-profile-update>
    </div>
  </div>
</ng-template>

<ng-template #resumeReview let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Review Resume</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="manageResumeRequest">
      <div class="row">
        <div class="col-sm-6">
          <label for="">Name</label>
          <p>{{ requestedResume[selectedResume].name }}</p>
        </div>

        <div class="col-sm-6">
          <label for="">Email</label>
          <p>{{ requestedResume[selectedResume].email }}</p>
        </div>

        <div class="col-sm-6">
          <label for="">Update Status</label>
          <p>
            <select
              *ngIf="isExpert"
              formControlName="status"
              class="form-control"
            >
              <option [value]="0">New</option>
              <option [value]="1">On Going</option>
              <option [value]="2">Completed</option>
              <option [value]="3">Approved</option>
            </select>
          </p>
        </div>

        <div class="col-sm-6">
          <label for="image">Image Upload</label>
          <input
            type="file"
            formControlName="image_upload"
            id="image_upload"
            (change)="uploadFile($event, 'image')"
            class="form-control"
            accept=".png, .jpg, .jpeg"
          />
        </div>

        <div class="col-sm-6">
          <label for="updated_resume">CV Upload</label>
          <input
            type="file"
            formControlName="updated_resume"
            id="updated_resume"
            (change)="uploadFile($event, 'pdf')"
            class="form-control"
            accept=".jpg,.jpeg,.png,.pdf"
          />
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label>Remarks</label>
            <p>
              <input
                type="text"
                formControlName="remarks"
                id="remarks"
                required
                class="form-control"
              />
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <p *ngIf="!isResumePaymentSuccess()">Payment Pending for this call</p>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="updateResumeRequest()"
      *ngIf="isExpert && isResumePaymentSuccess()"
    >
      Update
    </button>
  </div>
</ng-template>

<ng-template #approveRequest let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Job Approve</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="manageJobApprovalRequest">
      <div class="row">
        <div class="col-sm-12">
          <p>Please approve you are satisfied by the consultation.</p>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label>Remarks</label>
            <p>
              <input
                type="text"
                formControlName="remarks"
                id="remarks"
                required
                class="form-control"
              />
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="updateJobRequest('approve')"
      *ngIf="!isExpert"
    >
      Approve
    </button>
  </div>
</ng-template>
<!-- End Dashboard -->
