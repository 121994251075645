import { Component, Input, OnInit, ViewChild } from "@angular/core";

import { Router } from "@angular/router";
import { NgbModal,NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { CONFIGS } from "src/app/services/AppConstants";

@Component({
  selector: "app-expert-contact",
  templateUrl: "./expert-contact.component.html",
  styleUrls: ["./expert-contact.component.scss"],
})
export class ExpertContactComponent implements OnInit {
  isLoggedIn: Boolean = false;
  @Input() expert!: any;
  selectedService: any = {};
  user_type: any = CONFIGS.user_type;
  isExpert: any;
  user: any = {};
  @ViewChild("serviceModal") serviceModal;
  @ViewChild("noMessageModal") noMessageModal;
  constructor(
    private _user: UserService,
    public _api: APIServiceService,
    private _router: Router,
    private modalService: NgbModal
  ) {
    this.isExpert = _user.getUserType() == this.user_type.expert;
  
    
  }

  ngOnInit(): void {
    this.user = this._user.getUserSession();
    
    
  }

  openService(service, modal: NgbModalRef) {
    modal.close();
    // if(this.isExpert){      
    //   this.modalService.open(this.noMessageModal, { centered: true, size: "lg" });
    //   return;
    // };
    
    switch (service.service_type) {
      case "call":
        this._router.navigate(
          [
            "/experts",
            this.expert.vanity_url || this.expert.id,
            "schedule-call",
          ],
          { queryParams: { service: service.id } }
        );
        return;

      case "message":
        this._router.navigate(["/inbox"], {
          state: { expert_id: this.expert.id },
        });
        return;

      case "training":
        this._router.navigate(
          ["/experts", this.expert.vanity_url || this.expert.id, "get-trained"],
          { queryParams: { service: service.id } }
        );
        return;

      case "resume":
        this._router.navigate(
          [
            "/experts",
            this.expert.vanity_url || this.expert.id,
            "review-resume",
          ],
          { queryParams: { service: service.id } }
        );
        return;

      case "custom":
        this._router.navigate(
          [
            "/experts",
            this.expert.vanity_url || this.expert.id,
            service.service_name.replace(" /g", "_"),
          ],
          { queryParams: { service: service.id } }
        );
        return;
    }
  }

  serviceIcon(service) {
    let icon = "";
    switch (service.service_type) {
      case "call":
        icon = "assets/img/icons/customer-service.png";
        break;
      case "message":
        icon = "assets/img/icons/chat.png";
        break;
      case "training":
        icon = "assets/img/icons/teaching.png";
        break;
      case "resume":
        icon = "assets/img/icons/resume.png";
        break;
      default:
        icon = "assets/img/icons/skill-surger.png";
    }

    return icon;
  }

  serviceDescription(description) {
    return {
      text: description ? description?.substr(0, 60) + "..." : "",
      totalLength: description?.length,
    };
  }

  viewService(service) {
    this.selectedService = service;
    this.modalService.open(this.serviceModal, { centered: true, size: "lg" });
  }
}
