<div class="container">
  <div class="row">
    <div class="col-sm-3 filters">
      <h4 class="mt-2">List Your Offerings</h4>
      <hr />
      <div>
        <ul class="list-unstyled services-list">
          <li
            *ngFor="let service of services; let i = index"
            [ngClass]="selectedService == i ? 'active' : ''"
            (click)="selectService(i)"
          >
            {{ service.service_name }}
          </li>

          <li
            [ngClass]="selectedService == 'new' ? 'active' : ''"
            (click)="selectService('new')"
          >
            Add New Offer
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="card">
        <div class="card-header">Offer Details</div>
        <div class="card-body">
          <div class="form-group my-2">
            <label for="serviceName">Offer Name</label>
            <input
              type="text"
              name="service_name"
              id="serviceName"
              class="form-control"
              (change)="handleFormInput($event)"
              [value]="services[selectedService]?.service_name"
              [(ngModel)]="services[selectedService].service_name"
            />
          </div>
          <div class="form-group my-2">
            <label for="description">Description</label>
            <input
              type="text"
              name="description"
              id="description"
              class="form-control"
              (change)="handleFormInput($event)"
              [value]="services[selectedService]?.description"
              [(ngModel)]="services[selectedService].description"
            />
          </div>

          <div
            class="d-flex"
            *ngIf="services[selectedService]?.service_type != 'message'"
          >
            <div class="form-group my-2">
              <label class="label-required">Charge Type</label><br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="fixed"
                  name="charge_type"
                  value="fixed"
                  (change)="handleFormInput($event)"
                  [checked]="services[selectedService]?.charge_type == 'fixed'"
                  [(ngModel)]="services[selectedService].charge_type"
                />
                <label class="form-check-label" for="fixed">Fixed</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="hourly"
                  name="charge_type"
                  value="hourly"
                  (change)="handleFormInput($event)"
                  [disabled]="isHourlyDisabled()"
                  [checked]="services[selectedService]?.charge_type == 'hourly'"
                  [(ngModel)]="services[selectedService].charge_type"
                />
                <label class="form-check-label" for="hourly">Hourly</label>
              </div>
            </div>

            <div class="form-group my-2">
              <label for="charges">Charges</label>
              <input
                type="number"
                name="charges"
                id="charges"
                class="form-control"
                (change)="handleFormInput($event)"
                [value]="services[selectedService]?.charges"
                [(ngModel)]="services[selectedService].charges"
              />
            </div>
          </div>

          <div class="form-group my-2">
            <label for="status">Status</label><br />
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="active"
                value="active"
                (change)="handleFormInput($event)"
                [checked]="isActive(services[selectedService]?.status)"
              />
              <label class="form-check-label" for="active">Active</label>
            </div>

            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="inActive"
                value="inactive"
                (change)="handleFormInput($event)"
                [checked]="!isActive(services[selectedService]?.status)"
              />
              <label class="form-check-label" for="inActive">In-active</label>
            </div>
          </div>

          <button class="btn btn-primary" 
            [disabled]="loaderService.isLoading || (serviceForm | json) == ({} | json) || serviceForm.charges <= 0 && serviceForm.service_type != 'message' || !serviceForm.charges && serviceForm.service_type != 'message'"
            type="button"  
            (click)="updateCall()">            
            <span *ngIf="loaderService.isLoading" style="width: 80px; display: inline-block;"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
            <span *ngIf="!loaderService.isLoading">Save Offer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
