<!-- Navbar -->

<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logoIcon.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Rules -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>INTRODUCTION</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>privacy
                    policy&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;start&nbsp;</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;This
                    website, related mobile applications, and &nbsp;services available through the website and
                    &nbsp;mobile applications (&ldquo;Platform&rdquo;) (&ldquo;Platform&rdquo;) are owned by ExpertDoor
                    Technologies Private Limited and / or its affiliates (&ldquo;ExpertDoor&rdquo;). increase.
                    ExpertDoor (&ldquo;us&rdquo;, &ldquo;us&rdquo;, &ldquo;us&rdquo;) is &nbsp;committed to respecting
                    your privacy and understands how we collect, use and share information about you. I want you to do
                    it.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;This
                    privacy policy covers our data collection practices and describes your rights to restrict access,
                    modification, or &nbsp;use of your personal information. We are committed to protecting them by
                    complying with this Privacy Policy (&quot;Privacy Policy&quot;). This privacy policy explains that:
                    (A) A description of ExpertDoor practices and policies. (B) the types of information you may
                    collect from you when you access / use the Platform, and (c) the purpose and method of collecting,
                    using and disclosing the &nbsp;information you collect using the Platform. .. (D) Security practices
                    and procedures designed to protect the privacy of your information. &nbsp;This privacy policy
                    contains the provisions of the &nbsp; Information Technology Act 2000 (&quot;IT Act&quot;) and the
                    rules enacted under it, as well as amendments related to electronic documents / records of various
                    laws amended by the IT Act. Configure an electronic contract based on. Sometimes it was. This
                    privacy policy does not require a physical, electronic or digital signature, and this privacy policy
                    is a legal document between you and ExpertDoor. &nbsp;By using or accessing the platform, you agree
                    to the practices described in this Privacy Policy. You also expressly consent to the use and
                    disclosure of your information (as defined below) in the manner &nbsp; described in this Privacy
                    Policy. If you do not agree to this privacy policy or the terms contained herein, you must
                    immediately stop using the platform. We encourage you to review this privacy policy regularly as it
                    may be updated from time to time as the platform and its content evolve and mature.
                    Definition&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;&quot;Account&quot;
                    means a password-protected member registration account created by a user to access and use the
                    Platform. &quot;Non-Personal Identification Data&quot; means non-personal data collected as a result
                    of access / use of the Platform. This includes, but is not limited to, technical information about
                    the browser name, computer type, and how to connect to the platform. Operating system, internet
                    service provider to use, and other similar information. &quot;Personal data&quot; is information or
                    opinions (including information or opinions that are part of a database) about an individual that
                    the identity of the individual is clear or reasonably identifiable, whether it is true or not, and
                    specific. It means whether it is recorded in &nbsp;the format. An information or opinion platform
                    that includes, but is not limited to, access to the platform, registration, job application,
                    participation in surveys, form filling, and other information related to &nbsp; other activities,
                    services, features, or resources. You may be asked for personal information such as your name, email
                    address, phone number, and home address that you have disclosed &nbsp;in connection with the
                    services available on the platform. However, you can also access the platform anonymously.
                    ExpertDoor collects personally identifiable information from you only if you voluntarily provide
                    such information. You may refuse to provide personally identifiable information at any time unless
                    you may not be able to participate in certain activities / services available on the Platform.
                    &quot;Confidential Personal Data or Information&quot; / &quot;SPDI&quot; consists of &nbsp;the
                    following related information: (Ii) Financial information such as bank account, credit card, debit
                    card and other payment method details. (Iii) Physical, physiological and mental health; (iv) Sexual
                    orientation; (v) Medical records and medical history; (vi) Biometric information; (vii) The above
                    provided to legal entities to provide services All details related to the clause. (Viii) Information
                    obtained from an entity for processing, storage, or processing under a legitimate contract or
                    otherwise pursuant to the provisions above. For the purposes of this Privacy Policy, non-personally
                    identifiable information, personally identifiable information, and SPDI are collectively referred to
                    as &quot;information.&quot; &quot;Third Party&quot; means a customer or an individual or group other
                    than &nbsp;us. &nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;The
                    terms data and information are used interchangeably &nbsp;in this privacy policy. Privacy and
                    information we collect&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;We
                    are committed to protecting the privacy of your personal information. We endorse the right to
                    privacy, including the individual&apos;s right to control the distribution and use of personal
                    information that describes the individual, personal choice, or life experience. We are bound by the
                    privacy principles of IT law and applicable regulations (&ldquo;Privacy Law&rdquo;) in connection
                    with the management of &nbsp; Indian citizen and permanent resident information. We collect
                    information only through this platform and use, disclose and store such information in accordance
                    with data protection laws and this privacy policy.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;The
                    type of information we collect from you depends on your use of the platform. However, in general,
                    the information we may collect can fall into the following broad categories:&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;(I)
                    Personally identifiable information provided by you: Receives and stores the information &nbsp;you
                    enter on the Platform. It can represent a wide range of information. For example, creating an
                    account on the platform collects information &nbsp;such as &nbsp;name, email ID, phone number, home
                    address, qualifications, and marriage history. In some cases, we may ask you to disclose demographic
                    information such as gender, age, educational background, family history, and other relevant
                    information.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;(Ii)
                    From a third-party site: You can also register for an account using your existing Facebook,
                    LinkedIn, Gmail account, or similar third-party account and &nbsp; credentials (&ldquo;third-party
                    site account&rdquo;). As part of the platform&apos;s capabilities, you can link your account to a
                    third-party site account in any of the following ways: (i) provide credentials for your third-party
                    site account through the platform, or (ii) third. A site account permitted by the terms of use
                    applicable to &nbsp;the &nbsp;use of third party site accounts that allow access to the party. If
                    you choose to log in and register for a website using a third party website account, we will receive
                    the information you received&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>(Iii)
                    Non-personally identifiable information we collect from you: We may &nbsp;receive and store certain
                    automated information related to the use and operation of &nbsp; the Platform at any time. For
                    example, it may record information such as the area, location, and IP address of the platform
                    accessed. This allows you to identify the most popular areas of the platform and adjust the platform
                    accordingly.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;(Iv)
                    Non-personally identifiable information stored and retrieved by &quot;cookies&quot;: The Platform
                    uses a feature of Internet web browsers called &quot;cookies&quot;. A small piece of information
                    stored by your browser on your computer&apos;s hard drive. Cookies allow browsers to facilitate the
                    use of the Platform by remembering specific information about the user and the use of the Platform
                    and &nbsp; storing information about the user&apos;s preferences. Whenever you access our platform,
                    we inform you of the existence of our cookies, including information about whether you have
                    previously accessed the platform and your preferences for our platform. Use your browser to check.
                    If you do not want to receive cookies, you can either refuse them or set your internet browser
                    &nbsp;to warn you when cookies are being used. However, most platforms will not work if cookies are
                    disabled. Setting your browser to &quot;warning&quot; when cookies are being used reduces platform
                    enjoyment. For this reason, we strongly recommend that you make cookies fully functional. We do not
                    use cookies to retrieve information from your computer that was not originally sent by us, but allow
                    or share third party access to cookies placed by our platform. We do not disclose information
                    obtained from third parties except in the above cases. (V) SPDI: To ensure accuracy in servicing the
                    Platform, you may &nbsp;be required to disclose / confirm certain SPDIs, including but not limited
                    to details such as medical conditions and medical history. The type of information collected depends
                    on the interaction on the platform. It also collects some data automatically. B. Information about
                    your device and what parts of our services you use or use. ExpertDoor is committed to protecting
                    the information you provide in accordance with reasonable security practices and procedures. You
                    agree to disclose SPDI to third parties such as: B. ExpertDoor employees are requested to perform
                    duties related to the Platform. (Vi) Payment Information: When you make a purchase, we collect
                    certain information about the purchase (such as your name and address) that you need to process your
                    order. Certain payment and billing information, such as name, credit card information, billing
                    address, and mobile phone number, must be provided directly to the payment processing partner. For
                    security reasons, ExpertDoor does not collect or store sensitive cardholder data such as complete
                    credit card numbers or card authentication data.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;
                    The above data &nbsp;is stored by us and assigned to your account. How to collect your
                    information&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;You
                    &nbsp; provide us directly with most of the information we collect. To do this, enter the
                    registration details on the platform, link to a third-party website, fill out the form and
                    survey&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;</span>
            </p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;ExpertDoor
                    and service providers acting on our behalf (such as Google Analytics and third-party advertisers)
                    include server log files and &nbsp;cookies, tags, scripts, customized links, device or browser
                    fingerprints, and web beacons (collectively). To access and use the &quot;Data Collection
                    Tool&quot;) service. These data collection tools automatically track and collect specific system and
                    usage data (discussed in Section 1) when you use the service. In some cases, we combine the
                    information collected through these data collection tools with other information collected as
                    described in this Privacy Policy.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;The
                    service uses third-party browsers and mobile analytics services such as Google Tag Manager, Google
                    Analytics, Mixpanel, Netcore, Branch, Firebase, and Facebook Analytics. These services use data
                    collection tools to provide service usage, including information such as the third-party websites
                    from which they are accessed, frequency of visits, events within the service, usage and performance
                    data, and application locations. It is useful for the analysis of. Download from. We use this data
                    to improve our services, better understand how our services work on different devices, and provide
                    information that may be of interest to our customers.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;I&apos;m
                    using a third-party advertising service. Depending on the type of advertising service we use, we may
                    place cookies or other tracking technologies on your computer, phone, or other device to collect
                    data about your use of our services and &nbsp; access those tracking technologies. We may then
                    switch between these ads to suit you. ..&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;Use
                    and disclosure of your information&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;The
                    information collected by the Platform is also used to help us &nbsp;operate the Platform,
                    &nbsp;provide &nbsp;information, activities, services, features or resources &nbsp;available on the
                    Platform, and &nbsp;improve the Platform and the services it provides. .. We use your information
                    for service:&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;Providing
                    you &nbsp; services and products;&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Provide service and feedback by phone and email.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Inquiry and order processing;&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Manage &nbsp; account settings.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Ask for your feedback.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Sell and manage research and promotions managed or sponsored by ExpertDoor.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    You can learn more about you by combining your data with other third-party data &nbsp;or by
                    analyzing your data with the help of an analytics service provider.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Improve our services and develop new products, services and features.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Analyze trends and traffic, track purchases, and track usage data. When&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;⦿
                    Promote your service on third-party websites and applications. &nbsp;We make every effort to record
                    and support &nbsp;access to the Platform and to collect only the information that we believe is
                    relevant to provide &nbsp;the services provided by the Platform. &nbsp;Information about you
                    collected through the platform will not be shared with third parties except: (I) Our employees,
                    contractors, and agents: from time to time</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>SECURITY</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>We strive to ensure the security,
                    integrity, and confidentiality of your information and to protect it from unauthorized access,
                    tampering, disclosure, or destruction. Implement appropriate physical, electronic, and
                    administrative procedures to protect and &nbsp;prevent unauthorized access and maintain the security
                    of your data.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;Unfortunately, &nbsp; no system is
                    100% secure. Absolute security cannot be guaranteed because there is no way to fully protect and
                    send the data. In addition, we are not responsible for security breaches or actions of third parties
                    who receive your information. Notwithstanding this Privacy Policy or otherwise contained elsewhere,
                    we will lose your information in the event of loss, damage, or misuse resulting from events beyond
                    our reasonable control. We are &nbsp;not responsible for any &nbsp;damage or misuse. An important
                    aspect of the security of your information is the password protection system. You must keep your
                    account credentials, including your password, secure and be careful not to disclose them to third
                    parties. We strive to ensure the maximum security of your information, but ultimately you are &nbsp;
                    responsible for maintaining the confidentiality of your account credentials. If you believe
                    &nbsp;your password has been compromised, or if you become aware of any misuse of your account or
                    &nbsp;other security breaches, please let us know immediately.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;How do we store your
                    information?&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;Store your information on Amazon AWS,
                    which is based in Bangalore, India. Some of the personal information you provide to us is stored or
                    processed on our behalf by third-party service providers and data processors, and is in other
                    jurisdictions that differ from the jurisdiction to which you belong, such as the United States and
                    Israel. It may be located in. Located, live. All transfers of personal data from the European
                    Economic Region (&quot;EEA&quot;) to countries that do not &nbsp; provide &nbsp;adequate levels of
                    data protection, whether third-party or internal, are the European Union (EU) standard contractual
                    provisions and /. Or the equivalent of personal data Data transfer rules designed to protect the
                    security and confidentiality of your data. We will take all reasonable steps &nbsp; to ensure that
                    your personal information is handled safely and in accordance with this policy.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;Access, update, delete, change your
                    information&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;You can request access to the
                    information we hold about you. Although there are certain exceptions that may apply to the provision
                    of this information, we will provide access to this information in accordance with applicable law.
                    You may be charged an administration fee to grant access to the information.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;We will also take reasonable steps to
                    accurately record the information you provide, including &nbsp;subsequent updates. You may review,
                    update, and change the information we hold about you. You may also request the removal of
                    information about you that is inaccurate, incomplete, or irrelevant to a legitimate purpose or that
                    has been processed in a manner that violates applicable law. Your right to review, update, modify
                    and delete your information is also subject to applicable law, including &nbsp;statutory retention
                    requirements.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;You have specific rights to the use
                    of your information, including the ability to opt out of promotional emails and the collection of
                    your information by specific analytics providers. You can renew or cancel your account &nbsp;within
                    our services. You can also contact us regarding requests for personal rights regarding personal
                    information. Parents who may have inadvertently collected personally identifiable information about
                    a minor child need assistance in removing that information. &nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;We recognize the benefits of
                    children&apos;s privacy &nbsp;and encourage parents and guardians to play an active role in their
                    children&apos;s online activities and interests. Children under the age of 13 (or under the age of
                    16 in the European Economic Area) should not use this service. We do not knowingly collect
                    personally identifiable information from children under the age of 13. Parents who believe that
                    ExpertDoor may have collected personally identifiable information from children under that age can
                    send a request to remove that information to support@ExpertDoor.com.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;Other important
                    information&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;The platform may contain links to
                    other applications or websites. Except for other platforms owned by us, you acknowledge and agree
                    &nbsp;that these applications and websites are operated by third parties and are not controlled
                    &nbsp;or associated by us unless otherwise stated. Suppose. Therefore, you cannot control the
                    privacy practices or policies of &nbsp;applications and websites that are accessed via links from
                    this platform. If you have &nbsp;questions about the privacy practices and policies of these
                    third-party platforms, &nbsp; you should contact the company that directly controls those websites.
                    Nothing in this Privacy Policy warrants you or the collection or storage of information about you or
                    your use of the Platform. You are responsible for maintaining your own records of the use of the
                    Platform and the transactions made through it and &nbsp;complying with all applicable records
                    management laws.&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;Policy changes&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:16px;font-family:"Arial",sans-serif;'>&nbsp;ExpertDoor has the discretion to
                    update this privacy policy at any time. We encourage you to &nbsp; check this page regularly to stay
                    up to date on &nbsp;changes to your privacy policy. You acknowledge and agree that it is your
                    responsibility to regularly review this Privacy Policy &nbsp;to be aware of any changes. Your
                    continued use of our Platform after we effect any changes to the Privacy Policy signifies your
                    acceptance of those changes.</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;vertical-align:baseline;'>
                <span style='font-size:30px;font-family:"Arial",sans-serif;'>CONTACT US</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span
                    style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>If
                    you have any queries relating to the processing/usage of Information provided by you or this Privacy
                    Policy, you may contact our Grievance Officer at&nbsp;</span><strong><em><span
                            style="font-size:16px;font-family:Poppins;color:#707070;border:none windowtext 1.0pt;padding:0cm;">support@ExpertDoor.com</span></em></strong><span
                    style='font-size:16px;font-family:"inherit",serif;color:#707070;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;or
                    write to us the following address:</span></p>
            <p
                style='margin-top:7.5pt;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span style="font-size:16px;font-family:Poppins;color:#707070;">&nbsp;</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span
                    style="font-size:16px;font-family:Poppins;color:black;border:none windowtext 1.0pt;padding:0cm;">ExpertDoor
                    Technologies Private Limited,</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span
                    style="font-size:16px;font-family:Poppins;color:black;border:none windowtext 1.0pt;padding:0cm;">Hennur
                    Main Road,</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;vertical-align:top;'>
                <span
                    style="font-size:16px;font-family:Poppins;color:black;border:none windowtext 1.0pt;padding:0cm;">Bangalore,
                    Karnataka 560077</span></p>
            <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                &nbsp;</p>
        </div>
    </div>
</div>
<!-- End Rules -->
