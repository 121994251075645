import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expert-basic-more',
  templateUrl: './expert-basic-more.component.html',
  styleUrls: ['./expert-basic-more.component.scss']
})
export class ExpertBasicMoreComponent implements OnInit {
 constructor() { }
  ngOnInit(): void {
  }

  addMoreEvent() {
    // alert('hello');
  }
}
