import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CONFIGS } from "src/app/services/AppConstants";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";


@Component({
  selector: 'app-expert-profile-add-more',
  templateUrl: './expert-profile-add-more.component.html',
  styleUrls: ['./expert-profile-add-more.component.scss']
})
export class ExpertProfileAddMoreComponent implements OnInit {

  @Output() addMoreEvent = new EventEmitter<string>();
  progress: number = 0;
  isExpert: any;
  user_type: any = CONFIGS.user_type;
  user: any = {};
  userMeta: any;
  expertProfile: any;
  expertsingle: { industry: string; total_exp: string; vanity_url?: string; linkedin_url?: string } = { industry: '', total_exp: '' };
  expandedIndex: number | null = null;
  rows: any[] = [];
  formSubmitted: boolean = false;
  is_loading: boolean = false;
  maxDate: string;

  constructor(
    private _api: APIServiceService,
    private _user: UserService,
    private _notifier: NotifierService,
    public loaderService: LoaderService,
  ) {
    this.getExperinceDetail();

    const today = new Date();
    const month = today.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;
    this.maxDate = `${today.getFullYear()}-${formattedMonth}`;

  }

  ngOnInit(): void {}

  getExperinceDetail() {
    this.isExpert = this._user.getUserType() == this.user_type.expert;
    this.user = this._user.getUserSession();
    this.userMeta = this.user.user_meta;

    if(this.user.ExpertProfile){
      this.expertProfile = this.user.ExpertProfile;
    }else{
      this.expertProfile = [];
    }
    
    this.expertsingle = {
      industry: this.userMeta?.industry || '',
      total_exp: this.userMeta?.total_exp || '',
      vanity_url: this.userMeta?.vanity_url || '',
      linkedin_url: this.userMeta?.linkedin_url || ''
    };

    if (this.expertProfile.length == 0) {
      this.addMore();
    }
  }

  addMore() {
    this.expertProfile.push({
      location: '',
      position: '',
      company: '',
      role: '',
      start_year: '',
      end_year: '',
      till_now: false,
      is_new: true
    });

    this.colaspend(this.expertProfile.length - 1);

  }

  showAlert(index: number) {
    this.expertProfile.splice(index, 1);
  }

  colaspend(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  validateForm(): boolean {
    const isExpertProfileValid = this.expertProfile.every(
      (expert: any) => expert.position && expert.company && expert.role && expert.start_year
    );
    return isExpertProfileValid;
  }

  onSubmit() {
    this.is_loading = true;
    this.formSubmitted = true;
     if (!this.validateForm()) {
      this._notifier.showNotification('Please fill all required fields');
      this.is_loading = false;
      return;
    }

    const keysToMap = ['industry', 'linkedin_url', 'total_exp'];
    const expertsingleArray = keysToMap.map(key => ({
      key: key,
      value: this.expertsingle[key]
    }));

    const allRecords = [...this.expertProfile, ...expertsingleArray];
    const mainRecordArray = allRecords.filter(obj => !obj.key);
    const expertSingleArray = allRecords.filter(obj => obj.key);
    const dataToSend = {
      mainRecordArray: mainRecordArray,
      expertSingleArray: expertSingleArray
    };
    
    this._api.updateExpertProfile(dataToSend).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: "Profile Updated Successfully",
      });
      data.data.is_new = false;
      this._user.updateUserSession(data.data);
      // this.appService.setUserData(data.data); 
      this.getExperinceDetail();
      this.is_loading = false;
    });
  }
}
