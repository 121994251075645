import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  JsonpClientBackend,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

import { NotifierService } from "../NotifierService/notifier-service.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  apiBaseUrl: any = environment.apiBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private __notifier: NotifierService
  ) {}

  /**
   * Function to make GET Requests
   *
   * @param {String} url
   * @param {Object} params
   * @returns {Object}
   */
  httpGet(url: string, params?: any, headerParams?: any): Observable<any> {
    const data = { params };
    let headers = new HttpHeaders();
    const auth = localStorage.getItem("token");
    headers = headers.set("content-type", "application/json");
    headers = headers.set("Access-Control-Allow-Origin", "*");
    headers = headers.set(
      "Hide-Loader",
      headerParams?.hideLoader.toString() || "false"
    );

    if (auth) {
      headers = headers.set("Authorization", "Bearer " + auth);
    }

    let apiUrl = this.apiBaseUrl + url;

    return this.httpClient
      .get(apiUrl, { headers, params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Function to make POST Requests
   *
   * @param {String} url
   * @param {Object} params
   * @returns {Object}
   */
  httpPost(url: string, params?: any): Observable<any> {
    const data = params;
    const auth = localStorage.getItem("token");
    let headers = new HttpHeaders();
    // headers = headers.set('content-type', 'application/json');
    headers = headers.set("Access-Control-Allow-Origin", "*");
    if (auth) {
      headers = headers.set("Authorization", "Bearer " + auth);
    }

    return this.httpClient
      .post(this.apiBaseUrl + url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Function to make PUT Requests
   *
   * @param {String} url
   * @param {Object} params
   * @returns {Object}
   */
  httpPut(url: string, params?: any): Observable<any> {
    const data = params;
    const auth = localStorage.getItem("token");
    let headers = new HttpHeaders();
    // headers = headers.set('content-type', 'application/json');
    headers = headers.set("Access-Control-Allow-Origin", "*");
    if (auth) {
      headers = headers.set("Authorization", "Bearer " + auth);
    }

    return this.httpClient
      .put(this.apiBaseUrl + url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Function to make DELETE Requests
   *
   * @param {String} url
   * @param {Object} params
   * @returns {Object}
   */
  httpDelete(url: string, params?: any): Observable<any> {
    const data = params;
    const auth = localStorage.getItem("token");
    let headers = new HttpHeaders();
    headers = headers.set("content-type", "application/json");
    headers = headers.set("Access-Control-Allow-Origin", "*");
    if (auth) {
      headers = headers.set("Authorization", "Bearer " + auth);
    }

    return this.httpClient
      .delete(this.apiBaseUrl + url, { headers, params: data })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Error handler of API Response
   *
   * @param {Object} response
   * @returns
   */
  private handleError(response: any) {
    const error = response.error;
    const keys = Object.keys(error);
    const key = keys[0];
    let message = error.message;

    this.__notifier.showNotification({ message, type: "error" });

    if (message === "Unauthenticated.") {
      this.router.navigate(["logout"]);
    }

    return throwError({ message: message, error });
  }
}
