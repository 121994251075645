import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { UserService } from "src/app/services/UserService/user.service";
import { CONFIGS } from "src/app/services/AppConstants";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  
  user: any;
  userMeta: any;
  user_type: any = CONFIGS.user_type;
  bank: any;
  mode:any;
  isExpert: any;
  serviceForm: any = {};
  modalReference: any;
  isModal: boolean = false;
  closeModal = () => {
    this.modalReference.close();
    this.isModal = false;
  };

  @ViewChild("servicesForm") servicesForm;
  constructor(
    private _user: UserService,
    private modalService: NgbModal,
    private _router: Router,
  ) {
    this.isExpert = _user.getUserType() == this.user_type.expert;
    this.user = _user.getUserSession();
    this.userMeta = this.user.user_meta;
    this.bank = this.user.bank_details;
  }

  ngOnInit(): void {
    this.mode = localStorage.getItem('mode');
    setTimeout(() => {
      if (this.userMeta &&
        this.userMeta?.skills &&
        this.userMeta?.position &&
        this.userMeta?.about &&
        this.userMeta?.company &&
        this.userMeta?.phone &&
        this.bank &&
        this.bank?.account_name &&
        this.bank?.ifsc_code &&
        this.bank?.account_number
        && (history.state.serviceModal || this._user.getUserSession()?.services.length < 3)) {
        this.isModal = true;

        this.modalReference = this.modalService.open(this.servicesForm, {
          centered: true,
          size: "xl",
          backdrop: "static",
          keyboard: false,
        });
      }
    }, 1000);
  }

  ngAfterViewInit() {
    if(this.mode != "switchExpert"){
      this._router.navigate(["/dashboard"]);
    }
  
  }
  
}
