import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LinkedinService {
  linkedinUri: any = "https://www.linkedin.com/oauth/v2/";
  initAuthUrl: any =
    "authorization?response_type=code&client_id=864ce2ui8ea2ow&redirect_uri=";
  accessTokenUri: any =
    "accessToken?grant_type=authorization_code&redirect_uri=" +
    environment.applicationBaseUrl +
    "linkedin&client_id=864ce2ui8ea2ow&client_secret=eGCC84Ekt2FpKNlm";

  constructor() {}

  createInitAuthUrl(user_type) {
    const url =
      this.linkedinUri +
      this.initAuthUrl +
      environment.applicationBaseUrl +
      `linkedin&state=${user_type}&scope=r_liteprofile%20r_emailaddress`;

    return url;
  }

  getAccessTokenUrl(code) {
    const url = this.linkedinUri + this.accessTokenUri + "&code=" + code;

    return url;
  }
}
