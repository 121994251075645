import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

import { CONFIGS } from "src/app/services/AppConstants";

import { UserService } from "src/app/services/UserService/user.service";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";

function _window(): any {
  return window;
}

@Component({
  selector: "app-pay-now",
  templateUrl: "./pay-now.component.html",
  styleUrls: ["./pay-now.component.scss"],
})
export class PayNowComponent implements OnInit {
  get nativeWindow(): any {
    {
      return _window();
    }
  }

  @Input() jobData: any = {};
  @Output() closeModal = new EventEmitter();

  constructor(
    private _api: APIServiceService,
    private _user: UserService,
    private _notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  /** PAYMENT CODE */
  getCreateOrderParams() {
    let amount = this.jobData.meta.budget;

    if(this.jobData.order_note){
      var note = JSON.parse(this.jobData.order_note);
      amount = note || note.amount ? note.amount : amount;
    }

    const expert = {
      call_id: this.jobData.id,
      amount: amount,
      notes: {
        expert_id: this.jobData.user_id,
        expert_name: this.jobData.user_name,
        amount: this.jobData.meta.budget ? this.jobData.meta.budget : this.jobData.amount,
        ...this.jobData.meta,
      },
    };

    return expert;
  }

  paymentProcess(orderDetails: any) {
    const user = this._user.getUserSession();

    const options = {
      key: CONFIGS.razor_key,
      amount: orderDetails.amount,
      currency: "INR",
      name: CONFIGS.app_name,
      description: "Call Request",
      image: "https://skillsurger.com/assets/img/logo.png",
      order_id: orderDetails.order_id,
      handler: function (response) {
        response.status = "success";
        this.updateOrderStatus(response); // your function
        this.closeModal.emit();
        setTimeout(() => {
          location.reload();
        }, 500);
      }.bind(this),
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone,
      },
      notes: JSON.parse(orderDetails.notes),
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new this.nativeWindow.Razorpay(options);
    rzp1.on(
      "payment.failed",
      function (response) {
        const paymentObj = {
          status: "failed",
          razorpay_order_id: response.error.metadata.order_id,
          razorpay_payment_id: response.error.metadata.payment_id,
          razorpay_signature: response.error.reason,
        };

        this.updateOrderStatus(paymentObj);

        rzp1.close();
      }.bind(this)
    );

    rzp1.open();

    this.closeModal.emit();
  }

  createOrder() {
    this._api
      .createRazorOrder(this.getCreateOrderParams())
      .subscribe((data) => {
        this.paymentProcess(data.data);
      });
  }

  updateOrderStatus(orderDetails) {

    if(this.jobData.service_type == 'hourly'){
      orderDetails.service_details = this.jobData;
    }

    this._api.saveOrder(orderDetails).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });
    });
  }
}
