import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationsComponent } from "../../components/common/notifications/notifications.component";

@Injectable({
  providedIn: "root",
})

export class NotifierService {
  constructor(private __snackBar: MatSnackBar) {}

  showNotification(props: any) {
    const { message, btnTxt = "", icon = "", type = "" } = props;
    // this.__snackBar.openFromComponent(NotificationsComponent, {
    //   data: {
    //     message,
    //     btnTxt,
    //     icon,
    //   },
    //   duration: 2000,
    //   horizontalPosition: "center",
    //   verticalPosition: "top",
    //   panelClass: type,
    // });
  }
}
