<!-- Error -->
<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <h1>Oops!</h1>
                    <h2>Here Is Some Problem</h2>
                    <p>The page you are looking for it maybe deleted</p>
                    <a routerLink="/">Go To Home</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Error -->