import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { environment } from "src/environments/environment";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-blog-details",
  templateUrl: "./blog-details.component.html",
  styleUrls: ["./blog-details.component.scss"],
})
export class BlogDetailsComponent implements OnInit {
  blog: any;
  recentPosts: any;
  env: any = environment;

  constructor(private _api: APIServiceService, private _route: ActivatedRoute) {
    this._route.params.subscribe((params) => {
      this.getBlogDetails(params.slug);
    });

    this._api.recentPosts().subscribe((data) => {
      this.recentPosts = data.data;
    });
  }

  ngOnInit(): void {}

  getBlogDetails(slug) {
    this._api.blogs({ slug }).subscribe((data) => {
      this.blog = data.data;
    });
  }

  tags(tags) {
    return tags.split(",");
  }

  postUrl(slug) {
    return this.env.applicationBaseUrl + "blog/" + slug;
  }
}
