<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>{{ pageTitle }}</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <ng-container *ngFor="let item of path">
              <li><span>/</span></li>
              <li>
                <a [routerLink]="item.url">{{ item.title }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->
