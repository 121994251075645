import { Component, OnInit , AfterViewInit , Renderer2 , ElementRef,ViewChild} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import {AppService} from "src/app/app.service";
import { EducationModalComponent } from 'src/app/components/common/multi-modal/education-modal/education-modal.component';

@Component({
  selector: "app-candidates-details",
  templateUrl: "./candidates-details.component.html",
  styleUrls: ["./candidates-details.component.scss"],
})



export class CandidatesDetailsComponent implements OnInit, AfterViewInit {
  expert: any = {};
  profileUrl: number = 0;
  expertRandomList: any = {};
  educationList: any = {};
  experinceList: any = {};
  experince: any = {};
  education: any = {};
  lastCurrentlyEducation: any = {};
  lastCurrentlyExperience: any = {};
  lastExperience: any = {};
  
  lastTwoExperiences: any = {};
  lastEducation : any = {};
  lastTwoEducation : any = {};
  showFullText = false;
  reviewForm: FormGroup;
  currentRate: number = 0;
  index:number = 0 ;
  userSlug: any = "";
  footerHeight: any = '';
  filterOptions: any = [];
  // expandedIndex: number | null = null;
  expandedIndex: number = 0;
  expandedIndexExperince: number = 0;
  loading: boolean = true;
  private scrollListener: () => void;

  @ViewChild("educationModal") educationModal;
  @ViewChild("experinceModal") experinceModal;

  constructor(
    private _route: ActivatedRoute,
    private _api: APIServiceService,
    private _form: FormBuilder,
    private _notifier: NotifierService,
    public loaderService: LoaderService,
    private appService: AppService,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: NgbModal
  ) {
    this.reviewForm = this._form.group({
      name: new FormControl(),
      email: new FormControl(),
      review: new FormControl(),
    });

    this._route.params.subscribe((data) => {
      this.userSlug = data.slug;
     
      
    });

    this.appService.footerHeight.subscribe((obj) => {
      this.footerHeight = obj;
      
    });
  }

 

  ngOnInit(): void {
    if (this.userSlug) {
      
      
      this._api.viewExpert(this.userSlug).subscribe((data) => {
        this.profileUrl = 1;
        this.loading = false;
        
        this.expert = data.data;
        this.education = data.data.education;
        this.experince =  data.data.experince;
        this.lastExperience = this.experince[this.experince.length - 1];
        this.lastTwoExperiences = this.experince.slice(-2);
        this.lastEducation = this.education[this.education.length - 1];
        this.lastTwoEducation = this.education.slice(-2);
        const currentlyStudyingRecords = this.education.filter(record => record.currently_studying === 1);
        this.lastCurrentlyEducation = currentlyStudyingRecords.pop();
        if (!this.lastCurrentlyEducation) {
          this.lastCurrentlyEducation = this.lastEducation;
        }
        const currentlyJobRecords = this.experince.filter(records => records.till_now !== null);
        this.lastCurrentlyExperience = currentlyJobRecords.pop();
        
      });
      this._api.getFilters({ type: "experts" }).subscribe((data) => {
        this.filterOptions = data.data;
      });
      this.randomExpertList();
    }
    
  }


  navigateToProfile(expertRandom: any): void {
    const url = `/skillsurger/experts/${expertRandom.meta.vanity_url || expertRandom.id}`;
    window.location.href = url;
  }


  ngAfterViewInit(): void {
    const profileBox = this.el.nativeElement.querySelector('.profile-box');
    this.scrollListener = this.renderer.listen('window', 'scroll', () => {
      const footerPosition = document.documentElement.scrollHeight - window.innerHeight - this.footerHeight;
      const scrolledFromTop = window.scrollY || document.documentElement.scrollTop;

      if (scrolledFromTop >= footerPosition) {
        this.renderer.setStyle(profileBox, 'position', 'absolute');
        this.renderer.setStyle(profileBox, 'top', `280%`);
        this.renderer.setStyle(profileBox, 'max-height', `26rem`);
      } else {
        this.renderer.setStyle(profileBox, 'position', 'fixed');
        this.renderer.setStyle(profileBox, 'top', '140px');
        this.renderer.setStyle(profileBox, 'max-height', `26rem`);
      }
    });
  }

  submitReview() {
    const formData = this.reviewForm.getRawValue();
    formData.stars = this.currentRate;
    formData.user_id = this.expert.user_id;

    this._api.reviewExpert(formData).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });
      this.reviewForm.reset();
    });
  }
  toggleText() {
    this.showFullText = !this.showFullText;
  }
  skillsToText(skills) {
    
    if(skills){
      return skills.replaceAll('"', '').replaceAll("[", '').replaceAll("]", '');
    }
    
    return skills;

  }
  colaspend(index: number){

    if(this.expandedIndex == index) {
     
      this.expandedIndex = null;
    }else{
      this.expandedIndex = index;
    }
  }
  colaspendExperince(index: number){

    if(this.expandedIndexExperince == index) {
      
      this.expandedIndexExperince = null;
    }else{
     
      this.expandedIndexExperince = index;
    }
  }
 

   openEducationModal(education) {
    this.educationList = education;
    this.modalService.open(this.educationModal, { centered: true, size: "lg" });
  }

  openExperinceModal(experince){
    this.experinceList = experince;
    this.modalService.open(this.experinceModal, { centered: true, size: "lg" });
  }

  rateToArray(stars) {
    var a = 0;
    var starsArr = [];
    while (a < stars) {
      starsArr.push(a);
      a++;
    }

    return starsArr;
  }

  getSkills(skills: any) {
    if(skills){
       return skills.replaceAll('"', '').replaceAll("[", '').replaceAll("]", '');
     }
   }

  randomExpertList(){
    this._api.getRandomExpertList().subscribe((data) => {
      // this.expertr data.data;
      this.expertRandomList = data.data;
   
    });
  }

  getCompany(experiences: any[]): string {
    if (!experiences || experiences.length === 0) {
      return '';
    }
    const tillNowEntry = [...experiences].reverse().find(exp => exp.till_now !== null);
    if (tillNowEntry) {
      return tillNowEntry.company;
    }
  
    return experiences[experiences.length - 1].company;
  }

  getPosition(experiences: any[]): string {
    if (!experiences || experiences.length === 0) {
      return '';
    }
    const tillNowEntry = [...experiences].reverse().find(exp => exp.till_now !== null);
    if (tillNowEntry) {
      return tillNowEntry.position;
    }
  
    return experiences[experiences.length - 1].position;
  }

  getEndYear(experiences: any[]): string {
    if (!experiences || experiences.length === 0) {
      return '';
    }

    const tillNowEntry = [...experiences].reverse().find(exp => exp.till_now !== null);
    if (tillNowEntry) {
      return 'Present';
    }
  
    return experiences[experiences.length - 1].end_year;
  }
  
  getStartYear(experiences: any[]): string {
    if (!experiences || experiences.length === 0) {
      return '';
    }
    return experiences[0].start_year;
  }

}
