import {Injectable, EventEmitter} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AppService {
    private footerHeightSubject: BehaviorSubject<any>;
    public footerHeight: Observable<any>;
  
  constructor() {
    this.footerHeightSubject = new BehaviorSubject<any>(false);
    this.footerHeight = this.footerHeightSubject.asObservable();
   
  }
  public toggleSidebar: EventEmitter<any> = new EventEmitter();
  
  setFooterhight(value: any) {
    this.footerHeightSubject.next(value);
  }
  
}










