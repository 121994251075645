<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Inbox</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Inbox</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
  <div class="container">
    <a routerLink="/dashboard"><i class="flaticon-left-arrow"></i> &nbsp; Back</a>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
        
          <span *ngIf="isLoading" 
            style="margin: 20px 0px 00 80px;">
            <i id="spinLoader" class="bx bx-loader-alt" style="font-size:24px" ></i>
          </span>
          
          <a
            
            [class]="'room_'+room.expert_id+' nav-link d-flex'"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            href="#v-pills-home"
            role="tab"
            aria-controls="v-pills-home"
            *ngFor="let room of rooms; let i = index"
            (click)="openChatBox(room)"
          >
            <img [src]="room.profile_pic ? room.profile_pic : 'assets/img/avatar.jpg'" class="mr-2 inboxImg" />
            
            <p>
              {{ room.name }}<br />
              <span class="text-dark">{{ room.last_message }}</span>
            </p>
          </a>
          <!-- aria-selected="true" -->
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div class="profile-content" *ngIf="selectedRoom">
              <div class="card chat-box">
                <div class="card-header d-flex">
                  <!-- <img [src]="selectedRoom.profile_pic" /> -->
                  <img [src]="selectedRoom.profile_pic ? selectedRoom.profile_pic : 'assets/img/avatar.jpg'" class="mr-2 inboxImg" />
                  <h4>{{ selectedRoom.name }}</h4>
                </div>
                <div class="card-body inboxCardBody">
                  <ul class="chat-messages list-unstyled">
                    <li *ngFor="let msg of chatMessages; let i = index">
                      <!-- <div
                        class="d-flex"
                        [ngClass]="
                          isSender(msg.sender_id) &&
                          'justify-content-end float-right flex-direction-reverse'
                        "
                      >
                        <img
                          [ngClass]="isSender(msg.sender_id) ? 'ml-2' : 'mr-2 reciverMessage'"
                          [src]="
                            isSender(msg.sender_id)
                              ? user && user.usermeta && user.user_meta.profile_pic ? user.user_meta.profile_pic : 'assets/img/avatar.jpg'
                              : selectedRoom.profile_pic ? selectedRoom.profile_pic : 'assets/img/avatar.jpg'
                          "
                          alt=""
                        />
                        
                        <div [ngClass]="isSender(msg.sender_id) ? 'leftSide' : 'rightSide'">
                            <div class="InboxName">
                              <span *ngIf="isSender(msg.sender_id)">{{sendername}}</span>
                              <span *ngIf="!isSender(msg.sender_id)">{{receivername}}</span>
                            </div>
                            <div class="InboxMessage">
                              {{ msg.message }}
                            </div>
                        </div>

                      </div>-->
                      <div *ngIf="isSender(msg.sender_id)" [ngClass]="'rightSide'">
                       <div class="row inboxRow">
                        <div class="col-sm-11">
                          <div class="col-sm-12"><span class="inboxMinut" *ngIf="isSender(msg.sender_id)">{{sendername}}</span> <span class="inboxMinut">{{ getTimeDifference(msg.created_at) }}</span></div>
                          <div class="col-sm-12">{{ msg.message }}</div>
                        </div>  
                          <div class="col-sm-1">
                              <img [src]="this.user?.user_meta?.profile_pic || 'assets/img/avatar.jpg'" class="profile-thumb" />
                            </div>
                            
                          </div>
                      </div> 
                      <div *ngIf="!isSender(msg.sender_id)" [ngClass]="'leftSide'">
                        <div class="row inboxRow">
                          <div class="col-sm-1">
                            <img [src]="selectedRoom.profile_pic ? selectedRoom.profile_pic : 'assets/img/avatar.jpg'" class="mr-2 inboxImg" />
                          </div>  
                          <div class="col-sm-11">
                              <div class="col-sm-12"><span class="inboxMinut" *ngIf="!isSender(msg.sender_id)">{{receivername}}</span> <span class="inboxMinut">{{ getTimeDifference(msg.created_at) }}</span></div>
                              <div class="col-sm-12">{{ msg.message }}</div>
                          </div>
                            
                          </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-footer text-muted">
                  <form (submit)="sendMessage()" [formGroup]="chatForm">
                    <div class="row">
                      <div class="col-sm-10">
                        <textarea
                          formControlName="message"
                          class="form-control"
                        ></textarea>
                      </div>
                      <div class="col-sm-2">
                        <button
                          [disabled]="!chatForm.value.message"
                          class="btn btn-primary"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Dashboard -->
