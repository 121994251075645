import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";

import { CONFIGS } from "src/app/services/AppConstants";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
// import { IncompleteAlertComponent } from './incomplete-alert/incomplete-alert.component';
@Component({
  selector: "app-expert-profile-update",
  templateUrl: "./expert-profile-update.component.html",
  styleUrls: ["./expert-profile-update.component.scss"],
})
export class ExpertProfileUpdateComponent implements OnInit {
  public Editor = ClassicEditor;

  

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];

  user_type: any = CONFIGS.user_type;
  uploadedFiles: any;
  userForm: FormGroup;
  user: any = {};
  Education: any;
  Bank: any;
  Experince: any;
  userMeta: any;
  expertAvailablity: any = [];
  uploadProfilePic: any = null;
  resume : any = null;
  isExpert: any;
  mode : any ;
  isValidSlug: boolean = true;

  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;

  constructor(
    private _form: FormBuilder,
    private _api: APIServiceService,
    private _user: UserService,
    private _notifier: NotifierService,
    public loaderService: LoaderService,
    
  ) {
    this.isExpert = _user.getUserType() == this.user_type.expert;
    this.user = _user.getUserSession();
    this.userMeta = this.user.user_meta;
    this.Education = this.user.Education;
    this.Experince = this.user.ExpertProfile;
    this.Bank = this.user.bank_details;
    
    this._user.userData.subscribe((obj) => {
    this.user = obj;
    this.userMeta = this.user.user_meta;
    this.Education = this.user.Education;
    this.Experince = this.user.ExpertProfile;
    this.Bank = this.user.bank_details;  
    });
    
    // this.fruits = JSON.parse(this.userMeta?.skills || "[]");
    this.expertAvailablity = this.userMeta?.available_for
      ? JSON.parse(this.userMeta?.available_for) || []
      : [];

    const skills = this.userMeta && this.userMeta.skills ? this.userMeta?.skills.replaceAll('"', "") : null;

    this.userForm = this._form.group({
      name: new FormControl(this.user?.name, [Validators.required]),
      lname: new FormControl(this.user?.lname, [Validators.required]),
      phone: new FormControl(this.userMeta?.phone ? this.userMeta?.phone : '', [Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)      
      ]),
      email: new FormControl(this.user?.email ? this.user?.email : '', [Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      profile_pic: this.userMeta?.profile_pic,
      gender: new FormControl(this.userMeta?.gender, [Validators.required]),
      about: new FormControl(this.userMeta?.about, [Validators.required, Validators.minLength(305) ]),
      skills: new FormControl(skills, [Validators.required]),
      vanity_url: new FormControl(this.userMeta?.vanity_url, [Validators.required]),
      
    });
  }

  minLengthValidator(minLength: number) {
    return (control: FormControl) => {
      if (control.value && control.value.length < minLength) {
        return {
          minLength: {
            requiredLength: minLength,
            actualLength: control.value.length
          }
        };
      }
      return null;
    };
  }

  ngOnInit(): void {
    this.mode = localStorage.getItem('mode');
  }
  isFormValid() {
    if (!this.isExpert) {
      return (
        this.userForm.value.name &&
        this.userForm.value.lname &&
        this.userForm.value.email &&
        this.userForm.value.about &&
        this.userForm.value.skills &&
        // this.userForm.value.vanity_url &&
        this.userForm.value.gender &&
        this.userForm.value.phone &&
        this.userForm.get('phone').status == "VALID" &&
        this.userForm.get('email').status == "VALID"
      );
    }

    return (
      
      
      this.userForm.value.name &&
      this.userForm.value.lname &&
      this.userForm.value.email &&
      this.userForm.value.about &&
      this.userForm.value.skills &&
      this.userForm.value.gender &&
      this.userForm.value.phone &&
      this.userForm.get('phone').status == "VALID" &&
      this.userForm.get('email').status == "VALID"
    );
  }

  checkVanityUrl(event: any) {
    const slug = {
      slug: event.target.value,
    };
    this.isValidSlug = false;
    
    if(event.target.value == '')
    {
      this.isValidSlug = true;
    }else
    {
      this._api.verifyVanityUrl(slug).subscribe((data) => 
        this.isValidSlug = true
      );
    }
  }

  timeOptions(options) {
    const optArr = options.split(",");
    let html = "";
    optArr.forEach((element) => {
      html += `<label>${element}<label>`;
    });
    return html;
  }

  updateExpertAvailablity(value) {
    const index = this.expertAvailablity.indexOf(value);
    if (index !== -1) {
      this.expertAvailablity.splice(index, 1);
    } else {
      this.expertAvailablity.push(value);
    }
  }

  availabilityChecked(value) {
    return this.expertAvailablity.includes(value);
  }

  updateUserProfile() {
    var formData = this.userForm.getRawValue();
    formData.profile_pic = this.uploadProfilePic || this.userMeta?.profile_pic;
    formData.resume = this.resume || this.userMeta?.resume;
    formData.available_for = this.expertAvailablity;

    this._api.updateUserProfile(formData).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: "Profile Updated Succcessfully demo",
      });
      data.data.is_new = false;
      this._user.updateUserSession(data.data);
      // setTimeout(() => {
      //   location.reload();
      // }, 1500);
    });
  }

  updateCheckBoxes(name, value) {
    this.userForm.controls[name].setValue(value);
  }

  uploadFile(event: any) {
    this.uploadedFiles = <File>event.target.files;

    this._api.uploadFiles(this.uploadedFiles).subscribe((data) => {
      this.uploadProfilePic = data.data[0];
    });
  }

  uploadResume(event: any) {
    this.uploadedFiles = <File>event.target.files;

    this._api.uploadFilesResume(this.uploadedFiles).subscribe((data) => {
      this.resume = data.data[0];
    });
  }

  

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = "";
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  showValidation(fieldName, parentField = null){
    if(parentField &&
      this.userForm.get(parentField).get(fieldName).invalid &&
      (this.userForm.get(parentField).get(fieldName).dirty ||
      this.userForm.get(parentField).get(fieldName).touched )
      ){
      return true;
    }

    if( !parentField &&
      this.userForm.controls[fieldName].invalid && 
      (this.userForm.controls[fieldName].dirty ||
      this.userForm.controls[fieldName].touched)
      ) return true;

      return false;
  }


}
