import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

// import {Router}

import { UserService } from "../UserService/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private _user: UserService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this._user.getUserToken()) {
      if (route.data.role && route.data.role != this._user.getUserType()) {
        this._router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      this._router.navigate(["login"], { state: { invalidSession: true } });
      return false;
    }
  }
}
