import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/LoaderService/loader.service';

@Component({
  selector: 'app-employers-details',
  templateUrl: './employers-details.component.html',
  styleUrls: ['./employers-details.component.scss']
})
export class EmployersDetailsComponent implements OnInit {

  constructor(public loaderService: LoaderService) { }

  ngOnInit(): void {
  }

}
