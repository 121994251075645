
<app-breadcrumbs
  pageTitle="Job Requests"
  [path]="[
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Calls', url: '/dashboard/job-requests' }
  ]"
></app-breadcrumbs>

<div class="dashboard-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-sm-3 filters">
        <h4 class="mt-2">Filters</h4>
        <hr />
        <div>
          <h6>Filter by Status</h6>
          <hr />
          <ul class="list-unstyled">
            <li>
              <input
                type="checkbox"
                value="0"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Pending
            </li>
            <li>
              <input
                type="checkbox"
                value="1"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Scheduled
            </li>
            <li>
              <input
                type="checkbox"
                value="3"
                name="status[]"
                (change)="refineSearch($event)"
              />
              completed
            </li>
            <li>
              <input
                type="checkbox"
                value="4"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Approved
            </li>
          </ul>

          <h6>Job Type</h6>
          <hr />
          <ul class="list-unstyled">
            <li *ngFor="let service of SERVICES">
              <input
                type="checkbox"
                [value]="service.value"
                name="type[]"
                (change)="refineSearch($event)"
              />
              {{ service.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9">
        <ngb-accordion [closeOthers]="true" activeIds="static-0"
        (panelChange)="resetJobFeedback()"
        >
          <ngb-panel
            *ngFor="let call of scheduledCalls; let i = index"
            id="static-{{ i }}"
          >
            <ng-template
              ngbPanelHeader
              let-opened="opened"
              (click)="selectedCall = i"
            >
              <button ngbPanelToggle class="w-100 border-0 bg-transparent">
                <div
                  class="accordion-button bg-transparent custom-header"
                  [class.collapsed]="!opened"
                >
                  <div
                    class="call-user-profile d-flex align-items-center justify-content-between w-100"
                  >
                    <div>
                      <p class="m-0">
                        <img
                          [src]="call.profile_pic || 'assets/img/avatar.jpg'"
                        />
                        {{ call.user_name }}
                      </p>
                    </div>

                    <span>
                      <span>
                        Call Status: {{ getMeetingStatus(call.status, call.type) }}
                      </span>
                      <button class="btn text-danger">
                        <i class="bx bx-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </button>
            </ng-template>

            <ng-template ngbPanelContent *ngIf="isCustomService(call.type)">
              <form
              [formGroup]="manageAppointment"
              >
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label><strong>Requirements</strong></label>
                          <p>{{ call.meta.requirments }}</p>
                        </div>

                        <div class="form-group"
                        *ngIf="call.service_type && call.service_type == 'hourly'">
                          <label><strong>Expected Hours</strong></label>
                          <p>{{ call.meta.estimate_hours ? call.meta.estimate_hours+ ' Hours' : 'NA' }}</p>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for=""><strong>Status</strong></label
                          ><br />
                          <p *ngIf="call.status == -1">Please Update Budget</p>
                          <p *ngIf="call.status == 4">Approved</p>
                          <ng-container *ngIf="call.status != -1 && call.status != 4">
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                id="onGOing"
                                name="status"
                                (click)="updateJobStatus(call.id, 2, call.old_status)"
                                [checked]="call.status == 2"
                                value="2"
                                [disabled]="!isOrderSuccess(call.order_status)"
                              />
                              <label class="form-check-label" for="onGOing"
                                >On-Going</label
                              >
                            </div>

                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                id="status"
                                name="status"
                                (click)="updateJobStatus(call.id, 3, call.old_status)"
                                [checked]="call.status == 3"
                                value="3"
                                [disabled]="!isOrderSuccess(call.order_status)"
                              />
                              <label class="form-check-label" for="completed"
                                >Completed</label
                              >
                            </div>
                          </ng-container>
                        </div>

                        <div class="form-group"
                        *ngIf="call.service_type && call.service_type == 'hourly'">
                          <label><strong>Remarks</strong></label>
                          <p>
                            <input
                              type="text"
                              name="remarks"
                              id="remarks"
                              required
                              [value]="call.meta && call.meta.remarks ? call.meta.remarks : ''"
                              class="form-control"
                              (change)="updateJobFeedback($event)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  <div class="form-group"
                  *ngIf="call.service_type && call.service_type == 'fixed'">
                    <label><strong>Remarks</strong></label>
                    <p>
                      <input
                        type="text"
                        name="remarks"
                        id="remarks"
                        required
                        [value]="call.meta && call.meta.remarks ? call.meta.remarks : ''"
                        class="form-control"
                        (change)="updateJobFeedback($event)"
                      />
                    </p>
                  </div>

                  <div
                  *ngIf="call.service_type && call.service_type == 'hourly'"
                  class="col-sm-6">
                    <div class="form-group">
                      <label for="ideaHours">Hours</label>
                      <input
                        type="number"
                        name="ideaHours"
                        class="form-control"
                        [value]="call.meta?.ideaHours"
                        [disabled]="call.meta?.budget || call.status > -1"
                        (input)="updateJobFeedback($event, call)"
                      />
                    </div>
                  </div>

                  <div 
                  *ngIf="call.service_type && call.service_type == 'hourly'"
                  class="col-sm-6">
                    <label for="budget">Budget</label>
                    <input
                      type="number"
                      name="budget"
                      class="form-control"
                      [value]="call.meta?.budget ? call.meta?.budget : customServiceFeedback?.budget"
                      [disabled]="true"
                      (change)="updateJobFeedback($event)"
                    />
                  </div>
                </div>
                <div class="row">
                <div class="col-sm-6">
                  <p
                    class="sub-title float-left text-danger"
                    *ngIf="isCustomJobPaymentPaid(call)"
                  >
                    Payment not completed yet.
                  </p>
                </div>
                <div class="col-sm-6">                
                  <button
                    class="btn btn-primary float-right my-2 ml-2"
                    type="button"
                    (click)="
                      selectedCall = i; viewCallModal(call.type.toLowerCase())
                    "
                  >
                    View Details
                  </button>
                  
                  <button
                    [disabled]="isCustomJobPaymentPaid(call) || call.status > 3"
                    class="btn btn-primary float-right my-2"
                    (click)="updateJobRequest(i) || loaderService.isLoading"
                  >                  
                    <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                    <span *ngIf="!loaderService.isLoading">Submit</span> 
                  </button>
                </div>
              </div>
              </form>
            </ng-template>

            <ng-template ngbPanelContent>
              <form
                *ngIf="call.type.toLowerCase() === 'call' || call.type.toLowerCase() === 'training'"
                [formGroup]="manageAppointment"
                (submit)="updateAppointment(call.id)"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <label for="">Name</label>
                    <p>{{ call.user_name }}</p>
                  </div>

                  <div class="col-sm-6">
                    <label for="">Email</label>
                    <p>{{ call.user_email }}</p>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label
                        >Select Time ({{
                          call.meta.selected_time | date: "medium"
                        }})</label
                      >
                      <div
                        class="form-check"
                        *ngFor="
                          let time of timeOptionsToArray(call.meta.time_options)
                        "
                      >
                        <input
                          type="radio"
                          formControlName="selected_time"
                          [value]="time"
                          *ngIf="time"
                          [id]="time"
                        />&nbsp;
                        <label [for]="time">{{ time | date: "medium" }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Meeting URL</label>
                      <input
                        type="url"
                        formControlName="meeting_url"
                        class="form-control"
                        value="{{ call.meta.meeting_url }}"
                      />
                      <p>
                        <a target="_blank" [href]="call.meta.meeting_url">
                          {{ call.meta.meeting_url }}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Meeting Status</label>
                      <p>{{ getMeetingStatus(call.status, call.type) }}</p>
                      <div class="form-check" *ngIf="call.status == 1">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="comletedJob"
                          (click)="updateJobStatus(call.id, 3, call.old_status)"
                        />
                        <label class="form-check-label" for="exampleCheck1"
                          >Completed</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Payment Status</label>
                      <p>{{ call.order_status }}</p>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Remarks</label>
                  <p>
                    <input
                      type="text"
                      formControlName="remarks"
                      id="remarks"
                      required
                      [value]="call.meta && call.meta.remarks ? call.meta.remarks : ''"
                      class="form-control"
                      [(ngModel)]="call.meta.remarks"
                    />
                  </p>
                </div>
                <p
                  class="sub-title text-danger"
                  *ngIf="!isOrderSuccess(call.order_status)"
                >
                  Payment not completed yet.
                </p>

                <button
                  class="btn btn-primary float-right mb-2"
                  type="button"
                  (click)="
                    selectedCall = i; viewCallModal(call.type.toLowerCase())
                  "
                >
                  View {{ call.type == 'training'? 'Detail' : 'Call' }}
                </button>

                <button
                  class="btn btn-primary float-right mb-2 mr-2"
                  type="submit"
                  [disabled]="(call.old_status > 2 || !isOrderSuccess(call.order_status)) || loaderService.isLoading"
                >
                  <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                  <span *ngIf="!loaderService.isLoading">
                    <!-- {{call.type == 'training'? 'Schedule Training' : 'Schedule Call' }} -->
                    Submit
                  </span> 
                </button>
              </form>

              <form
                *ngIf="call.type.toLowerCase() === 'resume'"
                [formGroup]="manageResumeRequest"
                (submit)="updateAppointment(call.id, i, 'resume')"
              >
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <label for="">Name</label>
                      <p>{{ call.user_name }}</p>
                    </div>

                    <div class="col-sm-6">
                      <label for="">Email</label>
                      <p>{{ call.user_email }}</p>
                    </div>

                    <div class="col-sm-6">
                      <label for="">Update Status</label>
                      <p>
                        <select
                        *ngIf="call.old_status < 2"
                          formControlName="status"
                          class="form-control"
                          [value]="call.status"
                          [(ngModel)]="call.status"
                        >
                          <option value="0">New</option>
                          <option value="1">On Going</option>
                          <option value="2">Completed</option>
                          <option value="3">Approved</option>
                        </select>
                        <select
                          *ngIf="call.old_status > 1"
                          formControlName="status"
                          class="form-control"
                          [value]="call.status"
                          [(ngModel)]="call.status"
                        >
                          <option value="2" *ngIf="call.old_status == 2">Completed</option>
                          <option value="3" *ngIf="call.old_status == 3">Approved</option>
                        </select>
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <label for="image">Image Upload</label>
                      <input
                        type="file"
                        formControlName="image_upload"
                        id="image_upload"
                        (change)="uploadFile($event, 'image')"
                        class="form-control"
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>

                    <div class="col-sm-6">
                      <label for="updated_resume">CV Upload</label>
                      <input
                        type="file"
                        formControlName="updated_resume"
                        id="updated_resume"
                        (change)="uploadFile($event, 'pdf')"
                        class="form-control"
                        accept=".jpg,.jpeg,.png,.pdf"
                      />
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Remarks</label>
                        <p>
                          <input
                            type="text"
                            formControlName="remarks"
                            id="remarks"
                            required
                            [value]="call.meta && call.meta.remarks ? call.meta.remarks : ''"
                            class="form-control"
                            [(ngModel)]="call.meta.remarks"
                          />
                        </p>
                      </div>
                    </div>

                    <div *ngIf="call.type == 'resume'" class="col-sm-12">
                      <label for="updated_resume">Uploaded CV</label>
                      <a
                        href="{{ call.meta.cv_upload }}?download"
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                        style="margin-left: 5px;"
                      >
                        <button class="btn btn-primary float-left mb-2 mr-2"
                        type="button">Download</button>
                      </a>
                    </div>

                  </div>
                </div>
                <p
                  class="sub-title text-danger"
                  *ngIf="!isOrderSuccess(call.order_status)"
                >
                  Payment not completed yet.
                </p>

                <button
                  class="btn btn-primary float-right mb-2 mr-2"
                  type="button"
                  (click)="
                    selectedCall = i; viewCallModal(call.type.toLowerCase())
                  "
                >
                  View Details
                </button>

                <button
                  class="btn btn-primary float-right mb-2 mr-2"
                  type="submit"
                  [disabled]="!isOrderSuccess(call.order_status) || call.old_status > 1 || loaderService.isLoading"
                > 
                  <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                  <span *ngIf="!loaderService.isLoading">Update</span>                
                </button>
              </form>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

        <div class="pagination-area mt-2">
          <ngb-pagination
            [collectionSize]="pagination.total"
            [(page)]="pagination.current_page"
            [maxSize]="3"
            [pageSize]="pagination.per_page"
            [boundaryLinks]="true"
            (pageChange)="changePange($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewCall let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Meeting With {{ scheduledCalls[selectedCall].user_name }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Problem:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.problem }}</p>
    </div>
    <div class="form-group">
      <label><b>Session Expectation:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.session_expectation }}</p>
    </div>
    <div class="form-group">
      <label><b>Previous Steps:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.previous_steps }}</p>
    </div>
    <div class="form-group">
      <label><b>Selected Time of Call:</b></label>
      <p>
        {{ scheduledCalls[selectedCall].meta.selected_time | date: "medium" }}
      </p>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label><b>Day Time:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.day_time }}
          </p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Weekday:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.week_time }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group" style="word-break: break-all;">
          <label><b>Meeting Url:</b></label>
          <br />
          <a href="{{ scheduledCalls[selectedCall].meta.meeting_url }}">
            {{ scheduledCalls[selectedCall].meta.meeting_url }}
          </a>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Status:</b></label>
          <p>
            {{ getMeetingStatus(scheduledCalls[selectedCall].meta.status, scheduledCalls[selectedCall].type) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #viewTrainingRequest let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Meeting With {{ scheduledCalls[selectedCall].user_name }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Problem:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.problem }}</p>
    </div>
    <div class="form-group">
      <label><b>Time of Traning:</b></label>
      <p>
        {{ scheduledCalls[selectedCall].meta.selected_time | date: "medium" }}
      </p>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label><b>Day Time:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.day_time }}
          </p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Weekday:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.week_time }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group" style="word-break: break-all;">
          <label><b>Meeting Url:</b></label>
          <br />
          <a href="{{ scheduledCalls[selectedCall].meta.meeting_url }}">
            {{ scheduledCalls[selectedCall].meta.meeting_url }}
          </a>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Status:</b></label>
          <p>
            {{ getMeetingStatus(scheduledCalls[selectedCall].meta.status, scheduledCalls[selectedCall].type) }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="scheduledCalls[selectedCall].remarks" class="col-sm-6" style="word-break: break-all;">
        <div class="form-group">
          <label for="">Student Remarks</label>
          <p>
            {{ scheduledCalls[selectedCall].remarks }} 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #viewResumeRequest let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Resume Request From {{ scheduledCalls[selectedCall].user_name }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Skills:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.skills }}</p>
    </div>
    <div class="form-group">
      <label><b>Job Id:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.job_id }}</p>
    </div>
    <div class="form-group">
      <label><b>Job Posting Link:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.job_posting_link }}</p>
    </div>
    <div class="form-group">
      <label><b>Year of Experience:</b></label>
      <p>
        {{ scheduledCalls[selectedCall].meta.year_of_exp }}
      </p>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label><b>Linkedin Profile:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.linkedin_profile }}
          </p>
        </div>
      </div>
      <div *ngIf="scheduledCalls[selectedCall].remarks" class="col-sm-6" style="word-break: break-all;">
        <div class="form-group">
          <label for="">Student Remarks</label>
          <p>
            {{ scheduledCalls[selectedCall].remarks }} 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #viewCustomRequest let-modal>
  <div class="modal-header">
    <h4 class="modal-title">  Request Detail </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
  >
    <div class="row">
      <div class="col-sm-6">
        <label for="">Name</label>
        <p>{{ scheduledCalls[selectedCall]?.user_name   }}</p>
      </div>

      <div class="col-sm-6">
        <label for="">Email</label>
        <p>{{ scheduledCalls[selectedCall].user_email }}</p>
      </div>

      <div class="col-sm-6" *ngIf="scheduledCalls[selectedCall].service_type && scheduledCalls[selectedCall].service_type == 'hourly'">
        <div class="form-group">
          <label>Budget</label>
          <p>
            {{ scheduledCalls[selectedCall].meta?.budget || 'NA'  }}
          </p>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="scheduledCalls[selectedCall].service_type && scheduledCalls[selectedCall].service_type == 'hourly'">
        <div class="form-group">
          <label for="">Estimated Hours</label>
          <p> {{ scheduledCalls[selectedCall].meta?.estimate_hours || 'NA' }}
          </p>
          
        </div>
      </div>
      <div class="col-sm-6" *ngIf="scheduledCalls[selectedCall].service_type && scheduledCalls[selectedCall].service_type == 'hourly'">
        <div class="form-group">
          <label for="">Given Hours</label>
          <p> {{ scheduledCalls[selectedCall].meta?.ideaHours || 'NA' }}
          </p>
          
        </div>
      </div>

      <div *ngIf="scheduledCalls[selectedCall].remarks" class="col-sm-6" style="word-break: break-all;">
        <div class="form-group">
          <label for="">Student Remarks</label>
          <p>
            {{ scheduledCalls[selectedCall].remarks }} 
          </p>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <label for="">Request Status</label>
          <p >
            {{ getMeetingStatus(scheduledCalls[selectedCall].status) }}
          </p>
        </div>
      </div>
    </div>
  </form>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

