<app-incomplete-alert></app-incomplete-alert>
<ng-container>
  <div class="profile-content-inner">
    <h2 class="heading-underline">Education</h2>
    <div *ngFor="let education of expertEducation; let k = index">
      <div class="accordion accordion-flush" id="accordionFlushExample{{k}}">
        <div class="accordion-item itemCustom" >
          <h2 class="accordion-header accordion-headerNew" id="flush-heading{{k}}">
            <button class="accordion-button collapsed " (click)="colaspend(k)" type="button">
              {{education.degree}}
            </button>

          </h2>
          <div id="flush-collapseOne1" class="accordion-collapse {{expandedIndex == k ? 'expanded' : 'collapse'}}" data-bs-parent="#accordionFlushExample{{k}}">
            <div class="row row-acc">
              <div class="col-lg-12 col-md-12 d-flex justify-content-end trasPointer" *ngIf="education.is_new">
                <i class="bx bx-trash ml-2" (click)="showAlert(k)"></i>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label class="label-required">Degree/Qualification:</label>
                  <input type="text" class="form-control more-input" value="{{education.degree ?? ''}}" [(ngModel)]="education.degree" placeholder="Degree/Qualification" />
                  <div *ngIf="!education.degree && formSubmitted" class="text-danger">Degree is required</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label class="label-required">Field of Study:</label>
                  <input type="text" class="form-control more-input" value="{{education.field_of_study}}" [(ngModel)]="education.field_of_study" placeholder="Field of Study" />
                  <div *ngIf="!education.field_of_study && formSubmitted" class="text-danger">Field of Study is required</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label class="label-required">Institution Name:</label>
                  <input type="text" class="form-control more-input" value="{{education.institution}}" [(ngModel)]="education.institution" placeholder="Institution" />
                  <div *ngIf="!education.institution && formSubmitted" class="text-danger">Institution is required</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label class="label-required">Location:</label>
                  <input type="text" class="form-control more-input" value="{{education.location}}" [(ngModel)]="education.location" placeholder="Location" />
                  <div *ngIf="!education.location && formSubmitted" class="text-danger">Location is required</div>
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <div class="form-group">
                  <label class="label-required">Start Date:</label>
                  <input type="month" class="form-control more-input" value="{{education.start_date }}" [(ngModel)]="education.start_date" placeholder="Start Date" [max]="maxDate" />
                  <div *ngIf="!education.start_date && formSubmitted" class="text-danger">Start Date is required</div>
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <div class="form-group">
                  <label>End Date:</label>
                  <input type="month" class="form-control more-input" value="{{education.end_date}}" [(ngModel)]="education.end_date" placeholder="End Date" [max]="maxDate" />
                </div>
              </div>
              <div class="col-lg-2 col-md-12">
                <div class="form-group">
                  <label>Currently Studying:</label>
                  <br />
                  <div class="form-check form-check-inline">
                    <input class="form-check-input till" type="checkbox"  id="check1" [(ngModel)]="education.currently_studying" [checked]="education.currently_studying !== 0 ? true : false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
      <div class="col-lg-12 col-md-12 d-flex justify-content-end">
        <i class="bx bx-plus ml-2 add-cursor" (click)="addMore()"></i>
      </div>
    </div>  
  </ng-container>
  
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div
        class="btn"
        [ngClass]="{
          'dashboard-btn': validateForm() && !is_loading,
          'dashboard-btn-not': !validateForm() || is_loading,
          'disabled-cursor': !validateForm() || is_loading
        }"
        (click)="validateForm() ? onSubmit() : null"
      >
        Save Education Details
      </div>
    </div>
  </div>
  