<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Schedule Call</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Schedule Consultation</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Contact -->
<div class="dashboard-area pb-100 mt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="profile-item">
          <img
            [src]="
              expert?.profile_pic
                ? expert?.profile_pic
                : 'assets/img/avatar.jpg'
            "
            alt="Dashboard"
          />
          <h2>{{ expert.name }}</h2>
          <span class="mb-2">{{ expert.position }}</span>
          <h5>
            Charges: Rs. {{ serviceDetails.charges }}/- ({{
              serviceDetails.charge_type
            }})
          </h5>
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <div class="profile-content" *ngIf="isExpert">
          <div class="profile-content-inner">
            <h2>Experts can't schedule call with another expert.</h2>
          </div>
        </div>
        <div class="profile-content" >
          <form
            id="contactForm"
            (submit)="requestService()"
            [formGroup]="contactForm"
          >
            <div class="profile-content-inner">
              <h2>{{ formTitle }}</h2>
              <div class="row">
                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="name">Name</label>
                    <input
                      type="text"
                      formControlName="name"
                      id="name"
                      required
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <label class="label-required" for="phone_no">Phone</label>
                    <input
                      type="text"
                      formControlName="phone_no"
                      id="phone_no"
                      required
                      class="form-control"
                      placeholder="88888-88888"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('phone_no')">
                      Please enter valid 10 digit phone number.
                    </p>
          
                  </div>

                  <div class="form-group">
                    <label class="label-required" for="email">Email</label>
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      required
                      class="form-control"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('email')">
                      Please enter valid email address.
                    </p>
                  </div>

                  <div formArrayName="meta">
                    <div class="form-group">
                      <label class="label-required" for="requirements"
                        >Requirements</label
                      >
                      <textarea
                        id="requirements"
                        formControlName="requirments"
                        class="form-control"
                        required
                      ></textarea>
                    </div>

                    <div class="form-group" 
                    *ngIf="serviceDetails.charge_type == 'hourly'"
                    >
                      <label class="label-required" for="estimate_hours"
                        >Estimate Hours</label
                      >
                      <input
                        type="number"
                        formControlName="estimate_hours"
                        id="estimate_hours"
                        class="form-control"
                        required
                        min="1"
                      />
                      <p class="invalid-feedback"
                      *ngIf="showValidation('estimate_hours', 'meta')">
                        Please enter valid number.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              [disabled]="!getFormValidationErrors() || loaderService.isLoading"
              class="btn dashboard-btn"
            >              
              <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
              <span *ngIf="!loaderService.isLoading">Request Call</span>  
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Contact -->
</div>
