<strong class="m-0">Or</strong>
<div class="d-flex align-items-center justify-content-around">
    <button (click)="signInWithGoogle()" class="btn btn-social-login btn-google">
        <span><i class="fab fa-google"></i></span>
        <p>Login with Google</p>
    </button>
    <button (click)="signInWithFB()" class="btn btn-social-login btn-facebook">
        <span><i class="bx bxl-facebook"></i></span>
        <p>Login with Facebook</p>
    </button>
    <!-- <a (click)="signInWithLinkedIn()" class="btn-social-login"><img src="assets/img/linkedin-button.png"></a> -->
</div>

<!-- <div class="end">
    <ul>
        <li>
            <a (click)="signInWithFB()" target="_blank"><i class="bx bxl-facebook"></i
      ></a>
        </li>
        <li>
            <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
        </li>
        <li>
            <a (click)="signInWithLinkedIN()" target="_blank"><i class="bx bxl-linkedin"></i
      ></a>
        </li>
        <li>
            <a (click)="signInWithGoogle()" target="_blank"><i class="bx bxl-pinterest-alt"></i
      ></a>
        </li>
    </ul>
</div> -->