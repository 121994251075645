<div class="container mt-5 pt-100">
    <div class="section-title text-center mt-5">
        <h2>What Are you looking for?</h2>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="company-item p-2 login-cards">
                <div class="top text-center p-5 login-selection">
                    <img src="assets/img/user.png">
                    <h2>User</h2>
                    <p>Want to land your dream career?</p>
                    <a [routerLink]="['/login']" class="btn">Login</a>
                    <a [routerLink]="['/register']">Begin as a User</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="company-item p-2 login-cards">
                <div class="top text-center p-5 login-selection">
                    <img src="assets/img/expert.png">
                    <h2>Expert</h2>
                    <p>Want to help career seekers?</p>
                    <a [routerLink]="['/login']" class="btn">Login</a>
                    <a [routerLink]="['/expert-registration']">Begin as an Expert</a>
                </div>
            </div>
        </div>
    </div>
</div>