import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";

@Component({
  selector: "app-job-requests",
  templateUrl: "./job-requests.component.html",
  styleUrls: ["./job-requests.component.scss"],
  //encapsulation: ViewEncapsulation.None,
})
export class JobRequestsComponent implements OnInit {
  scheduledCalls: any = [];
  manageAppointment: FormGroup;
  manageResumeRequest: FormGroup;
  pagination: any = {};
  selectedCall: any = 0;
  expertServices: any = {};
  customServiceFeedback: any = {};
  param_call_id: any = null;
  uploadedFiles: any;

  SERVICES: any = [
    {
      title: "Calls",
      value: "call",
    },
    {
      title: "Resumes",
      value: "resume",
    },
  ];

  filters: any = {
    page: 1,
  };

  @ViewChild("viewCall") viewCall;
  @ViewChild("viewResumeRequest") viewResumeRequest;
  @ViewChild("viewTrainingRequest") viewTrainingRequest;
  @ViewChild("viewCustomRequest") viewCustomRequest;
  constructor(
    private _api: APIServiceService,
    private _form: FormBuilder,
    private _notifier: NotifierService,
    private modalService: NgbModal,
    public loaderService: LoaderService,
    public route: ActivatedRoute
  ) {
    this.getShceduledCalls();

    this.manageAppointment = this._form.group({
      selected_time: new FormControl(),
      meeting_url: new FormControl(),
      remarks: new FormControl(),
      status: 1,
    });

    this.manageResumeRequest = this._form.group({
      updated_resume: new FormControl(),
      image_upload: new FormControl(),
      remarks: new FormControl(),
      status: new FormControl(),
    });

    this._api.getExpertsServices().subscribe((data) => {
      const services = data.data.map((service) => {
        if (
          service.service_type == "call" ||
          service.service_type == "resume"
        ) {
          return { title: service.service_name, value: service.service_type };
        }
        return { title: service.service_name, value: service.id };
      });

      this.SERVICES = services;
    });
  }

  ngOnInit(): void {}

  isPositiveInteger(str) {
    if (typeof str !== 'string')  return false;
    const num = Number(str);
  
    if (Number.isInteger(num) && num > 0)  return true;
    return false;
  }

  getShceduledCalls() {
    
    this.route.queryParams
    .subscribe(params => {
      this.param_call_id = params.call_id && !params.user_id && this.isPositiveInteger(params.call_id) ? params.call_id : null;
      var baseUrl = window.location.href.split("?")[0];
      window.history.pushState('call_id', '', baseUrl);
    });


    if(this.param_call_id && this.filters.page == 1){
      this.filters['call_id'] = this.param_call_id;
    }else{
      this.param_call_id = null;
    }

    this._api.getSchedulesJobs(this.filters).subscribe((data) => {
      const {
        data: { data: jobRequests, total, per_page, current_page },
      } = data;
      
      if(this.param_call_id){
        const index = jobRequests.findIndex(object => {
          return object.id == this.param_call_id;
        });
        this.scheduledCalls = this.moveArrayItemToNewIndex(jobRequests, index, 0);
      }else{
        this.scheduledCalls = jobRequests;
      } 
      
      this.scheduledCalls = this.scheduledCalls.map((request) => {
        return { ...request, meta: JSON.parse(request.meta), old_status: request.status };
      });

      this.pagination = {
        total,
        current_page,
        per_page,
      };
    });
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
  };

  getMeetingStatus(status: any, type = null) {
    if(type == "resume"){
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "On Going";
        case "2":
          return "Completed";
        case "3":
          return "Approved";
        default :
          return "Completed";
      }
    }else{
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "Scheduled";
        case "2":
          return "On Going";
        case "3":
          return "Completed";
        case "4":
          return "Approved";
        case "-1":
          return "Requested";
        default:
          return "Completed";
      }
    }
  }

  isCustomJobPaymentPaid(call : any, isSubmitButton = false) {
    if(
      call.service_type && 
      call.service_type == 'hourly' &&
      call.meta?.budget &&
      !this.isOrderSuccess(call.order_status)
      ) return true;

      if(
        call.service_type && 
        call.service_type == 'fixed' &&
        !this.isOrderSuccess(call.order_status)
        ) return true;

        
      if(call.old_status > 2 && isSubmitButton) return true;

      return false;
  }

  updateAppointment(call_id, index, type = "call") {
    let formData = null;
    const job = this.scheduledCalls[index];

    if (type == "call") {
      formData = this.manageAppointment.getRawValue();
    } else {
      formData = this.manageResumeRequest.getRawValue();

      if(!formData.image_upload && job.meta.image_upload){
        formData.image_upload = job.meta.image_upload;
      }
      if(!formData.updated_resume && job.meta.updated_resume){
        formData.updated_resume = job.meta.updated_resume;
      }
    }

    formData.id = call_id;
    if(formData.old_status){
      delete formData["old_status"];
    }

    this._api.updateSchedulesJobs(formData).subscribe((data) => {
      this._notifier.showNotification({ message: data.data, type: "success" });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  timeOptionsToArray(options) {
    let timeOptions = [];
    for (var option in options) {
      timeOptions.push(options[option]);
    }

    return timeOptions.filter((option) => {
      return option;
    });
  }

  isOrderSuccess(status) {
    return status == "success";
  }

  filtersFormatting(event) {
    let newFilter = { ...this.filters };

    if (newFilter.hasOwnProperty(event.target.name)) {
      const index = newFilter[event.target.name].indexOf(event.target.value);

      if (index === -1) {
        newFilter[event.target.name].push(event.target.value);
      } else {
        newFilter[event.target.name].splice(index, 1);
      }
    } else {
      newFilter = {
        [event.target.name]: [event.target.value],
      };
    }

    return newFilter;
  }

  refineSearch(event) {
    let newFilter = this.filtersFormatting(event);

    this.filters = { ...this.filters, ...newFilter };

    setTimeout(() => {
      this.getShceduledCalls();
    }, 200);
  }

  changePange(page) {
    this.filters = { ...this.filters, page };

    this.getShceduledCalls();
  }

  viewCallModal(jobType = "call") {
    if (jobType == "call") {
      this.modalService.open(this.viewCall, { centered: true });
    } else if (jobType == "resume") {
      this.modalService.open(this.viewResumeRequest, { centered: true });
    } else if(jobType == 'training') {
      this.modalService.open(this.viewTrainingRequest, { centered: true });
    }else{
      this.modalService.open(this.viewCustomRequest, { centered: true });      
    }
  }

  isResumePaymentSuccess() {
    return this.scheduledCalls[this.selectedCall].order_status == "success";
  }

  uploadFile(event: any, type: any) {
    this.uploadedFiles = <File>event.target.files;

    this._api.uploadFiles(this.uploadedFiles).subscribe((data) => {
      if (type == "image") {
        this.manageResumeRequest.patchValue({
          image_upload: data.data[0],
        });
      } else {
        this.manageResumeRequest.patchValue({
          updated_resume: data.data[0],
        });
      }
    });
  }

  updateJobFeedback(event, call = null) {
    const {
      target: { name, value },
    } = event;
    if(name == 'ideaHours' && call){
      this.customServiceFeedback = {
        ...this.customServiceFeedback,
        [name]: value,
        'budget': value * call.service_charge
      };
    }else{
      this.customServiceFeedback = {
        ...this.customServiceFeedback,
        [name]: value,
      };
    }
    
  }

  resetJobFeedback(){
    this.customServiceFeedback= {};
  }

  updateJobRequest(index) {
    const job = this.scheduledCalls[index];

    const jobMeta = {
      ...job.meta,
      ...this.customServiceFeedback,
      id: job.id,
      status: 0,
    };
    
    if(this.isCustomService(job.type)){
      jobMeta.status = job.status != -1 ? job.status : 0;
      // jobMeta.meta.remarks = this.customServiceFeedback.remarks;
    }
    if(jobMeta.old_status){
      delete jobMeta["old_status"];
    }
    // return;

    this._api.updateSchedulesJobs(jobMeta).subscribe((data) => {
      this._notifier.showNotification({ message: data.data, type: "success" });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  isCustomService(type) {
    if (type == "call") return false;
    if (type == "resume") return false;
    if (type == "training") return false;
    return true;
  }

  updateJobStatus(id, status, old_status) {
    if(old_status >= status) return;
    const formData = { id, status };
    this._api.updateSchedulesJobs(formData).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: "Job Status Updated Successfully",
      });
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }
}
