import { Component, OnInit } from '@angular/core';
import { UserService } from "src/app/services/UserService/user.service";

@Component({
  selector: 'app-incomplete-alert',
  templateUrl: './incomplete-alert.component.html',
  styleUrls: ['./incomplete-alert.component.scss']
})
export class IncompleteAlertComponent implements OnInit {

  user: any = {};
  Education: any;
  mode: any;
  Bank: any;
  Experince: any;
  userMeta: any;

  constructor(
    private _user: UserService,
  ) { }

  ngOnInit(): void {
    this.mode = localStorage.getItem('mode');
    this._user.userData.subscribe((obj) => {
      this.user = obj;
      this.userMeta = this.user.user_meta;
      this.Education = this.user.Education;
      this.Experince = this.user.ExpertProfile;
      this.Bank = this.user.bank_details;  
      });
   
      
    }

    isBasicInfoIncomplete(): boolean {
      return !this.userMeta ||
        !this.userMeta.phone ||
        !this.userMeta.gender ||
        !this.userMeta.skills ||
        !this.userMeta.about;
    }
  
    isExperienceIncomplete(): boolean {
      return !this.Experince || this.Experince.length === 0;
    }
  
    isEducationIncomplete(): boolean {
      return !this.Education || this.Education.length === 0;
    }

}
