<app-breadcrumbs
  pageTitle="List Your Offerings"
  [path]="[
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'List Your Offerings', url: '/dashboard/services' }
  ]"
></app-breadcrumbs>

<div class="dashboard-area ptb-100">
  <app-services-form *ngIf="!isModal"></app-services-form>

  <ng-template #servicesForm let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        Please update each service details below in the form so you can
        complete profile and get listed
      </h4>
    </div>
    <div class="modal-body tab-content dashboard-area">
      <app-services-form
        [closeModal]="closeModal"
        [isModal]="true"
      ></app-services-form>
    </div>
  </ng-template>
</div>
