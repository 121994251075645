<!-- Banner -->
<div class="banner-area two">
    <div class="banner-shape-two">
        <img src="assets/img/home-two/banner-shape.png" alt="Shape">
        <img src="assets/img/home-two/banner-bottom-shape.png" alt="Shape">
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="banner-content two">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <p>We have 280,000+ live jobs</p>
                            <h1>Next Future Job Waiting For You Hold It!</h1>

                            <div class="banner-form-area">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label><i class='bx bx-search'></i></label>
                                                <input type="text" class="form-control" placeholder="Search Your Job">
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <select>
                                                    <option>All Categories</option>
                                                    <option>Another option</option>
                                                    <option>A option</option>
                                                    <option>Potato</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="form-group two">
                                                <label><i class='bx bx-location-plus'></i></label>
                                                <input type="text" class="form-control" placeholder="Location">
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn">Search Job <i class='bx bx-search'></i></button>
                                </form>
                            </div>

                            <div class="banner-key">
                                <ul>
                                    <li><span>Trending Keywords</span></li>
                                    <li><a routerLink="/jobs">Account Manager,</a></li>
                                    <li><a routerLink="/jobs">Administrative,</a></li>
                                    <li><a routerLink="/jobs">Android,</a></li>
                                    <li><a routerLink="/jobs">Angular,</a></li>
                                    <li><a routerLink="/jobs">appASP.NET</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="banner-img-two">
                    <img src="assets/img/home-two/banner-main.png" alt="Banner">
                    <img src="assets/img/home-two/banner1.png" alt="Banner">
                    <img src="assets/img/home-two/banner2.png" alt="Banner">
                    <img src="assets/img/home-two/banner3.png" alt="Banner">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner -->

<!-- Partner -->
<div class="support-area pb-100">
    <div class="container">
        <p>Over <span>1500.000</span> supporter all over the world</p>

        <div class="support-slider owl-theme owl-carousel">
            <div class="support-item">
                <img src="assets/img/home-two/support1.png" alt="Support">
            </div>
            
            <div class="support-item">
                <img src="assets/img/home-two/support2.png" alt="Support">
            </div>

            <div class="support-item">
                <img src="assets/img/home-two/support3.png" alt="Support">
            </div>

            <div class="support-item">
                <img src="assets/img/home-two/support4.png" alt="Support">
            </div>

            <div class="support-item">
                <img src="assets/img/home-two/support5.png" alt="Support">
            </div>

            <div class="support-item">
                <img src="assets/img/home-two/support6.png" alt="Support">
            </div>

            <div class="support-item">
                <img src="assets/img/home-two/support1.png" alt="Support">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->

<!-- Company -->
<section class="company-area two pb-100">
    <div class="container-fluid">
        <div class="section-title two">
            <span class="sub-title">Employers Offering Job</span>
            <h2>Company Offering Job</h2>
        </div>

        <div class="company-slider owl-theme owl-carousel">
            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company1.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Pi Agency</a></h3>
                    <span>Part Time Job</span>
                    <p><i class="flaticon-appointment"></i> 10 min ago / Austria, Vienna</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Pay Relocation Free</li>
                        <li>Remote Work</li>
                        <li>Duration: 5 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>50K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>
                
                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company2.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Kn It</a></h3>
                    <span>Permanent Job</span>
                    <p><i class="flaticon-appointment"></i> 9 min ago / Tirana, Albania</p>
                </div>
                
                <div class="bottom">
                    <ul>
                        <li>Graphic Designer</li>
                        <li>Remote Work</li>
                        <li>Duration: 2 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>56K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company3.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Orbit Inc.</a></h3>
                    <span>Part Time Job</span>
                    <p><i class="flaticon-appointment"></i> 8 min ago / Doha, Qatar</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Product Manager</li>
                        <li>Remote Work</li>
                        <li>Duration: 5 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>70K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company4.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Dev Roside</a></h3>
                    <span>Full Time Job</span>
                    <p><i class="flaticon-appointment"></i> 15 min ago / UK, England</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Design & Developer</li>
                        <li>Remote Work</li>
                        <li>Duration: 2 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>89K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company5.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Roshu.co</a></h3>
                    <span>Part Time Job</span>
                    <p><i class="flaticon-appointment"></i> 10 min ago / Cardiff, England</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Internet Operator</li>
                        <li>Remote Work</li>
                        <li>Duration: 2 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>66K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>
                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company6.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Omti. Med</a></h3>
                    <span>Part Time Job</span>
                    <p><i class="flaticon-appointment"></i> 40 min ago / Tokyo, Japan</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Caring Officer</li>
                        <li>Remote Work</li>
                        <li>Duration: 2 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>50K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company7.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Rahbar</a></h3>
                    <span>Full Time Job</span>
                    <p><i class="flaticon-appointment"></i> 7 min ago / Washington, US</p>
                </div>

                <div class="bottom">
                    <ul>
                        <li>Media Connector</li>
                        <li>Remote Work</li>
                        <li>Duration: 3 Years</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>87K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="company-item two">
                <div class="feature-top-right">
                    <span>Featured</span>
                </div>

                <div class="top">
                    <a routerLink="/employer-details"><img src="assets/img/home-one/company8.png" alt="Brand"></a>
                    <h3><a routerLink="/employer-details">Doblin. Fo</a></h3>
                    <span>Part Time Job</span>
                    <p><i class="flaticon-appointment"></i> 12 min ago / California, US</p>
                </div>
                
                <div class="bottom">
                    <ul>
                        <li>Private Officer</li>
                        <li>Remote Work</li>
                        <li>Duration: 1 Year</li>
                    </ul>
                    <span>Annual Salary</span>
                    <h4>50K</h4>
                    <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Company -->

<!-- Explore -->
<section class="explore-area two ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Be Found Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p>
            <ul>
                <li><a class="left-btn" routerLink="/">Upload Your Resume<i class="flaticon-upload"></i></a></li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
        <div class="explore-img"><img src="assets/img/home-two/explore-main.png" alt="Explore"></div>
    </div>
</section>
<!-- End Explore -->

<!-- Categories -->
<div class="categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="flaticon-placeholder"></i> Job By Location <i class="flaticon-right-arrow two"></i></a>

                    <a class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="flaticon-resume"></i> Jobs By Roles <i class="flaticon-right-arrow two"></i></a>

                    <a class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class="flaticon-pencil"></i> Jobs BySkills <i class="flaticon-right-arrow two"></i></a>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div class="row">
                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat1.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Sr. Shopify Developer</a></h3>
                                    <span>2 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat2.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Senior Data Engineer</a></h3>
                                    <span>1 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat3.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Construction Worker</a></h3>
                                    <span>5 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat4.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Product Designer</a></h3>
                                    <span>3 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat5.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Max manager</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat6.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Web Designer</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat7.png" alt="Cat">
                                    <h3><a routerLink="/job-details">UX/UI Designer</a></h3>
                                    <span>2 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat8.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Area Manager</a></h3>
                                    <span>1 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat9.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Sales Man</a></h3>
                                    <span>10 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat10.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Shop Caretaker</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat11.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Management Support</a></h3>
                                    <span>15 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat12.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Electric Technician</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <div class="row">
                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat1.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Sr. Shopify Developer</a></h3>
                                    <span>2 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat2.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Senior Data Engineer</a></h3>
                                    <span>1 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat3.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Construction Worker</a></h3>
                                    <span>5 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat4.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Product Designer</a></h3>
                                    <span>3 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat5.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Max manager</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat6.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Web Designer</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat7.png" alt="Cat">
                                    <h3><a routerLink="/job-details">UX/UI Designer</a></h3>
                                    <span>2 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat8.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Area Manager</a></h3>
                                    <span>1 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat9.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Sales Man</a></h3>
                                    <span>10 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div class="row">
                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat1.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Sr. Shopify Developer</a></h3>
                                    <span>2 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat2.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Senior Data Engineer</a></h3>
                                    <span>1 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat3.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Construction Worker</a></h3>
                                    <span>5 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat4.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Product Designer</a></h3>
                                    <span>3 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat5.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Max manager</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <div class="cat-item">
                                    <img src="assets/img/home-two/cat6.png" alt="Cat">
                                    <h3><a routerLink="/job-details">Web Designer</a></h3>
                                    <span>4 Job</span>
                                    <a class="link" routerLink="/job-details"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Categories -->

<!-- Work -->
<section class="work-area two pb-70">
    <div class="container">
        <div class="section-title two">
            <span class="sub-title">Working Process</span>
            <h2>See How It Works</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item two">
                    <i class="flaticon-accounting"></i>
                    <h3>Register An Account</h3>
                    <p>Lorem ipsum dolor sit amet conscu adiing elitsed do eusmod tempor incidunt utinto elit sed doe</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item two">
                    <i class="flaticon-file"></i>
                    <h3>Search Your Job</h3>
                    <p>Lorem ipsum dolor sit amet conscu adiing elitsed do eusmod tempor incidunt utinto elit sed doe</p>
                </div>
            </div>
            
            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item two work-border">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3>Apply For Job</h3>
                    <p>Lorem ipsum dolor sit amet conscu adiing elitsed do eusmod tempor incidunt utinto elit sed doe</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Jobs -->
<section class="job-area two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Desire Jobs</span>
                    <h2>Job You Will Love</h2>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="sorting-menu">
                    <ul> 
                        <li class="filter" data-filter="all">All</li>
                        <li class="filter" data-filter=".m">Internship</li>
                        <li class="filter" data-filter=".n">Full Time</li>
                        <li class="filter" data-filter=".o">Part Time</li>
                        <li class="filter" data-filter=".p">Remote</li>
                        <li class="filter" data-filter=".u">Freelancer</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="container" class="row">
            <div class="col-sm-6 col-lg-12 mix n">
                <div class="job-item">
                    <a routerLink="/job-details">
                        <div class="feature-top-right">
                            <span>Featured</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="assets/img/home-one/job1.png" alt="Brand">
                                    <h3>Product Designer</h3>
                                    <p>Digital Radio</p>
                                    <ul>
                                        <li>$500 - $1,000 /</li>
                                        <li>month /</li>
                                        <li><i class="flaticon-appointment"></i> 8 months ago</li>
                                        <li><i class='bx bx-location-plus'></i> Seoul, South Korea</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i> Full Time</li>
                                        <li><i class="flaticon-mortarboard"></i> Master</li>
                                        <li><span>Add to bookmarks</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-12 mix o">
                <div class="job-item">
                    <a routerLink="/job-details">
                        <div class="feature-top-right">
                            <span>Featured</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="assets/img/home-one/job2.png" alt="Brand">
                                    <h3>Construction Worker</h3>
                                    <p>Digital Vine</p>
                                    <ul>
                                        <li>$600 - $2,000 /</li>
                                        <li>month /</li>
                                        <li><i class="flaticon-appointment"></i> 6 months ago</li>
                                        <li><i class='bx bx-location-plus'></i> Kabul, Afghanistan</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i> Part Time</li>
                                        <li><i class="flaticon-mortarboard"></i> Master</li>
                                        <li><span>Add to bookmarks</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-12 mix m">
                <div class="job-item">
                    <a routerLink="/job-details">
                        <div class="feature-top-right">
                            <span>Featured</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="assets/img/home-one/job3.png" alt="Brand">
                                    <h3>Sr. Shopify Developer</h3>
                                    <p>Codex Info</p>
                                    <ul>
                                        <li>$400 - $1,500 /</li>
                                        <li>month /</li>
                                        <li><i class="flaticon-appointment"></i> 1 months ago</li>
                                        <li><i class='bx bx-location-plus'></i> Buenos Aires, Argentina</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i> Internship</li>
                                        <li><i class="flaticon-mortarboard"></i> Master</li>
                                        <li><span>Add to bookmarks</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-12 mix p">
                <div class="job-item">
                    <a routerLink="/job-details">
                        <div class="feature-top-right">
                            <span>Featured</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="assets/img/home-one/job4.png" alt="Brand">
                                    <h3>Tax Manager</h3>
                                    <p>Techno Vino</p>
                                    <ul>
                                        <li>$450 - $1,250 /</li>
                                        <li>month /</li>
                                        <li><i class="flaticon-appointment"></i> 4 months ago</li>
                                        <li><i class='bx bx-location-plus'></i> Vienna, Australia</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i> Remote</li>
                                        <li><i class="flaticon-mortarboard"></i> Master</li>
                                        <li><span>Add to bookmarks</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-12 mix u">
                <div class="job-item">
                    <a routerLink="/job-details">
                        <div class="feature-top-right">
                            <span>Featured</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="assets/img/home-one/job5.png" alt="Brand">
                                    <h3>Senior Data Engineer</h3>
                                    <p>Jarmin Poin</p>
                                    <ul>
                                        <li>$700 - $1,500 /</li>
                                        <li>month /</li>
                                        <li><i class="flaticon-appointment"></i> 2 months ago</li>
                                        <li><i class='bx bx-location-plus'></i> Tirana, Albania</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i> Freelancer</li>
                                        <li><i class="flaticon-mortarboard"></i> Master</li>
                                        <li><span>Add to bookmarks</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="job-browse">
            <p>A tons of top tech jobs are waiting for you. <a routerLink="/jobs">Browse all jobs</a></p>
        </div>
    </div>
</section>
<!-- End Jobs -->

<!-- Candidate -->
<section class="candidate-area pb-100">
    <div class="container">
        <div class="section-title two">
            <span class="sub-title">Working Process</span>
            <h2>Our Candidates</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                    <span>Web Developer</span>
                    <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate1.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>
            
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div>
        </div>

        <div class="candidate-browse">
            <p>Browse all talent <a routerLink="/candidates">Candidates</a></p>
        </div>
    </div>
</section>
<!-- End Candidate -->

<!-- Location -->
<section class="location-area pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="location-item">
                    <div class="top">
                        <a routerLink="/jobs"><img src="assets/img/home-one/location1.jpg" alt="Location"></a>
                    </div>
                    <span>8 Open Job</span>
                    <h3><a routerLink="/jobs">New York</a></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="location-item">
                    <div class="top">
                        <a routerLink="/jobs"><img src="assets/img/home-one/location2.jpg" alt="Location"></a>
                    </div>
                    <span>6 Open Job</span>
                    <h3><a routerLink="/jobs">Austria, Vienna</a></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="location-item">
                    <div class="top">
                        <a routerLink="/jobs"><img src="assets/img/home-one/location3.jpg" alt="Location"></a>
                    </div>
                    <span>2 Open Job</span>
                    <h3><a routerLink="/jobs">Tirana, Albania</a></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="location-item">
                    <div class="top">
                        <a routerLink="/jobs"><img src="assets/img/home-one/location4.jpg" alt="Location"></a>
                    </div>
                    <span>4 Open Job</span>
                    <h3><a routerLink="/jobs">Kabul, Afghanistan</a></h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Location -->

<!-- Testimonials -->
<section class="testimonial-area pb-100">
    <div class="container">
        <div class="section-title two">
            <span class="sub-title">Testimonials</span>
            <h2>What Our Customers Say About Us</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-item">
                <img src="assets/img/home-two/testimonial1.jpg" alt="Testimonial">
                <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                <h3>Devit M.Kolin</h3>
                <span>CEO & Founder</span>
            </div>

            <div class="testimonial-item">
                <img src="assets/img/home-two/testimonial2.jpg" alt="Testimonial">
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                <h3>Tom Henry</h3>
                <span>Director</span>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->

<!-- Blog -->
<section class="blog-area two pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Our Blogs</span>
                    <h2>Recent News Post</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="cmn-link">
                    <a routerLink="/blog"><i class="flaticon-right-arrow one"></i> Browse All Blogs <i class="flaticon-right-arrow two"></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                    </div>
                    <span>Job skills. 12-09-2020</span>
                    <h3><a routerLink="/blog-details">The Internet Is A Job Seeker Most Crucial Success </a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                    </div>
                    <span>Career advice. 11-09-2020</span>
                    <h3><a routerLink="/blog-details">Today From Connecting With Potential Employers</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                    </div>
                    <span>Future plan. 10-09-2020</span>
                    <h3><a routerLink="/blog-details">We’ve Weeded Through Hundreds Of Job Hunting</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->

<!-- Register -->
<div class="register-area">
    <div class="container">
        <div class="row">
            <div class="col-6 col-sm-4 col-lg-4">
                <div class="register-item">
                    <h3><span class="odometer" data-count="6789990">00</span> </h3>
                    <p>Registered Users</p>
                </div>
            </div>

            <div class="col-6 col-sm-4 col-lg-4">
                <div class="register-item">
                    <h3>
                        <span class="odometer" data-count="8098234">00</span> 
                    </h3>
                    <p>Global Employers</p>
                </div>
            </div>

            <div class="col-6 offset-4 offset-sm-0 col-sm-4 col-lg-4">
                <div class="register-item">
                    <h3><span class="odometer" data-count="3678890">00</span> </h3>
                    <p>Available Jobs</p>
                </div>
            </div>
        </div>

        <ul>
            <li><a href="#" target="_blank"><img src="assets/img/home-one/app1.png" alt="App"></a></li>
            <li><a href="#" target="_blank"><img src="assets/img/home-one/app2.png" alt="App"></a></li>
        </ul>
    </div>
</div>
<!-- End Register -->

<!-- Footer -->
<app-footer-style-one #footer></app-footer-style-one>
<!-- End Footer -->