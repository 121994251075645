<!-- Navbar -->
<div class="navbar-area fixed-top nav-main-dark">
  <div class="mobile-nav">
    <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo" /></a>
  </div>

  <div class="main-nav">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" class="logo" alt="Logo" /></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item" *ngIf="!isLoggedIn">
              <a href="javascript:void(0)" routerLink="/" class="nav-link" routerLinkActive="active"

                [routerLinkActiveOptions]="{ exact: true }" class="nav-link dropdown-toggle">Home</a>
            </li>
            
            <li class="nav-item">
              <a routerLink="/about" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">About</a>
            </li>

            <li class="nav-item">
              <a routerLink="/experts" class="nav-link dropdown-toggle" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Experts</a>
              <ul class="dropdown-menu">
                <li>
                  <a routerLink="/experts" class="nav-link dropdown-toggle" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Experts</a>
                </li>
                <li>
                  <a routerLink="/jobs" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Jobs</a>
                  <a routerLink="/courses" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">Courses</a>
                </li>
              </ul>
            </li>

            <!-- <li class="nav-item">
              <a routerLink="/career-surger-program" class="nav-link">Career Surger Program</a>
            </li> -->

            <!-- <li class="nav-item">
              <a routerLink="/blog" class="nav-link">Blog</a>
            </li> -->

            <!-- <li class="nav-item">
              <a routerLink="/events" class="nav-link">Events</a>
            </li> -->
            <!-- <ng-container *ngIf="!isLoggedIn">
              <li class="nav-item">
                <a routerLink="/for-businesses" class="nav-link">For Businesses</a>
              </li>
            </ng-container> -->
            
            <li class="nav-item">
              <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Contact</a>
            </li>
          </ul>

          <div class="side-nav">
            <!-- <a class="login-left" *ngIf="!isLoggedIn" [routerLink]="['/login', 'selection']"><i
                class="flaticon-enter"></i> Login/Register</a> -->
                <a class="login-left" *ngIf="!isLoggedIn" [routerLink]="['/login']"><i
                  class="flaticon-enter"></i> Login/Register</a>    
            
            <div class="" *ngIf="isLoggedIn" id="navbarSupportedContent">
              <ul class="navbar-nav d-block h-auto">
                <li class="nav-item">
                  <a routerLink="/dashboard" class="py-0 nav-link dropdown-toggle">
                    <img [src]="
                        this.user?.user_meta?.profile_pic ||
                        'assets/img/avatar.jpg'
                      " class="profile-thumb" />
                  </a>
                  <ul class="dropdown-menu" *ngIf="!isExpert || mode === 'switchUser'">
                    <li>
                      <a class="w-100" ngbDropdownItem (click)="switchToExpert()">Switch To Expert</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/dashboard" ngbDropdownItem>Dashboard {{mood}}</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/logout" ngbDropdownItem>Logout</a>
                    </li>
                  </ul>
                  <ul class="dropdown-menu" *ngIf="isExpert || mode === 'switchExpert'">
                    <li>
                      <a class="w-100" ngbDropdownItem (click)="switchToUser()">Switch To User</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/dashboard" ngbDropdownItem>Dashboard</a>
                    </li>
                    <li>
                      <a class="w-100" [routerLink]="['/experts', user.vanity_url || user.id]" ngbDropdownItem>Profile</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/dashboard/services" ngbDropdownItem>List your offerings</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/dashboard/job-requests" ngbDropdownItem>Job Requests</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/dashboard/payments-history" ngbDropdownItem>Payments</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/inbox" ngbDropdownItem>Inbox</a>
                    </li>
                    <li>
                      <a class="w-100" routerLink="/logout" ngbDropdownItem>Logout</a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item notifications">
                  <i class="bx bx-bell"> 
                    <span *ngIf="getUnseenNotificationsCount(notificationsList) > 0" class="hasUnseen">{{ getUnseenNotificationsCount(notificationsList) }}</span>
                  </i>
                  <div class="notification-popup" >
                      <div class="row mt-2">
                          <div class="col-sm-10"><h3>Notifications</h3></div>
                          <!-- <div class="col-sm-2"><img [src]="'assets/img/threeDot.png'" class="" /></div> -->
                      </div>
                      <div class="row mb-2" *ngIf="!notificationsList.length">
                          <div class="col-sm-12" >No New Notifications</div>
                       </div>
                       
                    <!-- <p *ngIf="!notificationsList.length">
                       demo
                    </p> -->
                    <a [ngClass]="notification.is_seen == 0 || notification.is_seen == '0' ? 'notification' : 'notification seen'" 
                    *ngFor="let notification of notificationsList" 
                      >
                      <!-- (click)="openNotification(notification)" -->
                      <div class="noti_title">{{ notification.title }}</div>
                      <div class="noti_date">{{ getTimeDifference(notification.created_at) }}</div>
                      
                      <p style="display: block;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;">
                        {{filterDescription(notification.description)}}</p>
                    </a>
                  </div>
                </li>
                <li class="nav-item notifications inbox_notifications">
                  <i class="bx bx-bell-new"> 
                    <span *ngIf="getUnseenInboxNotificationsCount(inboxNotificationsList) > 0" class="hasUnseen">{{ getUnseenInboxNotificationsCount(inboxNotificationsList) }}</span>
                  </i>
                  <div class="notification-popup">
                    <div class="row mt-2">
                      <div class="col-sm-10"><h3>Message</h3></div>
                      <!-- <div class="col-sm-2"><img [src]="'assets/img/threeDot.png'" class="" /></div> -->
                  </div>
                  <div class="row mb-2" *ngIf="!inboxNotificationsList.length">
                      <div class="col-sm-12" >No New Message</div>
                   </div>
                    <!-- <p *ngIf="!inboxNotificationsList.length">
                      No New Message
                    </p> -->
                    <a [ngClass]="notification.is_seen == 0 || notification.is_seen == '0' ? 'notification' : 'notification seen'" 
                    *ngFor="let notification of inboxNotificationsList" (click)="openNotification(notification)">
                  
                      <!-- (click)="openNotification(notification)"> -->
                      <h5>{{ notification.title }}</h5>
                      <p style="display: block;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;">
                        {{filterDescription(notification.description)}}</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar -->

<ng-template #viewNotification let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{notification.title}}
    </h4>
  </div>
  <div class="modal-body tab-content dashboard-area">
    <div [innerHtml]="sanitizeDescription(notification.description)"></div>
  </div>
</ng-template>


<div style="display: none !important;" class="nav_notification_update">
  <li class="nav-item notifications">
    <i class="bx bx-bell"> 
      <span *ngIf="getUnseenNotificationsCount(notificationsList) > 0" class="hasUnseen">{{ getUnseenNotificationsCount(notificationsList) }}</span>
    </i>
    <div class="notification-popup">
      <p *ngIf="!notificationsList.length" style="padding: 5px 15px 7px; margin:0;">
        No New Notifications
      </p>
      
      <a [ngClass]="notification.is_seen == 0 || notification.is_seen == '0' ? 'notification open_notification_btn' : 'notification seen open_notification_btn'" 
      *ngFor="let notification of notificationsList"
      href="{{this.isExpert 
      ? env.applicationBaseUrl + 'dashboard/job-requests?call_id='+notification.call_id 
      : env.applicationBaseUrl + 'dashboard?call_id='+notification.call_id}}"  
        >
        <input type="hidden" value="{{notification.is_seen}}" class="isSeen_input">
        <input type="hidden" value="{{notification.id}}" class="notId_input">
        <input type="hidden" value="{{notification.user_id}}" class="userId_input">
        <input type="hidden" value="{{ env.apiBaseUrl}}" class="api_url_input">
        <h5>{{ notification.title }}</h5>
        <p style="display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;">
          {{filterDescription(notification.description)}}</p>
      </a>
    </div>
  </li>
  <li class="nav-item notifications inbox_notifications">
    <i class="bx bx-bell-new"> 
      <span *ngIf="getUnseenInboxNotificationsCount(inboxNotificationsList) > 0" class="hasUnseen">{{ getUnseenInboxNotificationsCount(inboxNotificationsList) }}</span>
    </i>
    <div class="notification-popup">
      <p *ngIf="!inboxNotificationsList.length">
        No New Message
      </p>
      <a [ngClass]="notification.is_seen == 0 || notification.is_seen == '0' ? 'notification open_notification_btn' : 'notification seen open_notification_btn'" 
      *ngFor="let notification of inboxNotificationsList"
      href="{{env.applicationBaseUrl + 'inbox'}}">
        <input type="hidden" value="{{notification.is_seen}}" class="isSeen_input">
        <input type="hidden" value="{{notification.id}}" class="notId_input">
        <input type="hidden" value="{{notification.user_id}}" class="userId_input">
        <input type="hidden" value="{{ env.apiBaseUrl}}" class="api_url_input">
        <h5>{{ notification.title }}</h5>
        <p style="display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;">
          {{filterDescription(notification.description)}}</p>
      </a>
    </div>
  </li>
</div>