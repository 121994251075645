import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  jobs: any;
  pagination: any = {
    from: 0,
    per_page: 0,
    current_page: 0,
    total: 0,
  };
  filters: any = {};
  expert: any = [];
  courses: any = [];
  selectedJob: any = "";
  headers: any = {
    hideLoader: false,
  };

  filterOptions: any = [];

  redirectTo = (): void => {
    window.open(this.selectedJob);
  };

  constructor(
    private _api: APIServiceService,
    private _route: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe((data) => {
      this.filters = { ...data };

      this.getJobs();
    });

    this._api.getFilters({ type: "jobs" }).subscribe((data) => {
      this.filterOptions = data.data;
    });
  }

  getJobs(page = 1) {
    const filterKeys = Object.keys(this.filters);
    let filters = {};
    filterKeys.forEach((key) => {
      this.filters[key].length
        ? (filters[key] = JSON.stringify(this.filters[key]))
        : delete filters[key];
    });

    this._api.getJobs({ page, ...filters }, this.headers).subscribe((data) => {
    
      this.jobs = data.data.jobs.data;
      this.expert = data.data.expert;
      this.courses = data.data.courses;
      this.pagination = {
        from: data.data.jobs.from,
        per_page: data.data.jobs.per_page,
        current_page: data.data.jobs.current_page,
        total: data.data.jobs.total,
      };
      this.headers.hideLoader = true;
    });
  }

  tags(tags) {
    const tagsArray = tags.split(",");
    let html = "";

    tagsArray.forEach((tag) => {
      html += `<span class="span-one mr-2">${tag}</span>`;
    });

    return html;
  }

  filtersFormatting(event) {
    let newFilter = {};

    if (event.target.name == "keywords") {
      newFilter["keywords"] = event.target.value;
    } else {
      if (this.filters.hasOwnProperty(event.target.name)) {
        const index = this.filters[event.target.name].indexOf(
          event.target.value
        );

        if (index === -1) {
          this.filters[event.target.name].push(event.target.value);
        } else {
          this.filters[event.target.name].splice(index, 1);
        }
      } else {
        newFilter = {
          [event.target.name]: [event.target.value],
        };
      }
    }

    return newFilter;
  }

  refineSearch(event) {
    let newFilter = this.filtersFormatting(event);

    this.filters = { ...this.filters, ...newFilter };

    setTimeout(
      () => {
        this.getJobs(1);
      },
      event.target.name == "keywords" ? 500 : 0
    );
  }

  contactForm(content, url) {
    this.selectedJob = url;

    this.modalService.open(content, { centered: true, size: "lg" });
  }

  stringTolower(value) {
    return value.toLowerCase();
  }
}
