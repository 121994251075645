import { Component, OnInit , ViewChild , ElementRef, AfterViewInit} from '@angular/core';
// import { appSrvice } from "src/app/AppService/app.service";
import {AppService} from "src/app/app.service";
@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit , AfterViewInit {

  fotter: any = {};

  constructor(
    private appService: AppService,
  ) { }

  @ViewChild('footer', { static: true }) footerRef: ElementRef;

  ngAfterViewInit(): void {
    const footerHeight = this.footerRef.nativeElement.offsetHeight;
    this.fotter = this.appService.setFooterhight(footerHeight);
   

  }
  ngOnInit(): void {
  }

}
