import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(
    private _api: APIServiceService,
    private _user: UserService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._api.logout().subscribe((data) => {
      this._user.resetUserSession();
      localStorage.removeItem('mode');
      setTimeout(() => {
        location.replace("login");
      }, 300);
    });
  }
}
