<!-- Register -->
<div class="user-form-area">
  <div class="container-fluid p-0">
    <div class="row m-0 align-items-center">
      <div class="col-lg-6 p-0 col-md-12">
        <div class="user-img">
          <img src="assets/img/resgister-bg.jpg" alt="User" />
        </div>
      </div>

      <div class="col-lg-6 p-0 col-md-12">
        <div class="user-content">
          <div class="top">
            <h2>{{ formTitle }}</h2>

            <form [formGroup]="registerForm" (submit)="register()">
              <div class="form-group">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control"
                  placeholder="{{ isAnonymous ? 'Anonymous Name' : 'First Name' }}"
                  (input)="input$.next($event.target.value)"
                />
                <p *ngIf="formValidator().name" class="registerSpecing">
                  {{ isAnonymous ? 'Invalid Name or already in use' : 'Invalid Name' }}
                  
                </p><p *ngIf="!formValidator().name" class="registerSpecing">&nbsp;</p>
                <!-- <div class="">
                  <input
                    type="checkbox"
                    (click)="toggleIsAnonymous()"
                    [checked]="isAnonymous"
                    id="isAnonymous"
                    class="m-1"
                  /><label for="isAnonymous">Register as Anonymous</label>
                </div> -->
                
                <!-- trigger anonymous name -->
                <pre *ngIf="isAnonymous"
                style="display: none !important; opacity: 0; width: 0; height: 0;" > {{codigosPonValue$ | async}} </pre>
                <div class="anonymous-name" *ngIf="isAnonymous && anonymusNames?.length > 0">
                  <span style="color: #000;">Username already in use.</span>
                  <span
                    *ngFor="let name of anonymusNames"
                    (click)="setUserName(name)"
                    >{{ name }}</span
                  >
                </div>
              </div>

              <div class="form-group">
                <input
                  type="text"
                  formControlName="lname"
                  class="form-control"
                  placeholder="Last Name"
                />
                <p *ngIf="formValidator().lname" class="registerSpecing">
                  Invalid Last Name
                </p>
                <p *ngIf="!formValidator().lname" class="registerSpecing">&nbsp;</p>
              </div>

              <!-- <div class="form-group" *ngIf="formTitle === 'Expert Registration'">
                <input
                  type="text"
                  formControlName="company"
                  class="form-control"
                  placeholder="Company Name"
                />
                <p *ngIf="formValidator().company" class="invalid-feedback">
                  Invalid Company Name
                </p>
              </div>

              <div class="form-group" *ngIf="formTitle === 'Expert Registration'">
                <input
                  type="text"
                  formControlName="role"
                  class="form-control"
                  placeholder="Current Role"
                />
                <p *ngIf="formValidator().role" class="invalid-feedback">
                  Invalid Current Role
                </p>
              </div> -->
              <!-- <div class="row">
                <div class="col-md-5">
                    <div class="form-group dateCustome" *ngIf="formTitle === 'Expert Registration'">
                      <label for="startYear">Start Year</label>
                      <input
                        type="date"
                        formControlName="start_year"
                        class="form-control"
                        placeholder="start Year"
                      />
                      <p *ngIf="formValidator().start_year" class="invalid-feedback">
                        Invalid Current Role
                      </p>
                    </div>
                 </div>
                  <div class="col-md-5">
                      <div class="form-group dateCustome" *ngIf="formTitle === 'Expert Registration'">
                        <label for="endYear">End Year</label>
                        <input
                          type="date"
                          formControlName="end_year"
                          class="form-control"
                          placeholder="End Year"
                        />
                        <p *ngIf="formValidator().end_year" class="invalid-feedback">
                          Invalid Current Role
                        </p>
                      </div>
                  </div>
                  <div class="col-md-2">
                      <div class="form-group dateCustome" *ngIf="formTitle === 'Expert Registration'">
                        <label for="till">Till Now</label>
                        <div>
                             <input class="form-check-input till" type="checkbox" id="check1" formControlName="till"  value="" >
                             <p *ngIf="formValidator().till" class="invalid-feedback">
                              Invalid till
                            </p>
                        </div>
                        
                      </div>
                  </div>
              </div> -->
              <div class="form-group">
                <input
                  type="email"
                  formControlName="email"
                  class="form-control"
                  placeholder="Email"
                />
                <p *ngIf="formValidator().email" class="registerSpecing">
                  Invalid Email
                </p>
                <p *ngIf="!formValidator().email" class="registerSpecing">&nbsp;</p>
              </div>

              <div class="form-group">
                <input
                  type="password"
                  formControlName="password"
                  class="form-control"
                  placeholder="Password"
                />
                <p *ngIf="formValidator().password" class="registerSpecing">
                  Invalid Password
                </p>
                <p *ngIf="!formValidator().password" class="registerSpecing">&nbsp;</p>
              </div>

              <div class="form-group">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  formControlName="confirmPassword"
                />
                <p
                  *ngIf="formValidator().confirmPassword"
                  class="invalid-feedback registerSpecing"
                >
                  Invalid Confirm Password
                </p>
                <p *ngIf="!formValidator().confirmPassword" class="registerSpecing">&nbsp;</p>
              </div>
              
              <button
                type="submit"
                [disabled]="isRegisterDisable() || loaderService.isLoading"
                class="btn"
              >
                <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                <span *ngIf="!loaderService.isLoading">Register Here</span>                
              </button>
              <a class="create-account" routerLink="/login"
                >Already Have an Account</a
              >
            </form>
          </div>

          <app-social-login></app-social-login>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Register -->
