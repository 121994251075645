import { Component, OnInit } from "@angular/core";

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

// Services
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { UserService } from "src/app/services/UserService/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isLoggedIn: boolean = false;
  loginForm: FormGroup;
  isPasswordReset: Boolean = false;

  constructor(
    private _forms: FormBuilder,
    private _api: APIServiceService,
    private _user: UserService,
    private _route: Router,
    public loaderService: LoaderService,
    private _notifier: NotifierService,
  ) {
    this.isLoggedIn = !!this._user.getUserToken();
    this.loginForm = this._forms.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {}

  login() {
    localStorage.setItem('mode', 'switchUser');
    const formData = this.loginForm.getRawValue();
    
    if(this.isPasswordReset){
      this._api.resetPassword({email: formData.email}).subscribe((data) =>{
        this._notifier.showNotification({
          type: "success",
          message: data.message,
        });
        this.loginForm.reset();
        this.resetPassword();
      });
    }else{
      this._api.login(formData).subscribe((data) => {
        this._user.createUserSession(data.data);
        location.replace("dashboard");
      });
    }
  }

  resetPassword() {
    this.isPasswordReset = !this.isPasswordReset;
  }
}
