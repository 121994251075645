import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

// Services
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { UserService } from "src/app/services/UserService/user.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { of, BehaviorSubject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  formTitle: any = "Register";
  userType: any = 0;
  isAnonymous: boolean = false;

  anonymusNames: any = [];
  readonly input$ = new BehaviorSubject('');

  constructor(
    private _forms: FormBuilder,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    private _user: UserService,
    private _router: ActivatedRoute,
    public loaderService: LoaderService,
  ) {
    this._router.data.subscribe((data) => {
      this.formTitle = data.form_title ?? this.formTitle;
      this.userType = data.user_type ?? this.userType;
    });
    
    
    this.registerForm = this._forms.group({
      name: new FormControl("", [Validators.required]),
      lname: new FormControl("", [Validators.required]),
      company: new FormControl(""),
      role: new FormControl(""),
      start_year: new FormControl(""),
      end_year: new FormControl(""),
      till: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
      user_type: new FormControl(this.userType),
      formTitle:new FormControl(this.formTitle),
    });
  }

  ngOnInit(): void {}

  toggleIsAnonymous() {
    this.setUserName('');
    this.isAnonymous = !this.isAnonymous;
    if(!this.isAnonymous){
      this.anonymusNames = [];
    }
    // if (this.isAnonymous) {
    //   this._api.getAnonymousNames().subscribe((result) => {
    //     this.anonymusNames = result.data.map((name) => {
    //       return name.name.replace(/[^\w\s]|\s/gi, "").substr(4, 10);
    //     });
    //   });
    // }
  }

  register() {
    this._api.register(this.registerForm.getRawValue()).subscribe((data) => {
      this._notifier.showNotification({
        type: "success",
        message: data.message,
      });
      this._user.createUserSession(data.data);

      setTimeout(() => {
        setTimeout(() => {
          location.replace("dashboard");
        }, 500);
      }, 200);
    });
  }

  get f() {
    const formControl = this.registerForm.controls;

    return formControl;
  }

  formValidator() {
    const {
      controls: { name,lname,company,start_year,end_year,till, email, password, confirmPassword },
    } = this.registerForm;
    return {
      name: name.status == "INVALID" && name.touched == true || (this.anonymusNames?.length > 0 && this.isAnonymous),
      lname: lname.status == "INVALID" && lname.touched == true || (this.anonymusNames?.length > 0 && this.isAnonymous),
      company: company.status == "INVALID" && company.touched == true ,
      start_year: start_year.status == "INVALID" && start_year.touched == true ,
      end_year: start_year.status == "INVALID" && end_year.touched == true ,
      till: till.status == "INVALID" && till.touched == true ,
      email: email.status == "INVALID" && email.touched == true,
      password: password.status == "INVALID" && password.touched == true,
      confirmPassword:
        (confirmPassword.status == "INVALID" &&
          confirmPassword.touched == true) ||
        password.value != confirmPassword.value,
    };

  }

  isRegisterDisable() {
    if (this.registerForm.status === "INVALID") return true;

    if (
      this.registerForm.controls.confirmPassword.value !=
      this.registerForm.controls.password.value
    )
      return true;

    return false;
  }

  readonly codigosPonValue$ = this.input$.pipe(
    debounceTime(250),
  switchMap(input => {
    if (input.length) {
      this._api.getAnonymousNames({name: this.registerForm.controls.name.value}).subscribe((result) => {
        this.anonymusNames = result.data;
      });
    } else {
      return of([]);
    }
  })
  );

  setUserName(name) {
    this.registerForm.controls.name.setValue(name);
    this.anonymusNames = [];
  }
}
