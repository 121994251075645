import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";

@Component({
  selector: "app-resume-requests",
  templateUrl: "./resume-requests.component.html",
  styleUrls: ["./resume-requests.component.scss"],
})
export class ResumeRequestsComponent implements OnInit {
  requests: any = [];
  manageAppointment: FormGroup;
  pagination: any = {};
  selectedCall: any = 0;

  filters: any = {
    page: 1,
    "type[]": "resume",
  };

  @ViewChild("viewRequest") viewRequest;
  constructor(
    private _api: APIServiceService,
    private _form: FormBuilder,
    private _notifier: NotifierService,
    private modalService: NgbModal,
    public loaderService: LoaderService,
  ) {
    this.getRequests();

    this.manageAppointment = this._form.group({
      selected_time: new FormControl(),
      meeting_url: new FormControl(),
      status: 1,
    });
  }

  ngOnInit(): void {}

  getRequests() {
    this._api.getSchedulesJobs(this.filters).subscribe((data) => {
      const {
        data: { data: jobRequests, total, per_page, current_page },
      } = data;
      this.requests = jobRequests.map((request) => {
        return { ...request, meta: JSON.parse(request.meta) };
      });

      this.pagination = {
        total,
        current_page,
        per_page,
      };
    });
  }

  getMeetingStatus(status: any, type = null) {
    if(type == "resume"){
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "On Going";
        case "2":
          return "Completed";
        case "3":
          return "Approved";
        default :
          return "Completed";
      }
    }else{
      switch (status.toString()) {
        case "0":
          return "New";
        case "1":
          return "Scheduled";
        case "2":
          return "On Going";
        case "3":
          return "Completed";
        case "4":
          return "Approved";
        case "-1":
          return "Requested";
        default:
          return "Completed";
      }
    }
  }

  updateAppointment(call_id) {
    const formData = this.manageAppointment.getRawValue();
    formData.id = call_id;

    this._api.updateSchedulesJobs(formData).subscribe((data) => {
      this._notifier.showNotification({ message: data.data, type: "success" });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  timeOptionsToArray(options) {
    let timeOptions = [];
    for (var option in options) {
      timeOptions.push(options[option]);
    }

    return timeOptions.filter((option) => {
      return option;
    });
  }

  isOrderSuccess(status) {
    return status == "success";
  }

  filtersFormatting(event) {
    let newFilter = { ...this.filters };

    if (newFilter.hasOwnProperty(event.target.name)) {
      const index = newFilter[event.target.name].indexOf(event.target.value);

      if (index === -1) {
        newFilter[event.target.name].push(event.target.value);
      } else {
        newFilter[event.target.name].splice(index, 1);
      }
    } else {
      newFilter = {
        [event.target.name]: [event.target.value],
      };
    }

    return newFilter;
  }

  refineSearch(event) {
    let newFilter = this.filtersFormatting(event);

    this.filters = { ...this.filters, ...newFilter };

    setTimeout(() => {
      this.getRequests();
    }, 200);
  }

  changePange(page) {
    this.filters = { ...this.filters, page };

    this.getRequests();
  }

  viewCallModal() {
    this.modalService.open(this.viewRequest, { centered: true });
  }
}
