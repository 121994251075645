import { Component, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {
  enquiryForm: FormGroup;
  @Input() openUrl: any = () => {};
  @Input() isContact: Boolean = true;

  constructor(
    private _form: FormBuilder,
    private _api: APIServiceService,
    private _notifier: NotifierService,
    public loaderService: LoaderService,
  ) {
    this.enquiryForm = this._form.group({
      name: new FormControl(),
      email: new FormControl(),
      phone_no: new FormControl(),
      skills: new FormControl(),
      required_service: new FormControl(),
      career_help: new FormControl(),
    });
  }

  ngOnInit(): void {}

  postEnquiry() {
    const formData = this.enquiryForm.getRawValue();
    this._api.enquiry(formData).subscribe((data) => {
      this._notifier.showNotification({
        message: data.message,
        type: "success",
      });
      this.enquiryForm.reset();
      
      this.openUrl()
    });

  }

  updateCheckBoxes(name, value) {
    this.enquiryForm.controls[name].setValue(value);
  }
}
