import { A } from "@angular/cdk/keycodes";
import { Component, OnInit } from "@angular/core";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";

@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.scss"],
})
export class PaymentHistoryComponent implements OnInit {
  payments: any = [];
  totalpayment: Number = 0;
  pagination: any = {};
  params: {
    page: 1;
  };

  constructor(private _api: APIServiceService) {
    this.getPaymentsHistory();
  }

  getPaymentsHistory() {
    this._api.getPaymentsHistory(this.params).subscribe((data) => {
      const {
        data: { data: payments, total, per_page, current_page },
      } = data;
      this.payments = payments;

      this.pagination = {
        total,
        current_page,
        per_page,
      };

      this.totalAmount();
    });
  }

  changePange(page) {
    this.params = { ...this.params, page };

    this.getPaymentsHistory();
  }

  callAmount(notes) {
    const parsedNotes = JSON.parse(notes);

    return parseInt(parsedNotes?.amount);
  }

  totalAmount() {
    let totalAmount = 0;
    let i = 0;

    while (i < this.payments.length) {
      totalAmount += this.callAmount(this.payments[i].notes);

      i++;
    }

    this.totalpayment = totalAmount;
  }

  refineSearch(event) {
    const {
      target: { name, value },
    } = event;

    this.params = { ...this.params, [name]: value };

    this.getPaymentsHistory();
  }

  ngOnInit(): void {}
}
