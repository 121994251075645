import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { LoaderService } from "src/app/services/LoaderService/loader.service";
import { NotifierService } from "src/app/services/NotifierService/notifier-service.service";
import { UserService } from "src/app/services/UserService/user.service";

@Component({
  selector: "app-services-form",
  templateUrl: "./services-form.component.html",
  styleUrls: ["./services-form.component.scss"],
})
export class ServicesFormComponent implements OnInit {
  @Input() closeModal: () => {};
  @Input() isModal: boolean = false;

  services: any = [
    {
      service_name: "Schedule Call",
      service_type: "call",
      description:
        "Consult about setting your career path/strategy, create study plan/ learning path,  learning tips and tricks,interview preparation, etc.",
      charges: "",
      status: "inActive",
      isDefault: true,
    },
    {
      service_name: "Review Resume",
      service_type: "resume",
      description:
        "Having a good resume on hand when going on the job hunt is crucial, and will make your search a lot easier. In this service, a mentor with hiring experience will go over your CV and give you actionable points on how to improve it.He will also refer you to any job postings he can.",
      charges: "",
      status: "inActive",
      isDefault: true,
    },
    {
      service_name: "Get Trained",
      service_type: "training",
      description: "",
      charges: "",
      status: "inActive",
      isDefault: true,
    },
    {
      service_name: "Message Me",
      service_type: "message",
      description: "Ask me any anything and I'll revert with a detailed answer",
      charges: "",
      status: "active",
      isDefault: true,
    },
  ];
  defaultServiceTypes: any = ["call", "resume", "training", "message"];
  selectedService: any = 0;

  serviceForm: any = {};

  constructor(
    private _api: APIServiceService,
    private _notifier: NotifierService,
    private _user: UserService,
    private _router: Router,
    public loaderService: LoaderService,
  ) {
    this.getServices();
  }

  mergeArrayObjects(data) {
    let i = 0;
    var savedServices = [];
    var cusServices = [];

    while (i < data.length) {
      var s = 0;
      while (s < this.services.length) {
        if (
          data[i].service_type == this.services[s].service_type &&
          data[i].service_type != "custom"
        ) {
          savedServices[i] = { ...this.services[s], ...data[i] };
          this.services[s] = { ...this.services[s], ...data[i] };
        }        
        s++;
      }

      if (data[i].service_type == "custom") 
      {
        cusServices.push(data[i]);
      }

      i++;
    }

    const customServices = data.filter((service) => {
      if (
        service.service_type == "custom" &&
        !this.checkIfServicePushed(service)
      ) {
        return service;
      }
    });

    savedServices = [...savedServices, ...customServices];
    this.services = [ ...this.services, ...customServices ];
    this.services['new'] = {
        service_name: "",
        service_type: "custom",
        description: "",
        charges: "",
        status: "inActive",
    };

    this._user.updateUserSession({
      ...this._user.getUserSession,
      services: savedServices,
    });
  }

  checkIfServicePushed(serviceToCheck) {
    return this.services.find((service) => {
      return service.id == serviceToCheck.id;
    });
  }

  getServices() {
    this._api.getExpertsServices().subscribe((data) => {

      if(data && data?.data.length > 0){
        this.mergeArrayObjects(data.data);
      }

      this.serviceForm = this.services[this.selectedService];

      if (this.isModal && data.data.length > 2) {
        this.closeModal();
      }
    });
  }

  ngOnInit(): void {}

  selectService(index) {
    this.selectedService = index;
    this.serviceForm = this.services[index];
  }

  isActive(status) {
    return status.toLowerCase() == "active";
  }

  updateCall() {
    const formData = this.serviceForm;
    delete formData["isDefault"];

    if (!this.services[this.selectedService]?.id) {
      this._api.createService(formData).subscribe((data) => {
        this._notifier.showNotification({
          type: "success",
          message: data.message,
        });
        // this.mergeArrayObjects(data.data);
        this.getServices();
      });
    } else {
      this._api.updateService(formData).subscribe((data) => {
        this._notifier.showNotification({
          type: "success",
          message: data.message,
        });

        this.services[this.selectedService] = formData;
      });
    }
  }

  handleFormInput(event) {
    const { name, value } = event.target;

    this.serviceForm = { ...this.serviceForm, [name]: value };
  }

  isHourlyDisabled() {
    if (this.selectedService == "new") {
      return false;
    }

    if (this.services[this.selectedService]?.service_type == "custom") {
      return false;
    }

    return true;
  }
}
