import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { APIServiceService } from "src/app/services/APIService/apiservice.service";
import { UserService } from "src/app/services/UserService/user.service";


@Component({
  selector: "app-candidates",
  templateUrl: "./candidates.component.html",
  styleUrls: ["./candidates.component.scss"],
})
export class CandidatesComponent implements OnInit {
  expertsList: any = [];
  paginationData: any = {};
  filters: any = {};
  filterOptions: any = [];
  loading: boolean = false;
  user: any = {};

  constructor(
    private _user: UserService,
    private _api: APIServiceService,
    private _route: ActivatedRoute,
   
  ) {
    this._route.queryParams.subscribe((data) => {
      this.filters = data;
      this.getRecords();
    });

    
  }

  ngOnInit(): void {
    this._api.getFilters({ type: "experts" }).subscribe((data) => {
      this.filterOptions = data.data;
    });
  }

  getSkills(skills: any) {
   if(skills){
      return skills.replaceAll('"', '').replaceAll("[", '').replaceAll("]", '');
    }
  }

  getRecords(page = 1) {
    this.loading = true;
    let params = { page: page };
    for (const [key, value] of Object.entries(this.filters)) {
      if (Array.isArray(value)) {
        params[key] = value.join(",");
      } else {
        params[key] = value;
      }
    }

    this._api.expertsList(params).subscribe((data) => {
      this.user = this._user.getUserSession();
      const userId = this.user.id;
      const expertsData = data.data.data;
      this.expertsList = expertsData.filter(expert => expert.id !== userId);
      
      
      this.paginationData = {
        from: data.data.from,
        to: data.data.to,
        per_page: data.data.per_page,
        current_page: data.data.current_page,
        total: data.data.total,
      };
      this.loading = false;
    });
  }

  filtersFormatting(event) {
    let newFilter = {};

    if (event.target.name == "keywords") {
      newFilter["keywords"] = event.target.value;
    } else {
      if (this.filters.hasOwnProperty(event.target.name)) {
        const index = this.filters[event.target.name].indexOf(
          event.target.value
        );

        if (index === -1) {
          this.filters[event.target.name].push(event.target.value);
        } else {
          this.filters[event.target.name].splice(index, 1);
        }
      } else {
        newFilter = {
          [event.target.name]: [event.target.value],
        };
      }
    }

    return newFilter;
  }

  refineSearch(event) {
    let newFilter = this.filtersFormatting(event);
    this.filters = { ...this.filters, ...newFilter };
    setTimeout(
      () => {
        this.getRecords(1);
      },
      event.target.name == "keywords" ? 500 : 0
    );
  }

  stringTolower(value) {
    return value.toLowerCase();
  }

  filterCategoryFilter(filter) {
    return filter.replace("_", " ");
  }
}
