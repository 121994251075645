<app-breadcrumbs
  pageTitle="Scheduled Calls"
  [path]="[
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Calls', url: '/dashboard/calls' }
  ]"
></app-breadcrumbs>
<div class="dashboard-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-sm-3 filters">
        <h4>Filters</h4>
        <hr />
        <div>
          <h6>Filter by Status</h6>
          <hr />
          <ul class="list-unstyled">
            <li>
              <input
                type="checkbox"
                value="0"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Pending
            </li>
            <li>
              <input
                type="checkbox"
                value="1"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Scheduled
            </li>
            <li>
              <input
                type="checkbox"
                value="3"
                name="status[]"
                (change)="refineSearch($event)"
              />
              completed
            </li>
            <li>
              <input
                type="checkbox"
                value="4"
                name="status[]"
                (change)="refineSearch($event)"
              />
              Approved
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9">
        <ngb-accordion [closeOthers]="true" activeIds="static-0">
          <ngb-panel
            *ngFor="let call of scheduledCalls; let i = index"
            id="static-{{ i }}"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button ngbPanelToggle class="w-100 border-0 bg-transparent">
                <div
                  class="accordion-button bg-transparent custom-header"
                  [class.collapsed]="!opened"
                >
                  <div
                    class="call-user-profile d-flex align-items-center justify-content-between w-100"
                  >
                    <div>
                      <p class="m-0">
                        <img
                          [src]="call.profile_pic || 'assets/img/avatar.jpg'"
                        />
                        {{ call.user_name }}
                      </p>
                    </div>

                    <span>
                      <span
                        >Call Status: {{ getMeetingStatus(call.status, call.type) }}</span
                      >
                      <button class="btn text-danger">
                        <i class="bx bx-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <form
                [formGroup]="manageAppointment"
                (submit)="updateAppointment(call.id)"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <label for="">Name</label>
                    <p>{{ call.user_name }}</p>
                  </div>

                  <div class="col-sm-6">
                    <label for="">Email</label>
                    <p>{{ call.user_email }}</p>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label
                        >Select Time ({{
                          call.meta.selected_time | date: "medium"
                        }})</label
                      >
                      <div
                        class="form-check"
                        *ngFor="
                          let time of timeOptionsToArray(call.meta.time_options)
                        "
                      >
                        <input
                          type="radio"
                          formControlName="selected_time"
                          [value]="time"
                          *ngIf="time"
                          [id]="time"
                        />&nbsp;
                        <!-- [checked]="time == call.meta.selected_time" -->
                        <label [for]="time">{{ time | date: "medium" }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Meeting URL</label>
                      <input
                        type="url"
                        formControlName="meeting_url"
                        class="form-control"
                        value="{{ call.meta.meeting_url }}"
                      />
                      <p>
                        <a target="_blank" [href]="call.meta.meeting_url">
                          {{ call.meta.meeting_url }}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Meeting Status</label>
                      <p *ngIf="!isExpert">
                        {{ getMeetingStatus(call.status, call.type) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Payment Status</label>
                      <p>{{ call.order_status }}</p>
                    </div>
                  </div>
                </div>

                <p
                  class="sub-title text-danger"
                  *ngIf="!isOrderSuccess(call.order_status)"
                >
                  Payment not completed yet.
                </p>

                <button
                  class="btn btn-primary float-right mb-2 mr-2"
                  type="button"
                  (click)="selectedCall = i; viewCallModal()"
                >
                  View Call
                </button>

                <button
                  class="btn btn-primary float-right mb-2"
                  type="submit"
                  disabled="{{ isOrderSuccess(call.order_status) }} || loaderService.isLoading"
                >
                  <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                  <span *ngIf="!loaderService.isLoading">Schedule Call</span>
                </button>
              </form>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

        <div class="pagination-area mt-2">
          <ngb-pagination
            [collectionSize]="pagination.total"
            [(page)]="pagination.current_page"
            [maxSize]="3"
            [pageSize]="pagination.per_page"
            [boundaryLinks]="true"
            (pageChange)="changePange($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewCall let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Meeting With {{ scheduledCalls[selectedCall].user_name }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><b>Problem:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.problem }}</p>
    </div>
    <div class="form-group">
      <label><b>Session Expectation:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.session_expectation }}</p>
    </div>
    <div class="form-group">
      <label><b>Previous Steps:</b></label>
      <p>{{ scheduledCalls[selectedCall].meta.previous_steps }}</p>
    </div>
    <div class="form-group">
      <label><b>Selected Time of Call:</b></label>
      <p>
        {{ scheduledCalls[selectedCall].meta.selected_time | date: "medium" }}
      </p>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label><b>Day Time:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.day_time }}
          </p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Weekday:</b></label>
          <p>
            {{ scheduledCalls[selectedCall].meta.week_time }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group" style="word-break: break-all;">
          <label><b>Meeting Url:</b></label>
          <br />
          <a href="{{ scheduledCalls[selectedCall].meta.meeting_url }}">
            {{ scheduledCalls[selectedCall].meta.meeting_url }}
          </a>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label><b>Status:</b></label>
          <p>
            {{ getMeetingStatus(scheduledCalls[selectedCall].meta.status, scheduledCalls[selectedCall].type) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>
