<!-- Page Title -->
<app-breadcrumbs
  pageTitle="Review Resume"
  [path]="[{ title: 'Experts', path: '/experts' }]"
></app-breadcrumbs>
<!-- End Page Title -->

<!-- Contact -->
<div class="dashboard-area pb-100 mt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="profile-item">
          <img
            [src]="
              expert?.profile_pic
                ? expert?.profile_pic
                : 'assets/img/avatar.jpg'
            "
            alt="Dashboard"
          />
          <h2>{{ expert.name }}</h2>
          <span>{{ expert.position }}</span>
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <!-- <div class="profile-content" *ngIf="isExpert">
          <div class="profile-content-inner">
            <h2>Experts can't review resume with another expert.</h2>
          </div>
        </div> -->
        <div class="profile-content" >
          <form
            id="contactForm"
            (submit)="reviewResume()"
            [formGroup]="contactForm"
          >
            <div class="profile-content-inner">
              <h2>{{ formTitle }}</h2>
              <div class="row">
                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="name">Name</label>
                    <input
                      type="text"
                      formControlName="name"
                      id="name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="phone_no">Phone</label>
                    <input
                      type="text"
                      formControlName="phone_no"
                      id="phone_no"
                      required
                      class="form-control"
                      placeholder="88888-88888"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('phone_no')">
                      Please enter valid 10 digit phone number.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="form-group">
                    <label class="label-required" for="email">Email</label>
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      required
                      class="form-control"
                    />
                    <p class="invalid-feedback"
                    *ngIf="showValidation('email')">
                      Please enter valid email address.
                    </p>
                  </div>
                </div>

                <span formArrayName="meta">
                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="job_posting_link"
                        >Job Posting Link</label
                      >
                      <input
                        type="text"
                        formControlName="job_posting_link"
                        id="job_posting_link"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="job_id">Job Id</label>
                      <input
                        type="text"
                        formControlName="job_id"
                        id="job_id"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="skills">Skills</label>
                      <input
                        type="text"
                        formControlName="skills"
                        id="skills"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="year_of_exp"
                        >Years of exp</label
                      >
                      <input
                        type="text"
                        formControlName="year_of_exp"
                        id="year_of_exp"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="linkedin_profile"
                        >Linkedin Profile</label
                      >
                      <input
                        type="text"
                        formControlName="linkedin_profile"
                        id="linkedin_profile"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-12">
                    <div class="form-group">
                      <label class="label-required" for="cv_upload"
                        >CV Upload</label
                      >
                      <input
                        type="file"
                        formControlName="cv_upload"
                        id="cv_upload"
                        required
                        (change)="uploadFile($event)"
                        class="form-control"
                        accept=".jpg,.jpeg,.png,.pdf"
                      />
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <button
              type="submit"
              [disabled]="getFormValidationErrors() || loaderService.isLoading"
              class="btn dashboard-btn"
            >
              <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
              <span *ngIf="!loaderService.isLoading">Pay Now</span>              
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Contact -->
</div>
