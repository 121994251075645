<section class="explore-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>For Businesses</h2>
            <p>&nbsp;</p>
        </div>
    </div>
</section>
<!-- Work -->
<section class="work-area pt-90 pb-70 light_green_bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <h3>Hire Talent</h3>
                    <p>We have polished and ready-to-work candidates to get hired in your
                        companies,that too at ZERO cost.
                    </p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">                    
                    <h3>Hire Mentors/Freelancers</h3>
                    <p>Sub-hire Freelancers for teaching/project work etc from us at complete quality & delivery
                        ownership on ExpertDoor.
                    </p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">                    
                    <h3>Hire Influencers</h3>
                    <p>Sub-Hire Influencers for webinars, promotions and marketing Contact us.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->
<div class="contact-form-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="form-item">
                <h2>WRITE TO US</h2>
                <p>Jot us a note and we’ll get back to you as quickly as possible.</p>
                <form id="contactForm" [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" id="name" formControlName="name" class="form-control" />
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['name'].invalid && (enquiryForm.controls['name'].dirty || enquiryForm.controls['name'].touched)">
                                    <p class="invalid-feedback" *ngIf="enquiryForm.controls['name'].errors.required">
                                        This field is required
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" formControlName="email" class="form-control" />
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['email'].invalid && (enquiryForm.controls['email'].dirty || enquiryForm.controls['email'].touched)">
                                    <p class="invalid-feedback" *ngIf="enquiryForm.controls['email'].errors.required">
                                        This field is required
                                    </p>
                                    <p class="invalid-feedback" *ngIf="enquiryForm.controls['email'].errors.email">
                                        Enter valid email
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                <input type="number" id="phone" formControlName="phone" class="form-control" />
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['phone'].invalid && (enquiryForm.controls['phone'].dirty || enquiryForm.controls['phone'].touched)">
                                    <p class="invalid-feedback" *ngIf="enquiryForm.controls['phone'].errors.required">
                                        This field is required
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="comany">Company Name</label>
                                <input type="text" formControlName="company" id="company" class="form-control" />
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['company'].invalid && (enquiryForm.controls['company'].dirty || enquiryForm.controls['company'].touched)">
                                    <p class="invalid-feedback" *ngIf="enquiryForm.controls['company'].errors.required">
                                        This field is required
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-sm-8 col-lg-8">
                            <div class="form-group">
                                <label for="comany">Category</label>
                                <select formControlName="category" id="category" class="form-control">
                                    <option value="">Please Select</option>
                                    <option value="Hire talent">Hire talent</option>
                                    <option value="Hire Mentors">Hire Mentors</option>
                                    <option value="Hire Freelancers">Hire Freelancers</option>
                                    <option value="Hire Influencers">Hire Influencers</option>
                                    <option value="Others">Others</option>
                                </select>
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['category'].invalid && (enquiryForm.controls['category'].dirty || enquiryForm.controls['category'].touched)">
                                    <p class="invalid-feedback"
                                        *ngIf="enquiryForm.controls['category'].errors.required">
                                        This field is required
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group">
                                <label for="comany">Description</label>
                                <textarea rows="12" formControlName="description" id="description"
                                    class="form-control"></textarea>
                                <div class="error-msg-content"
                                    *ngIf="enquiryForm.controls['description'].invalid && (enquiryForm.controls['description'].dirty || enquiryForm.controls['description'].touched)">
                                    <p class="invalid-feedback"
                                        *ngIf="enquiryForm.controls['description'].errors.required">
                                        This field is required
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12">
                            <button type="submit" class="btn">                                
                                <span *ngIf="loaderService.isLoading"><i id="spinLoader" class="bx bx-loader-alt" ></i></span>
                                <span *ngIf="!loaderService.isLoading">Send</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>