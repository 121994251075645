<!-- Navbar -->

<!-- End Navbar -->

<!-- Banner -->
<div class="banner-area">
  <!-- <ng-particles id="tsparticles" [options]="particlesOptions"></ng-particles> -->

  <div class="banner-bg-shapes">
    <img src="assets/img/patten.svg" />
    <img src="assets/img/patten.svg" />
    <img src="assets/img/patten.svg" />
  </div>
  <div class="banner-shape">
    <img src="assets/img/home-one/banner/shape-bottom-dark.png" alt="Shape" />
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="banner-content">
          <div class="d-table">
            <div class="d-table-cell">
              <h1>ExpertDoor Tech Job focused programs</h1>
              <p>
                The Ultimate 1-1 Mentorship Programs to get into a product
                company/ surge your salary by more than 50% hike/ higher than
                service based companies
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 d-flex align-items-center">
        <div class="banner-cards">
          <div *ngFor="let card of bannerCards" class="card">
            <img [src]="card.icon" />
            <h5>{{ card.title }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Banner -->

<!-- Programs -->
<section class="pb-70 dark_green_bg">
  <div class="container py-4">
    <div class="section-title">
      <h2>Our Flagship Programs</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-5 col-xl-4 col-md-4">
        <div class="card programs p-2" (click)="activeProgram = 0">
          <div class="card-body d-flex">
            <div>
              <span class="toggleButton"
                ><i *ngIf="activeProgram == 1" class="bx bxs-x-circle"></i>
                <i *ngIf="activeProgram == 0" class="bx bxs-plus-circle"></i
              ></span>
              <h3 class="title">x to 10x Career surge program</h3>
              <p class="description">
                Get into a product company and boost your salary by more than
                50% or transition into a lucrative career with 1-1 expert
                guidance, referral, interview preparation support.
              </p>
              <h4 class="subtitle">100% Job Assistance*</h4>

              <div
                class="price-details {{ activeProgram == 0 ? 'active' : '' }}"
              >
                <!-- <p>
                  MRP: <s><i class="fas fa-rupee-sign"></i> 10,000</s>
                </p> -->
                <!-- <p>
                  <i class="fas fa-rupee-sign"></i> 6,250/-
                  <sub>per month for 12 months + 6% of CTC share</sub>
                </p> -->
                <h4 class="annual">
                  Billed Annually: <i class="fas fa-rupee-sign"></i> 7,800/-
                </h4>
              </div>
              <div class="d-flex justify-content-between">
                <a href="https://rzp.io/l/eI4HSqKV" target="_blank" class="btn btn-primary text-white">Buy Now</a>
                <button
                  class="btn btn-secondary"
                  (click)="scrollToContact('contact')"
                >
                  Enquire Now
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card programs p-2 mt-4" (click)="activeProgram = 1">
          <div class="card-body d-flex">
            <div>
              <span class="toggleButton"
                ><i *ngIf="activeProgram == 0" class="bx bxs-x-circle"></i>
                <i *ngIf="activeProgram == 1" class="bx bxs-plus-circle"></i
              ></span>
              <h3 class="title">Sure shot Career upstart program</h3>
              <p class="description">
                Get guaranteed referrals and job support for starting your
                career on a high note
              </p>
              <h4 class="subtitle">Career Acceleration Program</h4>

              <div
                class="price-details {{ activeProgram == 1 ? 'active' : '' }}"
              >
                <!-- <p>
                  MRP:
                  <s><i class="fas fa-rupee-sign"></i> 4,000</s>
                </p>
                <p>
                  <i class="fas fa-rupee-sign"></i> 2,500/-
                  <sub
                    >month for 12 months +15% of CTC if placed via expert
                    referral</sub
                  >
                </p> -->
                <h4 class="annual">
                  Billed Annually: <i class="fas fa-rupee-sign"></i> 5,500/-
                </h4>
              </div>
              <div class="d-flex justify-content-between">
                <a href="https://rzp.io/l/Pif1RBHB" target="_blank" class="btn btn-primary text-white">Buy Now</a>
                <button
                  class="btn btn-secondary"
                  (click)="scrollToContact('contact')"
                >
                  Enquire Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-sm-7 col-xl-8 col-md-8">
        <div class="card programs p-3">
          <div class="card-body row justify-content-between">
            <div class="col-sm-5">
              <h3 class="title">Domains</h3>
              <div class="row">
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/statistics.png" />
                    <h3>Data Analytics</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/monitor.png" />
                    <h3>Data Science</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/full-stack.png" />
                    <h3>Full Stack</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/eternity.png" />
                    <h3>DevOps</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/cloud-data.png" />
                    <h3>Cloud</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/process.png" />
                    <h3>Product Management</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/algorithm.png" />
                    <h3>Data Structure Algorithms</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/coding.png" />
                    <h3>Data Engineer</h3>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card programs domains">
                    <img src="assets/img/icons/deep-learning.png" />
                    <h3>Deep Learning</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <h3 class="title">What you receive</h3>
              <div class="receivings">
                <h4>Career Goal</h4>
                <ul>
                  <li>Dedicated career coordinator</li>
                  <li>
                    1-1 Goal setting calls with recommended experts (upto 2
                    calls for 30 mins each)
                  </li>
                  <li>Premium access to experts’ resumes from top companies</li>
                </ul>
              </div>

              <div class="receivings">
                <h4>Generic and Domain Skills Training</h4>
                <ul>
                  <li>
                    Access to tailor made career paths with course content
                  </li>
                  <li>Soft skills training</li>
                  <li>
                    Premium access to industry live projects
                    {{
                      activeProgram == 0
                        ? "with hands on environment"
                        : ""
                    }}
                  </li>
                  <li>
                    Live training with trainers ({{
                      activeProgram == 0 ? 20 : 15
                    }}
                    sessions, 1 hour each)
                  </li>
                </ul>
              </div>

              <div class="receivings">
                <h4>Post-Training Candidate Evaluation</h4>
                <ul>
                  <li>
                    1:1 Calls with industry experts (upto 2 calls, 30 mins each)
                  </li>
                </ul>
              </div>

              <div class="receivings">
                <h4>Referrals & Placement Assistance</h4>
                <ul>
                  <li>
                    1:1 mock interview with industry experts (upto 2 calls, 30
                    mins each)
                  </li>
                  <li>
                    Reach-outs for referrals: 20 reach-outs to recommended
                    experts for referrals
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Programs End -->

<!-- Acheivements -->
<app-counter></app-counter>
<!-- End Acheivements -->

<!-- Featured Experts -->
<section class="pt-70 pb-70 dark_green_bg">
  <div class="container">
    <div class="section-title">
      <h2>Meet Our Premium Experts</h2>
    </div>
    <div class="row">
      <div class="col-sm-2" *ngFor="let expert of experts">
        <div class="company-item p-2">
          <div class="top">
            <a [routerLink]="['/experts', expert.vanity_url]">
              <img
                src="{{ expert.profile_pic }}"
                class="m-auto d-flex w-100 h-100"
                alt=""
              />
              <h6 class="mt-1">{{ expert.name }}</h6>
              <p>{{ expert.position }}, {{ expert.company }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Featured Experts -->

<!-- Job Path -->
<section class="pt-70">
  <div class="section-title text-center">
    <h2>Your Career Transformation journey with ExpertDoor</h2>
    <img src="assets/img/job-path.png" alt="" />
  </div>
</section>
<!-- End Job Path -->

<!-- FAQs -->
<section class="pb-70 pt-70 dark_green_bg">
  <div class="container">
    <div class="section-title">
      <h2>FAQs</h2>
    </div>

    <div class="row">
      <div class="col-sm-8 m-auto">
        <ul class="job-faqs">
          <li
            *ngFor="let faq of faqs; let i = index"
            (click)="toggleFaq(i)"
            [ngClass]="i == openedFaq ? 'active' : ''"
          >
            <span class="toggleButton"
              ><i *ngIf="i == openedFaq" class="bx bxs-x-circle"></i>
              <i *ngIf="i != openedFaq" class="bx bxs-plus-circle"></i
            ></span>
            <h3>{{ faq.title }}</h3>
            <p>{{ faq.description }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- End FAQs -->

<!-- Certificate -->
<section class="pb-70 pt-70">
  <div class="container">
    <div class="section-title text-center">
      <h2>Yes you will be certified with this program</h2>
      <p class="sub-title">Community-led learning is the future</p>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="section-title">
          <h2 class="mb-3">Yes you will be certified with this program</h2>
          <h5 class="sub-title">
            Yes! You will be certified for this program once you submit your
            assignment.
          </h5>
        </div>

        <div class="section-title">
          <h2>Why ExpertDoor?</h2>
          <ul style="font-size: 18px;">
            <li>
              Get the right INDUSTRY EXPERT GUIDANCE, with the right SKILLS.
            </li>
            <li>Get REFERRED by INDUSTRY Experts.</li>
            <li>Domain Specific Live Projects with Certification.</li>
            <li>Get access to Live Labs.</li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6">
        <img src="assets/img/ceritificate.jpg" alt="" />
      </div>
    </div>
  </div>
</section>
<!-- End Certificate -->

<!-- Program For -->
<section class="pb-70 pt-70 dark_green_bg">
  <div class="container">
    <div class="section-title">
      <h2>Who is this program for?</h2>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ul class="program-chips">
          <li *ngFor="let domain of programFor">
            <i class="bx bxs-badge-check"></i> {{ domain }}
          </li>
        </ul>
      </div>
    </div>
    <div class="section-title text-center mt-3">
      <h4 class="text-white">Think it's a match?</h4>
      <a class="btn bg-white apply-now-btn" (click)="scrollToContact('contact')"
        >Apply Now</a
      >
    </div>
  </div>
</section>
<!-- End Program For -->

<!-- Contact Form -->
<section class="pt-70 pb-70" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-sm-8 m-auto">
        <div class="contact-form-area">
          <app-contact-form></app-contact-form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Form -->
