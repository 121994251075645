<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Courses</h2>
          <ul>
            <li>
              <img src="assets/img/logoIcon.png" alt="Image" />
              <a routerLink="/">Home</a>
            </li>
            <li><span>/</span></li>
            <li>Courses</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all">
            <div class="search">
              <form>
                <h3>Search Keywords</h3>

                <input
                  type="text"
                  name="keywords"
                  class="form-control"
                  (input)="refineSearch($event)"
                  placeholder="Type Your Keywords"
                />
              </form>
            </div>

            <div
              *ngFor="let filterCat of filterOptions"
              class="location-list cmn-padding"
            >
              <h3>{{ filterCategoryFilter(filterCat.name) }}</h3>

              <ul class="job-cmn-cat">
                <li *ngFor="let filters of filterCat.options">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [name]="stringTolower(filterCat.field)"
                      [value]="filters.value"
                      (click)="refineSearch($event)"
                      id="defaultCheck1"
                    />
                    <label class="form-check-label" for="defaultCheck1">{{
                      filters.label
                    }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-12">
        <ul class="job-list-item align-items-center">
          <li>
            <a href="#"
              >Results Found <span>({{ pagination.total }})</span></a
            >
          </li>
          <li></li>
        </ul>

        <div class="employer-item" *ngFor="let course of courses">
          <!-- target="_blank" href="{{ course.url }}" -->
          <a (click)="contactForm(content, course.url)">
            <img
              [src]="
                course.image == '-'
                  ? 'assets/img/skillsurger.png'
                  : course.image
              "
              class="thumbnail"
              alt="Employer"
            />
            <h3>{{ course.course_name }}</h3>
            <ul>
              <li *ngIf="course.provider && course.provider != '-'">
                <i class="flaticon-send"></i> {{ course.provider }}
              </li>
              <li
                *ngIf="course.course_duration && course.course_duration != '-'"
              >
                <i class="fas fa-clock"></i> {{ course.course_duration }}
              </li>
            </ul>
            <p *ngIf="course.description != '-'">
              {{ course.description }}
            </p>
            <p *ngIf="course.starting_date != '-'">
              {{ course.starting_date }}
            </p>
            <span
              *ngIf="course.tags != '-'"
              [innerHTML]="tags(course.tags)"
            ></span>
            <span class="span-two">
              <i class="fas fa-star" *ngIf="course.rating"></i>
              {{ course.rating }}
              <span *ngIf="course.reviews || course.reviews != '='">{{
                course.reviews
              }}</span></span
            >
          </a>
        </div>

        <div class="pagination-area">
          <ngb-pagination
            [collectionSize]="pagination.total"
            [(page)]="pagination.current_page"
            [maxSize]="3"
            [pageSize]="pagination.per_page"
            (pageChange)="getCourses($event)"
            [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>

      <div class="col-lg-2 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all p-2">
            <div class="location-list">
              <h3 style="font-size: 15px">
                <strong>Experts You May Now</strong>
              </h3>
              <div class="mb-2" *ngFor="let exp of expert">
                <a [routerLink]="['/experts', exp.vanity_url]">
                  <img src="{{ exp.profile_pic }}" class="w-100" />
                  <p class="m-0 text-capitalize">
                    <strong>{{ exp.name }}</strong>
                  </p>
                  <small class="text-capitalize"
                    >{{ exp.position }}, {{ exp.company }}</small
                  >
                </a>
              </div>
            </div>

            <div class="location-list cmn-padding">
              <h3 style="font-size: 15px"><strong>Relevant Jobs</strong></h3>
              <ul class="list-unstyled job-cmn-cat">
                <li *ngFor="let job of jobs">
                  <a href="{{ job.url }}" target="_blank">
                    <h6>
                      <Strong>{{ job.title }}</Strong>
                    </h6>
                    <p [innerHTML]="job.description.substr(0, 60)"></p>
                  </a>
                  <hr />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<ng-template #content let-modal>
  <button
    type="button"
    class="btn close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="modal-body">
    <div class="contact-form-area">
      <app-contact-form [openUrl]="redirectTo" [isContact]="false"></app-contact-form>
    </div>
  </div>
</ng-template>
